import "./module.scss";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { BiX } from "react-icons/bi";
import { useInView } from 'react-intersection-observer';
import { useLocation } from "react-router-dom";
import { CreatorCategoryModule } from "../module/category/CreatorCategoryModule";
import { useState } from "react";
import { CreatorProducModule } from "../module/product/CreatorProducModule";
import { useIdContext } from "../../../../context/IdContext";
import { EditCategoryModule } from "../module/category/EditCategoryModule";
import { EditProducModule } from "../module/product/EditProducModule";
import { CreatorRecollections } from "../module/recollections/CreatorRecollections";
import { EditRecollections } from "../module/recollections/EditRecollections";
import { CreatorWarehouse } from "../module/warehouse/CreatorWarehouse";
import { EditWarehouse } from "../module/warehouse/EditWarehouse";
import { CreatorMessenger } from "../module/messenger/CreatorMessenger";
import { EditMessenger } from "../module/messenger/EditMessenger";
import { CreatorSeller } from "../seller/CreatorSeller";
import { EditSeller } from "../seller/EditSeller";
import { CreatorLicense } from "../roles/CreatorLicense";
import { EditLicense } from "../roles/EditLicense";



// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500},
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const OpenCreate = ({idc, setIsOpenCreate, rol}) => {
    const location = useLocation();
    const { idItem, setIdItem } = useIdContext();
    const [ loanding, setLoading] =useState (false)

    const toggleOpen = () => {
        setIsOpenCreate  (isOpenCreate=> !isOpenCreate);
        setIdItem(null); 
    };

    return (
        <div className="open-create">
            <FadeInSection>
                {!loanding
                    ?<>
                        <button className='button-x-flotant' onClick={toggleOpen}>
                            <BiX />
                        </button>
                        <section className="section-open-create">
                            {/* Encabezados de las tablas */}
                            {location.pathname === "/stock/productos/categorias" && 
                                <>
                                    {!idItem
                                        ?<CreatorCategoryModule idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate}/>
                                        :<EditCategoryModule idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate} idItem ={idItem} setIdItem={setIdItem}/>
                                    }
                                </>
                            }
                            {location.pathname === "/stock/productos/base" && 
                                <>
                                    {!idItem
                                        ?<CreatorProducModule idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate}/>
                                        :<EditProducModule idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate} idItem ={idItem} setIdItem={setIdItem}/>
                                    }
                                </>
                            }
                            {(location.pathname === "/company/recolecciones" || location.pathname === "/recoleccion/search")  && 
                                <>
                                    {!idItem
                                        ?<CreatorRecollections idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate}/>
                                        :<EditRecollections idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate} idItem ={idItem} setIdItem={setIdItem}  rol={rol}/>
                                    }
                                </>
                            }
                            {location.pathname === "/company/warehouse" && 
                                <>
                                    {!idItem
                                        ?<CreatorWarehouse idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate}/>
                                        :<EditWarehouse idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate} idItem ={idItem} setIdItem={setIdItem}/>
                                    }
                                </>
                            }
                            {location.pathname === "/messengers" && 
                                <>
                                    {!idItem
                                        ?<CreatorMessenger idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate}/>
                                        :<EditMessenger idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate} idItem ={idItem} setIdItem={setIdItem}/>
                                    }
                                </>
                            }
                            {location.pathname === "/seller" && 
                                <>
                                    {!idItem
                                        ?<CreatorSeller idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate}/>
                                        :<EditSeller idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate} idItem ={idItem} setIdItem={setIdItem}/>
                                    }
                                </>
                            }
                            {location.pathname === "/admin/license" && 
                                <>
                                    {!idItem
                                        ?<CreatorLicense idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate}/>
                                        :<EditLicense idc={idc} setLoading={setLoading} setIsOpenCreate={setIsOpenCreate} idItem ={idItem} setIdItem={setIdItem}/>
                                    }
                                </>
                            }
                        </section>
                    </>
                    :<div>
                        <div className="loading-circle"></div>
                    </div>
                }
            </FadeInSection>
        </div>
    );
};