import axios from 'axios';
import Cropper from "react-easy-crop";
import NameCompany from './NameCompany';
import colombia from "../../../../firebase/colombia";
import { useCallback, useEffect, useRef, useState } from "react";
import { FiSave } from "react-icons/fi";
import { toast } from "react-toastify";
import { useLoad } from "../../../../context/LoadContext";
import { MdAspectRatio, MdOutlineCancel } from 'react-icons/md';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { GetCroppedImg } from "../template/CropImageHelper";
import { v4 as uuidv4 } from 'uuid'; 
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db, storage} from '../../../../firebase/config';
import { BiRefresh } from 'react-icons/bi';
import { FaRegFilePdf } from 'react-icons/fa';


const MAPS_API_KEY = "AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg";

export const EditSeller = ({ setLoading, setIsOpenCreate, idItem , setIdItem }) => {
    const mapRef = useRef(null); // Referencia al mapa
    const [ imagePreviewUrl, setImagePreviewUrl ] = useState('');
    const [ crop, setCrop ] = useState({ x: 0, y: 0 });
    const [ zoom, setZoom ] = useState(1);
    const [ croppedAreaPixels, setCroppedAreaPixels ] = useState(null);
    const [ imageSrc, setImageSrc ] = useState(null);
    const [ rutPdf, setRutPdf ] = useState(null); 
    const [ camaraComercioPdf, setCamaraComercioPdf ] = useState(null);
    const [ cedulaPdf, setCedulaPdf ] = useState(null);
    const [ rutPreviewUrl, setRutPreviewUrl ] = useState (null);
    const [ camaraComercioPreviewUrl , setCamaraComercioPreviewUrl ] = useState (null);
    const [ cedulaPreviewUrl  , setCedulaPreviewUrl ] = useState (null);
    const { triggerReload } = useLoad();
    const [ selectedDepartamento, setSelectedDepartamento ] = useState('');
    const [ ciudad, setCiudad ] = useState([]);
    const [ comercial, setComercial] = useState([]);
    const [ formData, setFormData ] = useState({
        imageUrl: "",
        namecompany: "",
        rut: "",
        telefono: "",
        pais: 'Colombia',
        departamento: '',
        ciudad: '',
        direccion1:"",
        direccion2:"",
        emailfacturacion: "",
        lat: 4.711,
        lng: -74.0721,
        id_commercial:"",
        nombre_comercial:"",
        typecompany:"",
        business_id: "", 
    });

    useEffect(() => {
        const loadData = async () => {
            const docRef = doc(db, "company", idItem);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const itemData = docSnap.data();
                setFormData({
                    ...itemData 
                });
                setImagePreviewUrl(itemData.imageUrl);
                setSelectedDepartamento(itemData.departamento);
                setCiudad(colombia[itemData.departamento]);
                if (itemData.rutUrl) {
                    setRutPreviewUrl(itemData.rutUrl);
                }
                if (itemData.camaraComercioUrl) {
                    setCamaraComercioPreviewUrl(itemData.camaraComercioUrl);
                }
                if (itemData.cedulaUrl) {
                    setCedulaPreviewUrl(itemData.cedulaUrl);
                }
            } else {
                toast.error("No such document!");
            }
        };
        loadData();
    }, [idItem]);

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'users');
                const u = query(q, where('idc', '==', 'DFevIG67TJ8AJsZ1eOwW')); // Ajusta el filtro si es necesario
                const querySnapshot = await getDocs(u);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setComercial(fetchedInfo);
                // Buscar el comercial con id_commercial
                if (formData.id_commercial) {
                    const selectedComercial = fetchedInfo.find(
                        (user) => user.id === formData.id_commercial
                    );
                    if (selectedComercial) {
                        setFormData((prevInfo) => ({
                            ...prevInfo,
                            nombre_comercial: `${selectedComercial.nombre} ${selectedComercial.apellido}`
                        }));
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [formData.id_commercial]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === 'telefono' || name === 'rut'){
            // Validar que solo se ingresen números
            if (/^[0-9]*$/.test(value)) {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else {
            // Manejo de otros cambios en el formulario
            setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
            if (name === 'departamento') {
                setSelectedDepartamento(value);
            }
        }
    }, []);
    
    useEffect(() => {
        if (selectedDepartamento) {
            setCiudad(colombia[selectedDepartamento]);
        }
    }, [selectedDepartamento]);
    
    // Actualizar el centro del mapa cuando cambian las coordenadas
    useEffect(() => {
        if (mapRef.current) {
            const { lat, lng } = formData;
            mapRef.current.setView([lat, lng], 13, { animate: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.lat, formData.lng]);

    // useEffect para actualizar las coordenadas cuando se completan los campos necesarios
    useEffect(() => {
        const { pais, departamento, ciudad, direccion1 } = formData;
        if (pais && departamento && ciudad && direccion1) {
            getCoordinates(); // Obtener coordenadas automáticamente
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.pais, formData.departamento, formData.city, formData.andress]);

    // Obtener coordenadas de la dirección proporcionada
    const getCoordinates = async () => {
        const address = `${formData.direccion1}, ${formData.ciudad}, ${formData.departamento}, ${formData.pais}`;
        const encodedAddress = encodeURIComponent(address);
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${MAPS_API_KEY}`
            );
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                setFormData(prevState => ({ ...prevState, lat, lng }));
                return true;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                return false;
            }
        } catch (error) {
            console.error("Error obteniendo coordenadas:", error);
            toast.error("Error al obtener coordenadas.");
            return false;
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImageSrc(reader.result); // Mostrar en el Cropper
            };
        }
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    // Nueva función para redimensionar la imagen
    const resizeImage = (image, width, height) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const resizedImage = new File([blob], "croppedImage.jpg", { type: blob.type });
                resolve(resizedImage);
            }, 'image/jpeg');
        });
    };

    const cropImage = async () => {
        try {
            // Obtener la imagen recortada
            const croppedImage = await GetCroppedImg(imageSrc, croppedAreaPixels);
    
            // Crear un objeto Image para redimensionar
            const img = new Image();
            img.src = URL.createObjectURL(croppedImage);
    
            img.onload = async () => {
                // Calcular el tamaño para mantener la proporción
                const maxSize = 1000;
                let width = img.width;
                let height = img.height;
    
                if (width > height) {
                    height = (height * maxSize) / width;
                    width = maxSize;
                } else {
                    width = (width * maxSize) / height;
                    height = maxSize;
                }
    
                // Redimensionar la imagen usando la nueva función
                const resizedImage = await resizeImage(img, width, height);
    
                // Actualizar el estado con la imagen redimensionada
                setFormData((prev) => ({ ...prev, imageUrl: resizedImage }));
                setImagePreviewUrl(URL.createObjectURL(resizedImage));
                setImageSrc(null); // Cerrar el cropper
            };
        } catch (error) {
            console.error("Error recortando la imagen", error);
            toast.error("Error recortando la imagen.");
        }
    };

    const uploadImageToStorage = async (file, customName) => {
        try {
            const storage = getStorage(); // Inicializar el storage
            const storageRef = ref(storage, `messenger/${customName}`); // Usar nombre personalizado
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref); // Obtener la URL de descarga
            return downloadURL;
        } catch (error) {
            console.error("Error subiendo la imagen:", error);
            throw new Error("Error subiendo la imagen.");
        }
    };

    const handleCamaraPdfChange = (e) => {
        if (e.target.files[0]) {
            setCamaraComercioPdf(e.target.files[0]);
            const previewUrlCamaraComercioPdf = URL.createObjectURL(e.target.files[0]);
            setCamaraComercioPreviewUrl(previewUrlCamaraComercioPdf);
        }
    };

    const handlecedulaPdfdfChange = (e) => {
        if (e.target.files[0]) {
            setCedulaPdf(e.target.files[0]);
            const previewUrlRutPdf = URL.createObjectURL(e.target.files[0]);
            setCedulaPreviewUrl(previewUrlRutPdf);
        }
    };

    const handleRutPdfChange = (e) => {
        if (e.target.files[0]) {
            setRutPdf(e.target.files[0]);
            const previewUrlRutPdf = URL.createObjectURL(e.target.files[0]);
            setRutPreviewUrl(previewUrlRutPdf);
        }
    };

    const uploadFile = async (file, path) => {
            const fileRef = ref(storage, path);
            const snapshot = await uploadBytes(fileRef, file);
            return getDownloadURL(snapshot.ref);
        };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const coordsObtained = await getCoordinates(); // Buscar coordenadas
        if (!coordsObtained) return; // Si no se obtuvieron coordenadas, no continuar
        try {
            let rutUrl = rutPreviewUrl; // Mantén la URL existente si no hay nuevo PDF para el RUT
            let camaraComercioUrl = camaraComercioPreviewUrl; // Mantén la URL existente si no hay nuevo PDF para la Cámara de Comercio
            let cedulaUrl = cedulaPreviewUrl; // Mantén la URL existente si no hay nuevo PDF para la Cédula
            let imageUrl = formData.imageUrl;
            if (formData.imageUrl instanceof File) {
                // Generar un nombre personalizado para la imagen
                const customName = `${formData.namecompany}-${new Date().toISOString()}-${uuidv4()}.jpg`;
                // Subir la imagen con el nombre personalizado
                imageUrl = await uploadImageToStorage(formData.imageUrl, `company/images/${customName}`);
            }
            if (rutPdf) rutUrl = await uploadFile(rutPdf, `company/rut/${rutPdf.name}`);
            if (camaraComercioPdf) camaraComercioUrl = await uploadFile(camaraComercioPdf, `company/camaracomercio/${camaraComercioPdf.name}`);
            if (cedulaPdf) cedulaUrl = await uploadFile(cedulaPdf, `company/cedula/${cedulaPdf.name}`);
            
            setLoading(true);
            const companyRef = doc(db, "company", idItem); // Usa la referencia del documento existente
            await updateDoc(companyRef, {
                ...formData,
                timestamp: new Date(),
                imageUrl,
                rutUrl,
                camaraComercioUrl,
                cedulaUrl,
            });
    
            toast.success("Compañía actualizada con éxito");
            setIsOpenCreate(isOpenCreate => !isOpenCreate);
            setIdItem("")
            // Después de guardar, disparar la recarga
            triggerReload();
        } catch (error) {
            toast.error("Error al actualizar la compañía: " + error.message);
        }
        finally {
            setLoading(false);
        }
    };


    return (
        <div className="section-open-create-form">
            {imageSrc ? (
                <div className="crop-container">
                    <div className="crop-area">
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1} // Proporción 1:1
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            style={{
                                containerStyle: {
                                    width: '100%',
                                    height: '400px',
                                    maxWidth: '500px',
                                    borderRadius: '30px',
                                    overflow: 'hidden',
                                },
                            }}
                        />
                    </div>
                    <div className="crop-buttons">
                        <button onClick={cropImage} className="button-new">
                            <MdAspectRatio /> Recortar
                        </button>
                        <button onClick={() => setImageSrc(null)} className="button-delete">
                            <MdOutlineCancel />Cancelar
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <section className="open-create-form-img">
                        <div className="input-img">
                            <img
                                className="user"
                                alt="user"
                                src={imagePreviewUrl || 'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'}
                            />
                            <div className="input-upload">
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <HiOutlinePhotograph /> {imagePreviewUrl ? 'Cambiar' : 'Subir imagen'}
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                    </section>
                    <form className="open-create-form-dates">
                        <div className='input-form-create'>
                            <label>Nombre Comercial</label>
                            <input type="text" name="namecompany" value={formData.namecompany} onChange={handleChange} required/>
                        </div>
                        <div className='input-form-create'>
                            <label>Email</label>
                            <input type="email" name="emailfacturacion" value={formData.emailfacturacion} onChange={handleChange} required readOnly />
                        </div>
                        <div className='input-form-create'>
                            <label>RUT | NIT</label>
                            <input type="text" name="rut" value={formData.rut} onChange={handleChange} required/>
                        </div>
                        <div className='input-form-create'>
                            <label>Busniess ID</label>
                            <input type="text" name="business_id" value={formData.business_id} onChange={handleChange}/>
                        </div>
                        <div className='input-form-create'>
                            <label>Teléfono</label>
                            <input type="text" name="telefono" value={formData.telefono} onChange={handleChange} required/>
                        </div>
                        <div className='input-form-create'>
                            <label>Departamento:</label>
                            <select name="departamento" value={selectedDepartamento} onChange={handleChange} required>
                                <option value="" disabled>Seleccione </option>
                                {Object.keys(colombia).map((department) => (
                                    <option key={department} value={department}>{department}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-form-create'>
                            <label>Ciudad:</label>
                            <select name="ciudad" value={formData.ciudad} onChange={handleChange} required>
                                <option value="" disabled>Seleccione</option>
                                {ciudad.map((city) => (
                                    <option key={city} value={city}>{city}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-form-create'>
                            <label>Direccion(Obligatorio)</label>
                            <input type="text" name="direccion1" value={formData.direccion1} onChange={handleChange} required/>
                        </div>
                        <div className='input-form-create'>
                            <label>Detalles de dirección</label>
                            <input type="text" name="direccion2" value={formData.direccion2} onChange={handleChange}/>
                        </div>
                        <div className='input-form-create'>
                            <label>Tipo de compañia:</label>
                            <select name="typecompany" value={formData.typecompany} onChange={handleChange} required>
                                <option value="" disabled>Seleccione </option>
                                {NameCompany.map((compy) => (
                                    <option key={compy.id} value={compy.id}>
                                        {compy.name}
                                    </option>
                                ))}
                            </select>
                        </div>                       
                        <div className='input-form-create'>
                            <label>Comercial:</label>
                            <select name="id_commercial" value={formData.id_commercial} onChange={handleChange} required>
                                <option value="" disabled>
                                    {formData.id_commercial ? formData.nombre_comercial : "Seleccione"}
                                </option>
                                {comercial.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.nombre} {user.apellido}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='form-create-botton space-top'>
                            <div className="custom-file-pdf">
                                <label htmlFor="rut-upload" className="custom-file-upload">
                                    {rutPreviewUrl ? <><BiRefresh />Cambiar RUT</> : <><FaRegFilePdf /> Subir RUT </>}
                                </label>
                                <input id="rut-upload" type="file" onChange={(e) => handleRutPdfChange(e, setRutPreviewUrl, setRutPdf)} accept=".pdf" style={{display: 'none'}} />
                            </div>
                            <div  className="custom-file-pdf">
                                <label htmlFor="camara-comercio-upload" className="custom-file-upload">
                                    {camaraComercioPreviewUrl ? <><BiRefresh />Cambiar Cma.C.</> : <><FaRegFilePdf /> Cma. comercio</>}
                                </label>
                                <input id="camara-comercio-upload" type="file" onChange={(e) => handleCamaraPdfChange(e, setCamaraComercioPreviewUrl, setCamaraComercioPdf)} accept=".pdf" style={{display: 'none'}} />
                            </div>
                            <div  className="custom-file-pdf">
                                <label htmlFor="cedula-upload" className="custom-file-upload">
                                    {cedulaPreviewUrl ? <><BiRefresh />Cambiar CCI</> : <><FaRegFilePdf /> CCI</>}
                                </label>
                                <input id="cedula-upload" type="file" onChange={(e) => handlecedulaPdfdfChange(e, setCedulaPreviewUrl , setCedulaPdf)} accept=".pdf" style={{display: 'none'}} />
                            </div>
                        </div>
                        <div className='form-create-botton space-top'>
                            <button className="button-new" onClick={handleSubmit}>
                                Actualizar <FiSave />
                            </button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}
