// Componentes de sistema
import { HomeUser } from '../components/page/private/homeuser/HomeUser';
import { MenuHeader } from '../components/UI/organisms/MenuHeader/MenuHeader';
import { MenuLeft } from '../components/UI/molecules/MenuLeft/MenuLeft';
import { Encuestas } from '../components/page/private/encuestas/Encuestas';
import { Equipo } from '../components/page/private/equipo/Equipo';
import { TeamStatus } from '../components/page/private/equipo/TeamStatus';
import { Kits } from '../components/page/private/kits/Kits';
import { TraySupport } from '../components/page/private/traysupport/TraySupport';
import { useLoginContext } from '../context/LoginContext';
import { CreadorEncuestas } from '../components/page/private/encuestas/CreadorEncuestas';
import { EditorEncuestas } from '../components/page/private/encuestas/EditorEncuestas';
import { EditorEquipo } from '../components/page/private/equipo/EditorEquipo';
import { CreadorEquipo } from '../components/page/private/equipo/CreadorEquipo';
import { DescriptionKits } from '../components/page/private/kits/DescriptionKits';
import { CreateKits } from '../components/page/private/kits/CreateKits';
import { Group } from '../components/page/private/group/Group';
import { CreadorGroup } from '../components/page/private/group/CreadorGroup';
import { ListBlogs } from '../components/page/private/blogs/ListBlogs';
// Bibliotes de react js
import { Route, Routes ,useLocation } from 'react-router-dom';
import { useState } from 'react';
import { AuthProvider } from '../context/AuthContext';
// Bases de datos
import { EditorGroup } from '../components/page/private/group/EditorGroup';
import { ConversationSupport } from '../components/page/private/traysupport/ConversationSupport';
import { ContestadorEncuestas } from '../components/page/private/encuestas/ContestadorEncuestas';
import { Perfil } from '../components/page/private/perfil/Perfil';
import { Password } from '../components/page/private/password/WimdonsPassword';
import { ListaEncuestas } from '../components/page/private/encuestas/ListaEncuestas';
import { ResultEncuesta } from '../components/page/private/encuestas/ResultEncuesta';
import { ListAsignaciones } from '../components/page/private/kits/ListAsignaciones';
import { ContestadorAsignaciones } from '../components/page/private/kits/ContestadorAsignaciones';
import { ListProduct } from '../components/page/private/kits/ListProduct';
import { Asiganaciones } from '../components/page/private/kits/Asiganciones';
import { CreateProduct } from '../components/page/private/product/base/CreateProduct';
import { CreatorBlogs } from '../components/page/private/blogs/CreatorBlogs';
import { EditBlogs } from '../components/page/private/blogs/EditBlogs';
import { Seller } from '../components/page/private/seller/Seller';
import { EditProduct } from '../components/page/private/product/base/EditProduct';
import { EditCategory } from '../components/page/private/product/base/EditCategory';
import { CreateCategory } from '../components/page/private/product/base/CreateCategory';
import { ListAsingProduct } from '../components/page/private/product/assigned/ListAsingProduct';
import { EdictAsingProduct } from '../components/page/private/product/assigned/EdictAsingProduct';
import { ListProductBass } from '../components/page/private/product/base/ListProductBass';
import { Category } from '../components/page/private/product/base/Category';
import { ListQuizOpen } from '../components/page/private/adminquizopen/ListQuizOpen';
// Tour de sistema SHOFI
import TourGuide from '../Tour';
// Rourtas de starco
import { CreadorCvs } from '../components/page/private/equipo/CreadorCvs';
import { LocationsUsers } from '../components/page/private/locationusers/LocationsUsers';
import { StarcoIversion } from '../components/page/private/startco/StarcoIversion';
import { StartcoList } from '../components/page/private/startco/StartcoList'
import { StartcoReserve } from '../components/page/private/startco/StartcoReserve';
import { StartcoListCowork } from '../components/page/private/startco/StartcoListCowork';
import { StartcoListSales } from '../components/page/private/startco/StartcoListSales';
import { DetailQuizOpen } from '../components/page/private/adminquizopen/DetailQuizOpen';
import { CreatorUserOpen } from '../components/page/private/adminquizopen/CreatorUserOpen';
import { CreadorUserCvs } from '../components/page/private/adminquizopen/CreatorUserCVS';
import { Manuals } from '../components/page/private/manuals/Manuals';
import { ListRegister } from '../components/page/private/listregister/ListRegister';
import { ListRegisterSiigo } from '../components/page/private/listregister/ListRegisterSiigo';
import { ListRegisterDemo } from '../components/page/private/listregister/ListRegisterDemo';
import { Balance } from '../components/page/private/balance/Balance';
import { DetailQuizUser } from '../components/page/private/encuestas/result/DetailQuizUser';
import { CreatorUsers } from '../components/page/private/creatorsusers/CreatorUsers';
import { KitList } from '../components/page/private/creatorsusers/KitList';
import { OrderConfirmationList } from '../components/page/private/creatorsusers/OrderConfirmationList';
import { OrderConfirmation } from '../components/page/private/creatorsusers/OrderConfirmation';
import { ReportsOrder } from '../components/page/private/reports/ReportsOrder';
import { StockCompany } from '../components/page/private/stockcompany/StockCompany';
import { UpdateOrden } from '../components/page/private/updateOrden/UpdateOrden';
import { StockAssignedProducts } from '../components/page/private/kits/StockAssignedProducts';
import { UpdateOrdenImagen } from '../components/page/private/updateOrden/UpdateOrdenImage';
import { EditorUserOpen } from '../components/page/private/adminquizopen/EditorUserOpen';
import { Recollections } from '../components/page/private/module/recollections/Recollections';
import { InventoryCompany } from '../components/page/private/module/inventorycompany/InventoryCompany';
import { OpenCreate } from '../components/page/private/template/OpenCreate';
import { LoadProvider } from '../context/LoadContext';
import { IdProvider } from '../context/IdContext';
import { Warehouse } from '../components/page/private/module/warehouse/Warehouse';
import { ProductModule } from '../components/page/private/module/product/ProductModule';
import { CategoryModule } from '../components/page/private/module/category/CategoryModule';
import { OpenGallery } from '../components/page/private/template/OpenGallery';
import { MessengerModule } from '../components/page/private/module/messenger/Messengermodule';
import { Roles } from '../components/page/private/roles/Roles';



const PrivateRouter = () => {
    const { user, setShouldReload } = useLoginContext();
    const [ retur, setRetur ] = useState(false);
    const [ isPassword, setIsPassword ] = useState(false);
    const [ isRegisterOpen, setIsRegisterOpen ] = useState(false);
    const [ isCreatorUsers, setIsCreatorUsers ] = useState(false);
    const [ isEditorOpen, setIsEditorOpen ] = useState(false);
    const [ isOpenCreate, setIsOpenCreate ] = useState(false);
    const [ isOpenGallery, setIsOpenGallery] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ isLocations, setIsLocations ] = useState();
    const location = useLocation ();

    const { 
        idc, 
        rol, 
        email, 
        code_orden , 
        nombre, 
        apellido, 
        ide, 
        typecompany, 
        module_active,
        business_id,
        compañia,
        img,
        telefono,
        imageUrl,
    } = user;

    const shouldHideNavBarAndFooter = () => {
        const hideForRoutes = [
            '/manuales',
            new RegExp('/manuales/.+'),
            new RegExp('/contestador/.+')
        ];
        return hideForRoutes.some(route => {
            if (route instanceof RegExp) {
                return route.test(location.pathname);
            } else {
                return route === location.pathname;
            }
        });
    };
    
    const hideNavBar = shouldHideNavBarAndFooter();
    return (
        <AuthProvider>
            <LoadProvider><IdProvider>
                {!isLocations&&<TourGuide  userId={user.id} />}
                <div className='main-admin'>
                    {isOpenGallery&&<OpenGallery setIsOpenGallery={setIsOpenGallery}/>}
                    {isOpenCreate&&<OpenCreate idc={idc} setIsOpenCreate={setIsOpenCreate}  rol={rol}/>}
                    {isPassword&&<Password id={user.id} email={email} idc={idc} setIsPassword={setIsPassword}/>}
                    {isRegisterOpen&&<CreatorUserOpen id={user.id} idc={idc} company ={compañia} rol={rol} ide={ide} setIsRegisterOpen={setIsRegisterOpen}/>}
                    {isEditorOpen&&<EditorUserOpen setIsEditorOpen={setIsEditorOpen} selectedId={selectedId} setRetur={setRetur}/>}
                    {isLocations&&<LocationsUsers id={user.id} setIsLocations={setIsLocations} email={email}/>}
                    {isCreatorUsers&&<CreatorUsers id={user.id}  idc={idc} company ={compañia} setIsCreatorUsers={setIsCreatorUsers} business_id={business_id}/>}
                    {!hideNavBar&&<section className='container-menu'>
                        <MenuLeft idc={idc} rol={rol} module_active={module_active} imageUrl={imageUrl}/>
                    </section>}
                    {!hideNavBar &&<section className='container-admin'>
                        <MenuHeader nombre={nombre} apellido={apellido} email={email} img={img} rol={rol} id={user.id} setIsPassword={setIsPassword} module_active={module_active}/>
                        <div className='windows-admin'>
                            <Routes>
                                {user ? (
                                    <>
                                        <>
                                            <Route path='/soporte' element={<TraySupport to={'/soporte'} idc={idc} compañia={compañia} id={user.id} email={email} rol={rol}/> } />
                                            <Route path='/soporte/:id' element={<ConversationSupport to={'/soporte/:id'} idu={user.id} email={email} rol={rol} idc={idc}/>}/>
                                            <Route path='/perfil' element={<Perfil to={'/perfil'} id={user.id} idc={idc}/>}/>
                                        </>
                                        {(     rol === 'US-001'
                                            || rol === 'AA-001'  
                                            || rol === 'SA-001'
                                            || rol === 'SA-002'
                                            || rol === 'SA-003'
                                            || rol === 'SA-004'
                                            || rol === 'SA-005' ) && (
                                            <>
                                                <Route path='/encuesta/lista' element={<ListaEncuestas idc={idc} compañia={compañia} id={user.id} to={'/encuesta/lista'} />} />
                                                <Route path='/encuesta/resultado/:id' element={<ResultEncuesta to={'/encuesta/resultado/:id'}/>} />
                                                <Route path='/kits/lista' element={<ListAsignaciones  code_orden={code_orden} idc={idc} compañia={compañia} id={user.id} to={'/kits/lista'}/>} />
                                                <Route path='/kits/asignados/:id' element={<ContestadorAsignaciones  code_orden={code_orden} idc={idc} compañia={compañia} id={user.id} business_id={business_id} to={'/kits/asignados/:id'}/>} />
                                                <Route path='/kits/procesados/:id' element={<Asiganaciones code_orden={code_orden} idc={idc} rol={rol} email={email} compañia={compañia} idu={user.id} business_id={business_id} to={'/kits/procesados/:id'}/>} />
                                                <Route path='/equipos' element={<ListProduct code_orden={code_orden} idc={idc} compañia={compañia} id={user.id} rol={rol} email={email} to={'equipos'} />} />
                                            </>
                                        )}
                                        {( rol === 'AA-001' || rol === 'SA-001' ) && (
                                            <>
                                                <Route path='/kits/send' element={<Kits to={'/kits/send'} code_orden={code_orden} compañia={compañia} idc={idc} rol={rol}/>} />
                                                <Route path='/kits/:id' element={<DescriptionKits to={'/kits/:id'} code_orden={code_orden} business_id={business_id} compañia={compañia} idc={idc} rol={rol}/>}/>
                                                <Route path='/kits/creador' element={<CreateKits to={'/kits/creador'} idc={idc} compañia={compañia} business_id={business_id} uscompañia={compañia} rol={rol}/>} />
                                                {/* <Route path='/marca/date' element={<Seller to={'/marca/date'}  idc={idc} />} /> */}
                                                <Route path='/usuarios' element={<Equipo to={'/usuarios'}  typecompany={typecompany} idc={idc} compañia={compañia} id={user.id} rol={rol} setIsCreatorUsers={setIsCreatorUsers} isCreatorUsers={isCreatorUsers}/>} />
                                                <Route path='/usuarios/status' element={<TeamStatus to={'/usuarios/status'} idc={idc} compañia={compañia} id={user.id} rol={rol} typecompany={typecompany}/>}/>
                                                <Route path='/usuarios/:id' element={<EditorEquipo to={'/usuarios/:id'} idc={idc} rol={rol} setIsOpenGallery={setIsOpenGallery}/>}/>
                                                <Route path='/usuarios/grupos' element={<Group to={'/usuarios/grupos'}  idc={idc} compañia={compañia} id={user.id}  rol={rol} />} />
                                                <Route path='/usuarios/grupos/creador' element={<CreadorGroup to={'/usuarios/grupos/creador'}  idc={idc} compañia={compañia} id={user.id} business_id={business_id}  rol={rol} />} />
                                                <Route path='/usuarios/grupos/:id' element={<EditorGroup to={'/usuarios/grupos/:id'}  idc={idc} compañia={compañia} id={user.id} rol={rol} />} />
                                                <Route path='/usuarios/creador' element={<CreadorEquipo to={'/usuarios/creador' } idc={idc} uscompañia={compañia} rol={rol} business_id={business_id} typecompany={typecompany}/>} />
                                                <Route path='/usuarios/csv' element={<CreadorCvs to={'/usuarios/csv' } idc={idc} compañia={compañia} rol={rol} business_id={business_id} typecompany={typecompany}/>} />
                                                <Route path='/encuestas/send' element={<Encuestas to={'/encuestas/send'} idc={idc} compañia={compañia} id={user.id} rol={rol} />} />
                                                <Route path='/encuestas/:id' element={<EditorEncuestas to={'/encuestas/:id'} idc={idc} rol={rol} compañia={compañia} />} />
                                                <Route path='/encuestas/creador' element={<CreadorEncuestas to={'/encuestas/creador'} idc={idc} rol={rol} compañia={compañia}/>} />
                                                <Route path='/encuestas/diagnostic/:id' element={<DetailQuizUser to={'/encuestas/diagnostic/:id'}/>}/>
                                                <Route path='/inventario/stock' element={<StockCompany  to={'/inventario/stock'} idc={idc} rol={rol}/>}/>
                                            </>
                                        )}
                                        {( rol === 'AA-003')&&(
                                            <>
                                                <Route path='/movimientos/balance' element={<Balance to={'/movimientos/balance'} idc={idc} compañia={compañia} />}/>
                                                <Route path='/usuarios' element={<Equipo to={'/usuarios'}  typecompany={typecompany} idc={idc} compañia={compañia} id={user.id} rol={rol}  setIsCreatorUsers={setIsCreatorUsers}/>} />
                                                <Route path='/usuarios/:id' element={<EditorEquipo to={'/usuarios/:id'} idc={idc} rol={rol} setIsOpenGallery={setIsOpenGallery}/>}/>
                                                <Route path='/usuarios/csv' element={<CreadorCvs to={'/usuarios/csv' } idc={idc} compañia={compañia} rol={rol} business_id={business_id} typecompany={typecompany} />} />
                                                <Route path='/kits/lista' element={<KitList to={'/kits/lista'} idc={idc} compañia={compañia} rol={rol} />} />
                                                <Route path='/kits/creador' element={<CreateKits to={'/kits/creador'} idc={idc} compañia={compañia} business_id={business_id} uscompañia={compañia} rol={rol}/>} />
                                                <Route path='/kits/:id' element={<DescriptionKits to={'/kits/:id'} code_orden={code_orden} business_id={business_id} compañia={compañia} idc={idc} rol={rol} typecompany={typecompany}/>}/>
                                                <Route path='/reportes' element={<ReportsOrder to={'/reportes'}  idc={idc} rol={rol} typecompany={typecompany}/>}/>
                                                <Route path='/inventario/stock' element={<StockCompany  to={'/inventario/stock'} idc={idc} rol={rol}/>}/>
                                                <Route path='/inventario/asignado'  element={<StockAssignedProducts to={'/inventario/asignado'} idc={idc} rol={rol} compañia={compañia}/>}/>
                                            </>
                                        )}
                                        {(rol === 'SA-001'
                                            || rol === 'SA-002'
                                            || rol === 'SA-003'
                                            || rol === 'SA-004'
                                            || rol === 'SA-005')  && (
                                            <>
                                                <Route path='/blogs/lista' element={<ListBlogs  to={'/lista/blogs'} />} />
                                                <Route path='/blogs/creador' element={<CreatorBlogs to={'/blogs/creador'} />} />
                                                <Route path='/blogs/creador/:id' element={<EditBlogs to={'/blogs/creador/:id'} />} />
                                                <Route path='/seller' element={<Seller setIsOpenCreate={setIsOpenCreate} to={'/seller'}/>} />
                                                {/* Rutas de products base */}
                                                <Route path='/productos' element={<ListProductBass  to={'/productos'} rol={rol} idc={idc} compañia={compañia} id={user.id}/>} />
                                                <Route path='/productos/creador' element={<CreateProduct  to={'/productos/creador'}/>} />
                                                <Route path='/productos/:id' element={<EditProduct to={'/productos/:id'}/>} />
                                                {/* Rutas de categoria base */}
                                                <Route path='/productos/categoria' element={<Category to={'/productos/categoria'}/>} />
                                                <Route path='/productos/categoria/:id' element={<EditCategory to={'/productos/categoria/:id'}/>} />
                                                <Route path='/productos/categoria/creador' element={<CreateCategory to={'/productos/categoria/creador'}/>} />
                                                {/* Rutas de productos asignados*/}
                                                <Route path='/productos/asignados' element={<ListAsingProduct to={'/productos/asignados'}/>} />
                                                <Route path='/productos/asignados/:sku/:business_id' element={<EdictAsingProduct to={'/productos/asignados/:sku/:business_id'}/>} />
                                                {/* Rutas de Starco*/}
                                                <Route path='/startco/inversionista' element={<StartcoList to={'/startco/inversionista'}/>} />
                                                <Route path='/startco/inversionista/creador' element={<StarcoIversion to={'/startco/inversionista/creador'}/>} />
                                                <Route path='/startco/inversionista/:id' element={<StarcoIversion to={'/startco/inversionista/:id'}/>} />
                                                <Route path='/startco/inversionista/reserva/:id' element={<StartcoReserve to={'/startco/inversionista/reserva/:id'}/>} />
                                                <Route path='/startco/inversionista/agenda/:id' element={<StartcoList to={'/startco/inversionista/agenda/:id'}/>} />
                                                <Route path='/startco/spaces' element={<StartcoListCowork  to={'/startco/spaces'}/>} />
                                                <Route path='/startco/sales' element={<StartcoListSales  to={'/startco/sales'}/>} />
                                                <Route path='/registros/SHOFI' element={<ListRegister to={'/registros/SHOFI'}/>}/>
                                                <Route path='/registros/Siigo' element={<ListRegisterSiigo to={'/registros/Siigo'}/>} />
                                                <Route path='/registros/Demo' element={<ListRegisterDemo to={'/registros/Demo'}/>}/>
                                                {/* Control logistico */}
                                                <Route path='/order' element={<OrderConfirmationList to={'/order'}  rol={rol}/>}/>
                                                <Route path='/order/update' element={<UpdateOrden to={'/order/update'}  rol={rol}/>}/>
                                                <Route path='/order/update/:id' element={<UpdateOrdenImagen to={'/order/update/:id'}/>}/>
                                                <Route path='/order/:id/:ido' element={<OrderConfirmation to={'/order/:id/:ido'} idc={idc}  business_id={business_id}/>}/>
                                                <Route path='/recoleccion/search' element={<Recollections to={'/recoleccion/search'} idc={idc} rol={rol} setIsOpenCreate={setIsOpenCreate}/>}/>
                                                <Route path='/messengers' element={<MessengerModule  to={'/messengers'} idc={idc} rol={rol} setIsOpenCreate={setIsOpenCreate}/>}/>
                                                <Route path='/admin/rol' element={<Roles to={'/admin/rol'} idc={idc}  setIsOpenCreate={setIsOpenCreate}/>}/>
                                                <Route path='/admin/license' element={<Roles to={'/admin/license'} idc={idc}  setIsOpenCreate={setIsOpenCreate}/>}/>
                                            </>
                                        )}
                                        {(rol === 'US-001' || rol === 'AA-002') && (
                                            <>
                                                <Route path='/open/invitations' element={<ListQuizOpen to={'/open/invitations' } retur={retur} setSelectedId={setSelectedId} setIsEditorOpen={setIsEditorOpen} setShouldReload={setShouldReload} idc={idc} compañia={compañia} id={user.id} name={nombre} lastname={apellido} phone={telefono} email={email} setIsRegisterOpen={setIsRegisterOpen}/>} />
                                                <Route path='/open/invitations/csv' element={<CreadorUserCvs to={'/open/invitations/csv' }  setShouldReload={setShouldReload} idc={idc} ide={ide} company={compañia} id={user.id}/>} />
                                                <Route path='/open/invitations/:id' element={<DetailQuizOpen to={'/open/invitations/:id'}  setShouldReload={setShouldReload} idc={idc} compañia={compañia} id={user.id} />}/>
                                            </>
                                        )}
                                        {/* Componentes de modulo de invetario externo */}
                                        {(rol === 'AA-001' || rol === 'AA-002' || rol === 'SA-001') && ( module_active ) &&
                                            <>
                                                <Route path='/stock/productos/base' element={<ProductModule to={'/stock/productos/base'} idc={idc}  setIsOpenCreate={setIsOpenCreate}/>}/>
                                                <Route path='/stock/productos/categorias' element={<CategoryModule to={'/stock/productos/categorias'} idc={idc}  setIsOpenCreate={setIsOpenCreate}/>}/>
                                                <Route path='/rental/inventario/stock' element={<StockCompany  to={'/rental/inventario/stock'} idc={idc} rol={rol}/>}/>
                                                <Route path='/rental/inventario'  element={<StockAssignedProducts to={'/rental/inventario'} idc={idc} rol={rol} compañia={compañia}/>}/>
                                                <Route path='/company/recolecciones' element={<Recollections to={'/company/recolecciones'} idc={idc} rol={rol} setIsOpenCreate={setIsOpenCreate}/>}/>
                                                <Route path='/company/inventario' element={<InventoryCompany to={'/company/inventario'} idc={idc}/>}/>
                                                <Route path='/company/warehouse' element={<Warehouse to={'/company/warehouse'} idc={idc}  setIsOpenCreate={setIsOpenCreate}/>}/>
                                            </>
                                        }

                                    </>
                                ) : (
                                    <Route path='/login' element={<HomeUser setShouldReload={setShouldReload}  to={'/login'} idc={idc} compañia={compañia} rol={rol} />} />
                                )}
                                <Route path='*' element={<HomeUser to={'/'} setShouldReload={setShouldReload}  typecompany={typecompany} idc={idc} compañia={compañia} rol={rol} business_id={business_id}  id={user.id}  setIsRegisterOpen={setIsRegisterOpen} name={nombre} lastname={apellido} phone={telefono} email={email} />} />
                            </Routes>
                        </div>
                    </section>}
                    {hideNavBar &&
                        <Routes>
                            <Route path='/contestador/:id' element={<ContestadorEncuestas to={'/contestador/:id'} idc={idc} compañia={compañia} id={user.id}/>}/>
                            <Route path='/manuales' element={<Manuals to={'/manuales'}/>}/>
                            <Route path='/manuales/:route' element={<Manuals to={'/manuales/:route'}/>}/>
                        </Routes>
                    }
                </div>
            </IdProvider></LoadProvider>
        </AuthProvider>
    );
}

export default PrivateRouter;