export const ItemProducModule = ({img, name , description, sku, category}) => {
    return (  
        <>
            <div className="avatar">
                {img ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{name}</h2>
                )}
            </div>
            <p>{name}</p>
            <p>{description}</p>
            <p>{category}</p>
            <p>{sku}</p>
        </>
    );
}