// formateadorDeFecha.js
export function formateDate(timestamp) {
    if (!timestamp) return "";
    
    try {
        const fecha = timestamp.toDate(); 
        return fecha.toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }); 
    } catch (e) {
        console.error("Error al convertir fecha:", e);
        return "";
    }
}
