import axios from 'axios';
import { addDoc, collection} from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { FiSave } from "react-icons/fi";
import { toast } from "react-toastify";
import { db } from "../../../../../firebase/config";
import { useLoad } from "../../../../../context/LoadContext";
import colombia from "../../../../../firebase/colombia";
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';

const MAPS_API_KEY = "AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg";

// Personalización del ícono del marcador
const MarkerIcon = new L.Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/684/684908.png',
    iconSize: [35, 35],
    iconAnchor: [17, 35],
});

// Componente que controla el movimiento del mapa
const MapUpdater = ({ lat, lng }) => {
    const map = useMap(); // Hook para acceder al mapa
    useEffect(() => {
        map.setView([lat, lng], 30, { animate: true });
    }, [lat, lng, map]);
    return null;
};

export const CreatorWarehouse = ({ idc, setLoading, setIsOpenCreate }) => {
    const { triggerReload } = useLoad();
    const mapRef = useRef(null); // Referencia al mapa
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const [formData, setFormData] = useState({
        idc: idc,
        name: "",
        country: "Colombia",
        state: "",
        city: "",
        andress: "",
        last_andress: "",
        name_contact: "",
        phone:"",
        lat: 4.711,
        lng: -74.0721,
    });

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            // Validar que solo se ingresen números
            if (/^[0-9]*$/.test(value)) {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else {
            // Manejo de otros cambios en el formulario
            setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
            if (name === 'state') {
                setSelectedDepartment(value);
            }
        }
    }, []);
    
    

    useEffect(() => {
        if (selectedDepartment) {
            setCities(colombia[selectedDepartment]);
        }
    }, [selectedDepartment]);
    

    const validateForm = () => {
        let isValid = true;
        let errorMessage = ""; // Variable para acumular los mensajes de error
        // Validar campos vacíos
        if (!formData.andress) {
            errorMessage = "El nombre es obligatorio.";
            isValid = false;
        }
        // Si no es válido, mostrar un solo mensaje de error con toast
        if (!isValid) {
            toast.error(errorMessage);
        }
        // Retornar si el formulario es válido o no
        return isValid;
    };

    // Actualizar el centro del mapa cuando cambian las coordenadas
    useEffect(() => {
        if (mapRef.current) {
            const { lat, lng } = formData;
            mapRef.current.setView([lat, lng], 13, { animate: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.lat, formData.lng]);

    // useEffect para actualizar las coordenadas cuando se completan los campos necesarios
    useEffect(() => {
        const { country, state, city, andress } = formData;
        if (country && state && city && andress) {
            getCoordinates(); // Obtener coordenadas automáticamente
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.country, formData.state, formData.city, formData.andress]);

    // Obtener coordenadas de la dirección proporcionada
    const getCoordinates = async () => {
        const address = `${formData.andress}, ${formData.city}, ${formData.state}, ${formData.country}`;
        const encodedAddress = encodeURIComponent(address);
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${MAPS_API_KEY}`
            );
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                setFormData(prevState => ({ ...prevState, lat, lng }));
                return true;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                return false;
            }
        } catch (error) {
            console.error("Error obteniendo coordenadas:", error);
            toast.error("Error al obtener coordenadas.");
            return false;
        }
    };

    // Actualizar coordenadas al arrastrar el marcador
    const handleMarkerDragEnd = (e) => {
        const { lat, lng } = e.target.getLatLng();
        setFormData((prevState) => ({ ...prevState, lat, lng }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validar el formulario
        if (!validateForm()) {
            return; // Si hay errores, no continuar
        }
        setLoading(true);
        const coordsObtained = await getCoordinates(); // Buscar coordenadas
        if (!coordsObtained) return; // Si no se obtuvieron coordenadas, no continuar
        try {
            setLoading(true);
            // Guardar el documento en Firestore, incluyendo la URL de la imagen
            await addDoc(collection(db, "collection_warehouse"), {
                ...formData,
                date_delivery: new Date(), 
            });
            toast.success("Documento creado con éxito.");
            setIsOpenCreate(isOpenCreate => !isOpenCreate);
            // Después de guardar, disparar la recarga
            triggerReload();
            setFormData({
                idc: idc,
                name: "",
                country: "Colombia",
                state: "",
                city: "",
                andress: "",
                last_andress: "",
                name_contact: "",
                phone:"",
                lat: "",
                lng: "",
            });
        } catch (error) {
            console.error("Error creando el documento:", error.message);
            toast.error("Error creando el documento: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="section-open-create-form">
            <section className="open-create-form-img">
                <MapContainer
                    center={[formData.lat, formData.lng]}
                    style={{ width: '100%', height: '400px', borderRadius: '30px', }}
                    whenCreated={map => (mapRef.current = map)}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker
                        position={[formData.lat, formData.lng]}
                        draggable
                        eventHandlers={{ dragend: handleMarkerDragEnd }}
                        icon={MarkerIcon}
                    >
                        <Popup>{formData.andress}</Popup>
                    </Marker>
                    <MapUpdater lat={formData.lat} lng={formData.lng} />
                </MapContainer>
            </section>
            <form onSubmit={handleSubmit} className="open-create-form-dates">
                <div className='input-form-create'>
                    <label>Nombre locación</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required/>
                </div>
                <div className='input-form-create'>
                    <label>Departamento:</label>
                    <select name="state" value={selectedDepartment} onChange={handleChange} required>
                        <option value="" disabled>Seleccione </option>
                        {Object.keys(colombia).map((department) => (
                            <option key={department} value={department}>{department}</option>
                        ))}
                    </select>
                </div>
                <div className='input-form-create'>
                    <label>Ciudad:</label>
                    <select name="city" value={formData.city} onChange={handleChange} required>
                        <option value="" disabled>Seleccione</option>
                        {cities.map((city) => (
                            <option key={city} value={city}>{city}</option>
                        ))}
                    </select>
                </div>
                <div className='input-form-create'>
                    <label>Direccion(Obligatorio)</label>
                    <input type="text" name="andress" value={formData.andress} onChange={handleChange} required/>
                </div>
                <div className='input-form-create'>
                    <label>Detalles de dirección</label>
                    <input type="text" name="last_andress" value={formData.last_andress} onChange={handleChange}/>
                </div>
                <div className='input-form-create'>
                    <label>Nombre de contacto</label>
                    <input type="text" name="name_contact" value={formData.name_contact} onChange={handleChange} required/>
                </div>
                <div className='input-form-create'>
                    <label>Teléfono</label>
                    <input type="text" name="phone" value={formData.phone} onChange={handleChange} required/>
                </div>
                <button className="button-new space-top" type="submit">
                    Crear Bodega <FiSave />
                </button>
            </form>
        </div>
    );
}
