export  const TitleRoles = () => {
    return (
        <>
            <p></p>
            <p>Nombre</p>
            <p>ID</p>
            <p>Date</p>
            <p>Inventario</p>
        </>
    );
}
