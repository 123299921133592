import React, { useState, useEffect, useCallback } from 'react';
import { CgClose } from "react-icons/cg";
import { collection, query, where, getDocs, getDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase/config';
import { useLoad } from '../../../../../context/LoadContext';
import { toast } from 'react-toastify';
import { FiSave } from "react-icons/fi";
import { BiCheck, BiX } from "react-icons/bi";

export  const EditRecollections = ({ setLoading, setIsOpenCreate, idItem, setIdItem, rol }) => {
    const { triggerReload } = useLoad();
    const [ search, setSearch ] = useState('');
    const [ products, setProducts ] = useState([]);
    const [ info, setInfo ] = useState([]);
    const [ infoMessenger, setMessenger ] = useState([]);
    const [ error, setError ] = useState(null);
    const [ selectedLocation, setSelectedLocation] = useState(null);
    const [ formData, setFormData] = useState({
        idc: "",
        id_warehouse:"",
        date_delivery:"",
        time_delivery:"",
        selectProduc:[],
        note:"",
        status_delivery:"",
        messenger:"",
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                const docRef = doc(db, "collection_recollections", idItem);
                const docSnap = await getDoc(docRef);
    
                if (docSnap.exists()) {
                    const itemData = docSnap.data();
                    setFormData({ ...itemData });
    
                    // Cargar la ubicación relacionada si existe
                    if (itemData.id_warehouse) {
                        const locationRef = doc(db, "collection_warehouse", itemData.id_warehouse);
                        const locationSnap = await getDoc(locationRef);
    
                        if (locationSnap.exists()) {
                            setSelectedLocation(locationSnap.data());
                        } else {
                            toast.error("Ubicación no encontrada.");
                        }
                    }
                } else {
                    toast.error("No se encontró el documento.");
                }
            } catch (error) {
                console.error("Error cargando los datos:", error);
                toast.error("Error cargando los datos.");
            }
        };
    
        loadData();
    }, [idItem]);
    
    // Función para obtener la fecha mínima (hoy)
    const getTodayDate = () => new Date().toISOString().split('T')[0];

    const handleTimeChange = (e) => {
        let [hours, minutes] = e.target.value.split(':');
    
        // Forzar los minutos a '00' siempre
        minutes = '00';
    
        const normalizedTime = `${hours}:${minutes}`;
    
        // Actualizar el estado con la hora normalizada
        setFormData((prev) => ({
            ...prev,
            time_delivery: normalizedTime,
        }));
    };
    
    const getCurrentExactHour = () => {
        const now = new Date();
    
        // Si hay minutos o segundos, avanza a la siguiente hora exacta
        if (now.getMinutes() > 0 || now.getSeconds() > 0) {
            now.setHours(now.getHours() + 1, 0, 0, 0); // Avanza a la siguiente hora exacta
        } else {
            now.setMinutes(0, 0, 0); // Asegúrate de que esté en la hora exacta
        }
    
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = '00'; // Siempre "00"
    
        return `${hours}:${minutes}`;
    };
    
    const getMaxTime = () => {
        const now = new Date();
        now.setHours(now.getHours() + 4, 0, 0, 0); // Sumar 4 horas exactas
    
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = '00'; // Siempre "00"
    
        return `${hours}:${minutes}`;
    };
    
    // Cargar Bodegas
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'collection_warehouse');
                const u = query( q, where('idc', '==', formData.idc));
                const querySnapshot = await getDocs(u);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [formData.idc, setLoading]);

    // Cargar lista de mensajeros
    useEffect(() => {
        const fetchMessengers = async () => {
            try {
                const q = collection(db, 'directory_messenger');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMessenger(fetchedInfo);
            } catch (err) {
                console.error("Error obteniendo mensajeros:", err);
            }
        };
        fetchMessengers();
    }, []);

    const handleChange = useCallback(
        (e) => {
            const { name, value } = e.target;
            // Actualizar solo el campo del formulario correspondiente
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            // Si se selecciona una nueva locación, actualizar `selectedLocation`
            if (name === 'id_warehouse') {
                const location = info.find((item) => item.id === value);
                if (location) {
                    setSelectedLocation(location); // Actualizar solo si hay un cambio de locación
                }
            }
        },
        [info]
    );
    
    // Obtener productos filtrados por 'idc'
    const fetchProductsByIdc = async () => {
        try {
            const q = query(
                collection(db, 'base_product_stock_company'),
                where('idc', '==', formData.idc)
            );

            const querySnapshot = await getDocs(q);
            const productList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setProducts(productList);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    // Efecto para cargar productos cuando 'idc' cambia
    useEffect(() => {
        if (formData.idc) fetchProductsByIdc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.idc]);

    // Función de filtrado con validaciones
    const filterProducts = (searchTerm) => {
        if (!searchTerm.trim()) return products;

        const lowerSearch = searchTerm.toLowerCase();
        return products.filter((product) => {
            const { sku = '', name = '', category = '' } = product; // Valores por defecto
            return (
                sku.toLowerCase().includes(lowerSearch) ||
                name.toLowerCase().includes(lowerSearch) ||
                category.toLowerCase().includes(lowerSearch)
            );
        });
    };

    const handleDateTimeChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    // Función para agregar un producto a `selectProduc`
    const addProduct = (product) => {
        const alreadySelected = formData.selectProduc.some((p) => p.id === product.id);
        if (alreadySelected) return; // Evita duplicados

        const newProduct = { ...product, qty: 1 }; // Inicializa 'qty' en 1
        setFormData((prevState) => ({
            ...prevState,
            selectProduc: [...prevState.selectProduc, newProduct],
        }));
        setSearch("");
    };

    // Función para eliminar un producto de `selectProduc`
    const removeProduct = (index) => {
        setFormData((prevState) => ({
            ...prevState,
            selectProduc: prevState.selectProduc.filter((_, i) => i !== index),
        }));
    };

    // Función para actualizar la cantidad de un producto en `selectProduc`
    const updateQty = (index, qty) => {
        const updatedProducts = [...formData.selectProduc];
        updatedProducts[index].qty = qty;

        setFormData((prevState) => ({
            ...prevState,
            selectProduc: updatedProducts,
        }));
    };

    const filteredProducts = filterProducts(search);

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validaciones
        const hasValidProduct = formData.selectProduc.some(product => product.qty >= 1);
        const isLocationSelected = formData.id_warehouse !== "";
        const isDateFilled = formData.date_delivery !== "";
        const isTimeFilled = formData.time_delivery !== "";
        if (!hasValidProduct) {
            toast.error("Debes seleccionar al menos un producto con cantidad mayor o igual a 1.");
            return;
        }
        if (!isLocationSelected) {
            toast.error("Debes seleccionar una locación.");
            return;
        }
        if (!isDateFilled) {
            toast.error("Debes seleccionar una fecha de recolección.");
            return;
        }
        if (!isTimeFilled) {
            toast.error("Debes seleccionar una hora válida.");
            return;
        }
        setLoading(true);
        try {
            setLoading(true);
            // Guardar el documento en Firestore
            const docRef = doc(db, "collection_recollections", idItem); // Referencia al documento
            // Actualizar o crear el documento en Firestore
            await setDoc(docRef, { ...formData}, { merge: true });
            toast.success("Documento actualizado con éxito.");
            setIsOpenCreate((isOpenCreate) => !isOpenCreate); // Cerrar el modal
            // Después de guardar, disparar la recarga
            setIdItem(null); 
            triggerReload();
        } catch (error) {
            console.error("Error creando el documento:", error.message);
            toast.error("Error creando el documento: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSCancel = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const docRef = doc(db, "collection_recollections", idItem); // Referencia al documento
            // Actualizar únicamente el campo 'status_delivery' con valor numérico 5
            await setDoc(docRef, { status_delivery: 5 }, { merge: true });
            toast.success("Documento actualizado con éxito.");
            setIsOpenCreate((isOpenCreate) => !isOpenCreate); // Cerrar el modal
            setIdItem(null); // Resetear idItem
            triggerReload(); // Recargar los datos
        } catch (error) {
            console.error("Error actualizando el documento:", error.message);
            toast.error("Error actualizando el documento: " + error.message);
        } finally {
            setLoading(false); // Finalizar carga
        }
    };


    const handleConfirm = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const docRef = doc(db, "collection_recollections", idItem); // Referencia al documento
            // Actualizar únicamente el campo 'status_delivery' con valor numérico 5
            await setDoc(docRef, { status_delivery: 1 }, { merge: true });
            toast.success("Orden Confirmada.");
            triggerReload(); // Recargar los datos
        } catch (error) {
            console.error("Error actualizando el documento:", error.message);
            toast.error("Error actualizando el documento: " + error.message);
        } finally {
            setLoading(false); // Finalizar carga
        }
    };


    const handleAssign = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const docRef = doc(db, "collection_recollections", idItem); // Referencia al documento
            // Actualizar únicamente el campo 'status_delivery' con valor numérico 5
            await setDoc(docRef, { status_delivery: 2, messenger:formData.messenger }, { merge: true });
            toast.success("Documento actualizado con éxito.");
            setIsOpenCreate((isOpenCreate) => !isOpenCreate); // Cerrar el modal
            setIdItem(null); // Resetear idItem
            triggerReload(); // Recargar los datos
        } catch (error) {
            console.error("Error actualizando el documento:", error.message);
            toast.error("Error actualizando el documento: " + error.message);
        } finally {
            setLoading(false); // Finalizar carga
        }
    };

    // Filtrar el mensajero guardado para mostrarlo como la primera opción y el resto después
    const selectedMessenger = infoMessenger.find(m => m.id === formData.messenger);
    const otherMessengers = infoMessenger.filter(m => m.id !== formData.messenger);
    if (error) return <div>Error: {error.message}</div>;
    return (  
        <div className="section-open-create-form-one">
            <section className="section-open-one-dates">
                <article className="open-one-dates-left">
                    <div className="input-form-create-order">
                        <label>Locación(Obligatorio)</label>
                        <select name="id_warehouse" value={formData.id_warehouse} onChange={handleChange} required disabled={formData.status_delivery !== 0}>
                            <option value="" disabled hidden>Seleccionar...</option>
                            {info.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='info-date-warehouse'>
                        {selectedLocation ? (
                            <>
                                <p><strong>Direccion:</strong>{selectedLocation.country} - {selectedLocation.state} - {selectedLocation.city} <br></br> {selectedLocation.andress} - {selectedLocation.last_andress} </p>
                                <p><strong>Contacto:</strong> {selectedLocation.name_contact} |  {selectedLocation.phone} </p>
                            </>
                        ) : (
                            <p>No hay locación seleccionada</p>
                        )}
                    </div>
                </article>
                <article className="open-one-dates-right">
                    <div className="input-form-create-order">
                        <label>Fecha Recolección</label>
                        <input
                            type="date"
                            name="date_delivery"
                            min={getTodayDate()}
                            value={formData.date_delivery}
                            onChange={handleDateTimeChange}
                            required
                            disabled={formData.status_delivery !== 0}
                        />
                    </div>
                    <div className="input-form-create-order">
                        <label>Hora (Min. dentro de 4 horas)</label>
                        <input
                            type="time"
                            name="time_delivery"
                            min={getCurrentExactHour()} // Hora exacta actual
                            max={getMaxTime()} // Máximo dentro de 4 horas
                            step="3600" // Solo intervalos de una hora (3600 segundos)
                            value={formData.time_delivery}
                            onChange={handleTimeChange}
                            required
                            disabled={formData.status_delivery !== 0}
                        />
                    </div>
                </article>
            </section>

            <section className="section-open-one-product">
                <article className="section-open-one-product-title">
                    <p>SKU</p>
                    <p>Nombre</p>
                    <p>SKU</p>
                    <p>Qty</p>
                    <p></p>
                </article>
                <div className='line'></div>
                <article className="section-open-one-product-scroll">
                    <div className='one-product-scroll-productselect'>
                        {formData.selectProduc.length === 0 
                            ?<div>
                                <p className='text-notification'>No has agregado articulos</p>
                            </div>
                            :formData.selectProduc.map((product, index) => (
                                <div className="item-one-product" key={product.id}>
                                    <img alt={product.name} src={product.img} />
                                    <p>{product.name}</p>
                                    <p>{product.sku}</p>
                                    <input
                                        type="number"
                                        value={product.qty}
                                        min="1"
                                        onChange={(e) =>
                                            updateQty(index, parseInt(e.target.value, 10))
                                        }
                                        disabled={formData.status_delivery !== 0}
                                    />
                                    {formData.status_delivery === 0
                                        ?<button className="icon-edit" onClick={() => removeProduct(index)}>
                                            <CgClose />
                                        </button>
                                        :<div></div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                    {formData.status_delivery === 0 &&
                        <div className="container-form-one-search">
                            <div className="input-form-one-search">
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Buscar por SKU o nombre"
                                    value={search}
                                    onChange={handleSearchChange}
                                />
                                {/* Array de busqueda */}
                                {search.trim() === '' ? (
                                        <></>
                                    ) : filteredProducts.length === 0 ? (
                                        <>
                                            <div className='line'></div>
                                            <p className='text-notification'>No se encontraron articulos</p>
                                        </>
                                    ) : (
                                        <div className='one-product-scroll-product'>
                                            <div className='line'></div>
                                            {filteredProducts
                                                .filter((product) => !formData.selectProduc.some((p) => p.id === product.id)) // Filtrar productos ya seleccionados
                                                .map((product) => (
                                                    <div className="item-one-product" key={product.id}>
                                                        <img alt={product.name} src={product.img} />
                                                        <p>{product.name}</p>
                                                        <p>{product.sku}</p>
                                                        <div> </div>
                                                        <button onClick={() => addProduct(product)} className='boton-item-one-product'>
                                                            Agregar
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                </article>
            </section>
            <section className="input-form-one-create">
                <label>Notas (Opcional)</label>
                <textarea type="text" name="note" value={formData.note} onChange={handleChange} disabled={formData.status_delivery !== 0}/>
            </section>
            {['SA-001', 'SA-002', 'SA-003', 'SA-004', 'SA-005'].includes(rol)
                ?<>
                    {formData.status_delivery === 0 &&
                        <div className='form-one-create-botoners'>
                            <button className="button-delete" onClick={handleSCancel}>
                                Cancelar Orden <BiX />
                            </button>
                            <button className="button-new" onClick={handleSubmit}>
                                Actualizar Orden <FiSave />
                            </button>
                            <button className="button-new2" onClick={handleConfirm }> Confirmar <BiCheck /></button>
                        </div>  
                    }  
                    {[1, 2].includes(formData.status_delivery) && (
                        <div className='form-one-create-botoners1'>
                            <div className="input-form-messenger">
                                <label>Mensajero (Obligatorio)</label>
                                <select
                                    name="messenger"
                                    value={formData.messenger}
                                    onChange={handleChange}
                                    required
                                >
                                    {/* Mostrar el mensajero seleccionado como primera opción */}
                                    {selectedMessenger ? (
                                        <option value={selectedMessenger.id}>
                                            {selectedMessenger.name}
                                        </option>
                                    ) : (
                                        <option value="" disabled hidden>
                                            Seleccionar...
                                        </option>
                                    )}

                                    {/* Mostrar las demás opciones de mensajeros */}
                                    {otherMessengers.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button className="button-new2" onClick={handleAssign}>
                                Asignar <FiSave />
                            </button>
                        </div>
                    )}
                </>
                :<>
                    {formData.status_delivery === 0 && <button className="button-new2" onClick={handleConfirm }> Confirmar <BiCheck /></button>}
                    {[1, 0].includes(formData.status_delivery) &&<button className="button-delete" onClick={handleSCancel}> Cancelar Orden <BiX /> </button> }
                </>
            }
        </div>
    );
}