import React from 'react';
import './Constant.scss';

export const Loading = ()  => {
    return (
        <div className="loading-container">
            <div className="loading-circle"></div>
        </div>
    );
}


export const InfoLoading = ()  => {
    return (
        <div className="info-loading-container">
            <div className="loading-circle"></div>
        </div>
    );
}

export const PageLoading = ()  => {
    return (
        <div className="loading">
            <div className="loading-circle"></div>
        </div>
    );
}


export const SectionLoading = ()  => {
    return (
        <div className="section-container">
            <div className="loading-circle"></div>
        </div>
    );
}

export const DivLoading = ()  => {
    return (
        <div className="div-container">
            <div className="loading-circle"></div>
            <h4>Cargando...</h4>
        </div>
    );
}