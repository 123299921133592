/* eslint-disable react-hooks/exhaustive-deps */
// Bibliotecas externas
import { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PieChart } from '@mui/x-charts/PieChart';
// Componentes Internos
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { DatesUser } from "./DatesUser";
import { ResponseStadistics } from "./ResponseStadistics";
import { NoOpenQuiz } from "./NoOpenQuiz";
import { CloseRestQuiz } from "./CloseRestQuiz";
import { BiX } from "react-icons/bi";
import { GrFormNextLink } from "react-icons/gr";
import { IoCloudUploadOutline } from "react-icons/io5";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from '../../../firebase/config';
import { useLoginContext } from "../../../context/LoginContext";
import { Loading } from "../../../Loanding";


export const RestQuizOpen = () => {
    const navigate = useNavigate();
    const {setLoading} = useLoginContext();
    const [files, setFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const {ide, idc, idu} = useParams();
    const [answer, setAnswer] = useState ([]);
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [encuesta, setEncuesta] = useState(null);
    const [respuestas, setRespuestas] = useState({});
    const [preguntaActual, setPreguntaActual] = useState(0);
    const [subPreguntaActual, setSubPreguntaActual] = useState({});
    const [error, setError] = useState(null);
    const [totalMaximo, setTotalMaximo] = useState(0);
    const [totalRespuestas, setTotalRespuestas] = useState(0);
    const [finished, setFinished] = useState(false);
    const porcentaje = totalMaximo > 0 ? (totalRespuestas / totalMaximo) * 100 : 0;
    const pregunta = encuesta && encuesta.questions[preguntaActual];
    const subPreguntaActiva = pregunta && pregunta.activesub && subPreguntaActual[pregunta.id];
    const subPregunta = subPreguntaActiva ? pregunta.subquestions.find(sub => sub.id === subPreguntaActual[pregunta.id]) : null;
    const [divClass, setDivClass] = useState('container-button-quiz');

    const updateDivClass = (opciones) => {
        const hasLongOptions = opciones.some(opc => opc.opcione.length >= 4);
        setDivClass(hasLongOptions ? 'block-button-quiz' : 'container-button-quiz');
    };

    useEffect(() => {
        const fetchAllData = async () => {
          try {
            // Referencias a los documentos
            const docUserRef = doc(db, "users_open", idu);
            const docQuizRef = doc(db, "quiz", ide);
            const docCompanyRef = doc(db, "company", idc);
            
            // Consultas en paralelo
            const [docSnap, docSnapQuiz, docSnapCompany] = await Promise.all([
              getDoc(docUserRef),
              getDoc(docQuizRef),
              getDoc(docCompanyRef)
            ]);
            
            const userData = docSnap.exists() ? docSnap.data() : "";
            setUser(userData);
            setEncuesta(docSnapQuiz.exists() ? docSnapQuiz.data() : "");
            setCompany(docSnapCompany.exists() ? docSnapCompany.data() : "");
            
            // Si el usuario tiene el campo "ider", consultamos la respuesta
            if (userData && userData.ider) {
              const docAnswerRef = doc(db, "quizanswer_open", userData.ider);
              const docSnapAnswer = await getDoc(docAnswerRef);
              if (docSnapAnswer.exists()) {
                setAnswer(docSnapAnswer.data());
              }
            }
          } catch (error) {
            setError(error);
            toast.error("Error fetching user data:", error);
          } finally {
            setLoading(false);
          }
        };  
        fetchAllData();
    }, [idc, ide, idu]);
      
    
    useEffect(() => {
        if (subPregunta) {
            updateDivClass(subPregunta.opcion);
        } else if (pregunta) {
            updateDivClass(pregunta.opcion);
        }
    }, [pregunta, subPregunta]);

    useEffect(() => {
        if (encuesta) {
            let sumaMaxima = 0;
            encuesta.questions.forEach(pregunta => {
                sumaMaxima += Math.max(...pregunta.opcion.map(opc => opc.value));
                if (pregunta.activesub) {
                    pregunta.subquestions.forEach(subPregunta => {
                        sumaMaxima += Math.max(...subPregunta.opcion.map(subOpc => subOpc.value));
                    });
                }
            });
            setTotalMaximo(sumaMaxima);
        }
    }, [encuesta]);
    
    const handleRespuesta = (idPregunta, opcionSeleccionada, idSubpregunta = null) => {
        // Encuentra el valor de la opción seleccionada
        const valorSeleccionado = idSubpregunta 
        ? subPregunta.opcion.find(opc => opc.opcione === opcionSeleccionada).value
        : pregunta.opcion.find(opc => opc.opcione === opcionSeleccionada).value;
        // Preparar la nueva respuesta
        const nuevaRespuesta = { opcione: opcionSeleccionada, value: valorSeleccionado };
        // Actualizar el estado de respuestas
        setRespuestas(prevRespuestas => {
            const respuestasActualizadas = { ...prevRespuestas };
            if (!respuestasActualizadas[idPregunta]) {
                respuestasActualizadas[idPregunta] = { respuesta: {}, subquestions: {} };
            }
            if (idSubpregunta) {
                // Si estamos respondiendo a una subpregunta
                respuestasActualizadas[idPregunta].subquestions[idSubpregunta] = { respuesta: nuevaRespuesta };
            } else {
                // Si estamos respondiendo a una pregunta principal
                respuestasActualizadas[idPregunta] = { respuesta: nuevaRespuesta, subquestions: respuestasActualizadas[idPregunta].subquestions };
            }
            return respuestasActualizadas;
        });
        // Manejar la navegación entre preguntas y subpreguntas
        if (!idSubpregunta && encuesta.questions[preguntaActual].activesub) {
            if (opcionSeleccionada === encuesta.questions[preguntaActual].controlResponse) {
                // Si la respuesta coincide con controlResponse, muestra la primera subpregunta
                setSubPreguntaActual({ [idPregunta]: encuesta.questions[preguntaActual].subquestions[0].id });
            } else {
                // Si no coincide, salta a la siguiente pregunta principal
                setPreguntaActual(prev => prev + 1);
                setSubPreguntaActual({}); // Asegúrate de reiniciar las subpreguntas
            }
        } else if (idSubpregunta) {
            // Manejo de las subpreguntas (igual que antes)
            const subPreguntas = encuesta.questions[preguntaActual].subquestions;
            const indexActual = subPreguntas.findIndex(sub => sub.id === idSubpregunta);
            if (indexActual < subPreguntas.length - 1) {
                setSubPreguntaActual({ ...subPreguntaActual, [idPregunta]: subPreguntas[indexActual + 1].id });
            } else {
                setPreguntaActual(prev => prev + 1);
                setSubPreguntaActual({});
            }
        } else {
            // Si no hay subpreguntas, simplemente avanza a la siguiente pregunta principal
            setPreguntaActual(prev => prev + 1);
        }
    };
    
    useEffect(() => {
        let puntajeTotal = 0;
        // Iterar sobre cada pregunta y sus subpreguntas
        for (const idPregunta in respuestas) {
            const pregunta = respuestas[idPregunta];
    
            // Sumar el valor de la respuesta principal (si existe)
            if (pregunta.respuesta && pregunta.respuesta.value) {
                puntajeTotal += parseInt(pregunta.respuesta.value);
            }
    
            // Sumar los valores de las respuestas de las subpreguntas (si existen)
            if (pregunta.subquestions) {
                for (const idSubpregunta in pregunta.subquestions) {
                    const subpregunta = pregunta.subquestions[idSubpregunta];
                    if (subpregunta.respuesta && subpregunta.respuesta.value) {
                        puntajeTotal += parseInt(subpregunta.respuesta.value);
                    }
                }
            }
        }
        setTotalRespuestas(puntajeTotal)
        // Aquí puedes hacer lo que necesites con el puntaje total, como actualizar un estado.
    }, [respuestas]); 
    
    // Preparar los datos para la gráfica circular
    const porcentajeRedondeado = Math.round(porcentaje); // Redondea el porcentaje a un número entero
    const porcentajeRestanteRedondeado = 100 - porcentajeRedondeado; 
    const datosGrafica = [
        { label: 'Positivo', value: porcentajeRedondeado },
        { label: 'Negativo', value: porcentajeRestanteRedondeado }
    ];
    // Cargar imagenes
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const totalFiles = files.length + selectedFiles.length;
        // Verificar si el total de archivos supera el límite de 4
        if (totalFiles > 4) {
            toast.error("Puedes cargar un máximo de 4 imágenes. ");
            return;
        }
        // Agregar los nuevos archivos a los ya existentes
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        // Crear previsualizaciones para los nuevos archivos y agregarlas a las existentes
        const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, fileIndex) => fileIndex !== index);
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => urlIndex !== index);
        setFiles(newFiles);
        setPreviewUrls(newPreviewUrls);
    };  
    // Guardado de respuestas
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // Referencia al documento del usuario
            const userDocRef = doc(db, 'users_open', idu);
            const userDocSnap = await getDoc(userDocRef);
            // Verificar si ya ha respondido la encuesta
            if (userDocSnap.exists() && userDocSnap.data().answer === true) {
                toast.error("La encuesta ya ha sido respondida");
                navigate('/');
                return; // Detener la ejecución si la encuesta ya fue respondida
            }
            const randomId = Math.random().toString(36).substring(2, 15);
            let imageUrls = [];
            if (files.length > 0) {
                imageUrls = await Promise.all(files.map(async (file) => {
                    const fileName = `image-shofiquiz-open-${randomId}-${new Date().toISOString()}`;
                    const fileRef = ref(storage, `quiz/open/${fileName}`);
                    await uploadBytes(fileRef, file);
                    return await getDownloadURL(fileRef);
                }));
            };
            const respuestaDoc = {
                idu: idu,
                idc: idc,
                ide: ide,
                respuestas: respuestas,
                fecha: new Date(),
                resultado:porcentaje,
                title:encuesta.title,
                files: imageUrls,
            };
            const docRef = await addDoc(collection(db, 'quizanswer_open'), respuestaDoc);
            const respuestaId = docRef.id;
            setFinished(true)
            await updateDoc(userDocRef, {
                answer:true,
                ider:respuestaId,
            });
            setLoading(false);
            toast.success("Respuestas guardadas con éxito");
        } catch (error) {
            toast.error("Error al guardar respuestas: ", error);
        };
    };

    // Rutinas de carga
    if ( error ) return <p>Error: {error.message}</p>;
    // quiz no encontrado comunicarse con comunidad
    if ( encuesta === null || company === null || user === null)  return <Loading />;
    // quiz no encontrado comunicarse con comunidad
    if ( encuesta === "" || company === "" || user === "") return <NoOpenQuiz />;
    // usuario no registrado
    if ( user === null ) return <CloseRestQuiz company= {company}/>;
    // Para completar datos
    if ( user.status ) return (<DatesUser user={user} idu={idu} setLoading={setLoading} />);
    // Si la encuesta ya fue respondida
    if ( user.answer ) return (<ResponseStadistics user={user} ide={ide} answer={answer} setLoading={setLoading}/>);
    // inicio de encuesta
    return (
        <>
            {subPregunta ? (
                // Mostrar solo la subpregunta activa
                <section className="wimdons-quiz">
                    <div key={subPregunta.id} className="wimdons-quiz-questions">
                        <section className="wimdons-quiz-questions-gallery">
                            {subPregunta.img && subPregunta.img.length > 0 && (
                                <>
                                    {subPregunta.img.filter(url => url).map((url, index) => (
                                        <img className="wimdons-quiz-questions-img" key={index} src={url} alt={`User ${index + 1}`} />
                                    ))}
                                </>
                            )}
                        </section>
                        <p className={subPregunta.text.length >= 100 ? "title2-quiz": "title-quiz"}>{subPregunta.text}</p>
                        <div className={divClass}>
                            {subPregunta.opcion.map((subOpc) => (
                                <button
                                    key={subOpc.id}
                                    onClick={() => handleRespuesta(pregunta.id, subOpc.opcione, subPregunta.id)}
                                >
                                        <p className={subOpc.opcione.length >= 4 ? 'contect-quiz' : 'contect-quiz2'}>
                                            {subOpc.opcione}
                                        </p>
                                </button>
                            ))}
                        </div>
                    </div>
                </section>
            ) : (
                // Mostrar la pregunta principal si no hay subpregunta activa
                pregunta && (
                    <section className="wimdons-quiz">
                        <div key={pregunta.id} className="wimdons-quiz-questions">
                            <section className="wimdons-quiz-questions-gallery">
                                {pregunta.img && pregunta.img.length > 0 && (
                                    <>
                                        {pregunta.img.filter(url => url).map((url, index) => (
                                            <img className="wimdons-quiz-questions-img" key={index} src={url} alt={`User ${index + 1}`} />
                                        ))}
                                    </>
                                )}
                            </section>
                            <p className={pregunta.text.length >= 100 ? "title2-quiz": "title-quiz"}>{pregunta.text}</p>
                            <div className={divClass}>
                                {pregunta.opcion.map((opc) => (
                                    <button className={opc.opcione.length >= 4? 'contect-quiz' : 'contect-quiz2'}
                                        key={opc.id}
                                        onClick={() => handleRespuesta(pregunta.id, opc.opcione)}
                                    >
                                        <p className={opc.opcione.length >= 4 ? 'contect-quiz' : 'contect-quiz2'}>
                                            {opc.opcione}
                                        </p>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </section>
                )
            )}
            {preguntaActual >= encuesta.questions.length && (
                <section className="wimdons-quiz-img">
                    {!finished
                        ?<div className="wimdons-quiz-questions">
                            <p className="title-result">
                                <span>Sube una foto de tu puesto o lugar de trabajo:</span>
                                <br></br>Una foto lateral donde te veas tú sentado en tu espacio de trabajo.
                            </p>
                            <section className="wimdons-quiz-questions-gallery">
                                <img className="wimdons-quiz-questions-img" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagen%20de%20WhatsApp%202024-05-21%20a%20las%2011.55.11_4bff2dc9.jpg?alt=media&token=e2286bb6-80bb-461d-a811-9002b9f66eba" alt="SHOFI" />
                            </section>
                            {previewUrls.length !==4 &&
                                <div className='input-load'>
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <IoCloudUploadOutline /> Cargar imágenes
                                    </label>
                                    <input id="file-upload" type="file" accept=".jpg, .png" onChange={handleFileChange} multiple />
                                </div>
                            }
                            {previewUrls&&
                                <div className="container-input-img-previ">
                                    {previewUrls.map((url, index) => (
                                        <div key={index} className="input-img">
                                            <img src={url} alt={`Previsualización ${index}`}/>
                                                <button className="delete-img" onClick={() => handleRemoveFile(index)}>
                                                    <BiX/>
                                                </button>
                                        </div>
                                    ))}
                                </div>
                            }
                            {previewUrls.length !==0 && <button className="button-result" onClick={handleSubmit}>Enviar<GrFormNextLink /></button>}
                        </div>
                        :<div className="wimdons-quiz-questions">
                            <p className="title-result">Esta es la calificación con respecto al <br></br>cumplimiento de la <span>situación ergonómica de tu puesto de trabajo.</span></p>
                            <div className='dates-dashboard'>
                                <div className="card-quiz-contador">
                                    <Stack direction="row">
                                        <PieChart
                                            colors={['#1E67E2', '#85D0D5']} // Colores de tu elección
                                            series={[{
                                                paddingAngle: 1,
                                                innerRadius: 60,
                                                outerRadius: 80,
                                                cornerRadius: 19,
                                                data: datosGrafica,
                                            }]}
                                            margin={{ right: 5 }}
                                            width={200}
                                            height={200}
                                            legend={{ hidden: true }}
                                        />
                                    </Stack>
                                    <div>
                                        <h1>Puntuación:</h1>
                                        <h2>{porcentajeRedondeado}Pts</h2>
                                        <p>Esta es la calificación va con un maximo de 100pts. <br></br> </p>
                                    </div>
                                </div>
                            </div>
                            <Link className="button-result" to={"/"}>Ir a web <GrFormNextLink /></Link>
                        </div>
                    }
                </section>
            )}
        </>
    );
};
