import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../../../context/AuthContext';
import { InfoLoading } from '../../../../../Loanding';
import { ListProductAsignate } from '../../../../UI/organisms/ListProductAsignate/ListProductAsignate';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../../../firebase/config';

export const ListAsingProduct = () => {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const {authToken } = useContext(AuthContext);
    const [products, setProducts] = useState([]);
    const [ info, setInfo] = useState([]);
    const [businessId, setBusinessId] = useState("6584d98c9b7c9");

    useEffect(() => {
        setLoading(true);
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`https://api.velocity-x.co/products?business_id=${businessId}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setProducts(response.data);
                setLoading(false);
            } catch (error) {
                setError(error)
            }
        };
        fetchProducts();
    }, [authToken, businessId]);

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'company');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleSelectChange = (event) => {
        setBusinessId(event.target.value);
    };

    const numberOfPages = Math.ceil(products.length / itemsPerPage);
    const displayItems = products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!authToken) return <p>No recepciono authToken</p>;
    return (

        <>
            {products&&
                <div className="product-admin-produc-bass">
                    <ListProductAsignate
                        products = {products}
                        handleChange = {handleChange}
                        displayItems = {displayItems}
                        numberOfPages = {numberOfPages}
                        currentPage = {currentPage}
                        handleSelectChange = {handleSelectChange}
                        info = {info}
                        businessId = {businessId}
                    />
                </div>
            }
        </>
    );
};