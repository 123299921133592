import "./Home.scss";
import { useState, useEffect } from 'react';
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
// Secciones componentes de home
import { Section0 } from "../../UI/organisms/Section_0/Section_0";
import { Section1 } from "../../UI/organisms/Section_1/Section_1";
import { Section2 } from "../../UI/organisms/Section_2/Section_2";
import { Section3 } from "../../UI/organisms/Section_3/Section_3";
import { Section4 } from "../../UI/organisms/Section_4/Section_4";
import { useParams , useLocation } from "react-router-dom";
import { Section5 } from "../../UI/organisms/Section_5/Section_5";
import { Section6 } from "../../UI/organisms/Section_6/Section_6";
import { useLoginContext } from '../../../context/LoginContext';

// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 2000 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const Home = ({ setIsOpenR, setIsLogin, setIdInfo, setIsWindow}) => {
    const{ setLoading } = useLoginContext ();
    const [error, setError] = useState(null);
    const { assignment } = useParams();
    const location = useLocation();
    // Abre el Login si 'assignment' está presente
    useEffect(() => {
        if (assignment) {
            setIsLogin(true);
        }
    }, [assignment, setIsLogin]);

    // Abre o cierra el modal basado en la URL
    useEffect(() => {
        if (location.pathname === '/subscripcion') {
            setIsOpenR(true);
        } else if (location.pathname === '/subscripcion/true'){
            setIsOpenR(true);
        } else {
            setIsOpenR(false);
        }
    }, [location.pathname, setIsOpenR]);
    
    const settings1 = {
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    
    const settings2 = {
        dots: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
    };


    if (error) return <p>Error: {error.message}</p>;
    return (
        <div className="main-home">
            <div className="ellipse-div" />
            <FadeInSection>
                <Section0 setIsOpenR={setIsOpenR} setLoading={setLoading} setError={setError}/>
            </FadeInSection>
            <FadeInSection>
                <Section1 setLoading={setLoading} setError={setError} settings={settings1}/>
            </FadeInSection>
            <FadeInSection>
                <Section2 settings={settings2} setIsOpenR={setIsOpenR} setLoading={setLoading} setError={setError}/>
            </FadeInSection>
            <FadeInSection>
                <Section6 setIsOpenR={setIsOpenR} setLoading={setLoading} setError={setError}/>
            </FadeInSection>
            <FadeInSection>
                <Section3 setLoading={setLoading} setError={setError} setIdInfo={setIdInfo} setIsWindow ={setIsWindow }/>
            </FadeInSection>
            <FadeInSection>
                <Section4 setLoading={setLoading} setError={setError} setIsOpenR={setIsOpenR}/>
            </FadeInSection>
            <FadeInSection>
                <Section5 setLoading={setLoading} setError={setError}/>
            </FadeInSection>
        </div>
    );
}
