import "./CreatorUsers.scss";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, Timestamp, updateDoc, where } from "firebase/firestore";
// import { useContext, useEffect, useState } from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { BiChevronLeft } from "react-icons/bi";
import colombia from '../../../../firebase/colombia';
import { toast } from "react-toastify";
import axios from "axios";
// import { AuthContext } from "../../../../context/AuthContext";
import { LuSave } from "react-icons/lu";
import { FiAlertCircle } from "react-icons/fi";

export const OrderConfirmation = () => {
    // const { authToken } = useContext(AuthContext);
    const { id, ido } = useParams();
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const [ orden, setOrden ] = useState([]);
    const [ info, setInfo ] = useState([]);
    const [ products, setProducts]  = useState([]); 
    const [ productsDates, setProductsDates ] = useState([]);
    // Inicializar formData con valores por defecto
    const [ formData, setFormData ] = useState({
        nombre: '',
        email: '',
        telefono: '',
        apellido: '',
        pais: 'Colombia',
        departamento: '',
        ciudad: '',
        direccion_1: '',
        direccion_2: '',
        img: '',
        cc:'',
        select_kit:'',
        name_kit:'',
        code_orden:'',
        idc:'',
        business_id:'',
    });

    useEffect(() => {
        let isMounted = true;
        const fetchKits = async () => {
            try {
                const docRef = doc(db, 'users', id);
                const docData = await getDoc(docRef);
                if (docData.exists() && isMounted) {
                    const userData = docData.data();
                    setFormData({ ...formData, ...userData });
                    setSelectedDepartment(userData.departamento);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchKits();
        return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        let isMounted = true;
        const fetchKits = async () => {
            try {
                const docRef = doc(db, 'orden', ido);
                const docData = await getDoc(docRef);
                if (docData.exists() && isMounted) {
                    const userData = docData.data();
                    setOrden({...userData });
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchKits();
        return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Llamado de informacion de los productos
    useEffect(() => {
        let isMounted = true;
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "produc"));
                if (isMounted) {
                    const allProducts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setProductsDates(allProducts);
                }
            } catch (error) {
                console.log("Error fetching products: ", error);
            }
        };
        fetchProducts();
        return () => { isMounted = false; };
    }, []);

    // Llamados de opciones de kits
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const infoCollectionRef = collection(db, 'produc_kits');
                const q = query(infoCollectionRef, where('idc', '==', formData.idc));
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
                const docRef = doc(db, 'produc_kits', formData.select_kit);
                const docData = await getDoc(docRef);
                if (docData.exists() && isMounted) {
                    const userData = docData.data();
                    setProducts(userData.kits);
                }
            } catch (err) {
                setError(error);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    // Mueve el historial hacia atrás en una entrada
    const goBack = () => {
        navigate(-1); 
    };
    
    // Control de cambio en el formilario de datos
    const handleChange = (e) => {
        const { name, value } = e.target;
        // Manejar la selección de una empresa
        if (name === 'select_kit') {
            const selectedKit= info.find(kit => kit.id === value);
            if (selectedKit) {
                setProducts(
                    selectedKit.kits
                );
            }
        }
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));      
        // Manejo de otros cambios en el formulario
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        if (name === 'departamento') {
            setSelectedDepartment(value);
        }
    };
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({
        email: null,
        telefono: null,
    });
    // Carga de ciudades
    useEffect(() => {
        if (selectedDepartment) {
            setCities(colombia[selectedDepartment] || []);
        }
    }, [selectedDepartment]);
    
    // Datos de productos de orden
    let ordenProductsVisual = products.map(productId => {
        const product = productsDates.find(p => p.id === productId);
        return {
            name: product.name, 
            picture_url: product.img,
            price: product.price,
            quantity:1,
            sku: product.SKU+"-"+formData.code_orden,
        };
    });
    // Datos para crear productos en Producto Asignados
    let datesProducts = products.map(productId => {
        const product = productsDates.find(p => p.id === productId);
        return {
            business_id:formData.business_id,
            price:parseFloat(product.price),
            category:product.categoryId,
            date_create: new Date(),
            idc: formData.idc,
            idp: product.id,
            img: product.img,
            name: product.name,
            serial: product.SKU,
            sku: product.SKU+"-"+formData.code_orden,
            tipe:"T-001",
            kit:formData.select_kit,
            cost:"10", // <=== Cargar costos en el futuro
            // Estos campos se actualizarán después
            idu: "",
            ido: "",
            ovelocity: "" ,
            counter:1,
            orden_id:"",
        };
    });
    // // Datos de creador de productos en Velocity
    // let createProducts = products.map(productId => {
    //     const product = productsDates.find(p => p.id === productId);
    //     return {
    //         name: product.name, 
    //         sku: product.SKU+"-"+formData.code_orden,
    //         external_id: product.id,
    //         picture_url: product.img,
    //         price:parseFloat(product.price),
    //         tax:0,
    //         cost:50000,
    //         weight: 0,
    //         large: 0,
    //         width: 0,
    //         height: 0,
    //         warehouses:[138,110],
    //         business_id: formData.business_id,
    //         bundle:[],
    //     };
    // });
    // Datos de productos de orden
    let ordenProducts = products.map(productId => {
        const product = productsDates.find(p => p.id === productId);
        return {
            price: parseFloat(product.price),
            sku: product.SKU+"-"+formData.code_orden,
            quantity:1,
            tax:0,
            discount:0,
            is_custom_kit: false,
        };
    });
    let totalOrden = ordenProducts.reduce((sum, product) => sum + product.price, 0);

    // Boton de guardar
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Verificar si el formato email es correcto
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            toast.error('Por favor, introduce un email válido.');
            return;
        }
    
        // Verificar si la compañía está bien
        if (!formData.idc) {
            toast.error('Por favor, selecciona una empresa.');
            return;
        }
    
        // Verificar si el formato teléfono es correcto
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.telefono)) {
            toast.error('Por favor, introduce un número de teléfono válido.');
            return;
        }
    
        // Verificar si el formato cc es correcto
        const ccRegex = /^[0-9]+$/;
        if (!ccRegex.test(formData.cc)) {
            toast.error('Por favor, introduce un número de documento válido.');
            return;
        }
    
        // Concatenar dirección para geocodificación
        const fullAddress = `${formData.direccion_1}, ${formData.ciudad}, ${formData.departamento}, ${formData.pais}`;
        let latTemp, lngTemp; // Variables temporales para lat y lng
        try {
            setLoading(true);
            const encodedAddress = encodeURIComponent(fullAddress);
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg`);
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                latTemp = lat;
                lngTemp = lng;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                setLoading(false);
                return;
            }
        } catch (error) {
            toast.error("Error al obtener las coordenadas: " + error.message);
            return;
        }
    
        // Actualizar formData directamente con latitud y longitud
        if (latTemp && lngTemp) {
            formData.latitud = latTemp;
            formData.longitud = lngTemp;
        }
        // Recargando sistema
        setLoading(true);
        try {
            // Obtener la fecha de entrega de pedido
            // const firebaseTimestamp = Timestamp.now();
            // const currentDate = firebaseTimestamp.toDate();
            // const newDate = new Date(currentDate.getTime() + (5 * 24 * 60 * 60 * 1000));
            // const dateISO = newDate.toISOString();
    
            // Actualizar datos de usuario en registro firebase
            const upadeData = doc(db, 'users', id);
            await updateDoc(upadeData, {
                nombre: formData.nombre,
                email: formData.email,
                telefono: formData.telefono,
                apellido: formData.apellido,
                pais: 'Colombia',
                departamento: formData.departamento,
                ciudad: formData.ciudad,
                direccion_1: formData.direccion_1,
                direccion_2: formData.direccion_2,
                select_kit: formData.select_kit,
                name_kit: formData.name_kit,
                latitud: formData.latitud,
                longitud: formData.longitud,
            });
    
            // // Información e customer para la SAAS Velocity
            // const customer = {
            //     document_type_id: 1,
            //     full_name: formData.nombre + " " + formData.apellido,
            //     mobile_phone_number: formData.telefono,
            //     email: formData.email,
            //     dni: formData.cc,
            // };
    
            // // Información e Shipping para la SAAS Velocity
            // const shipping = {
            //     address: formData.direccion_1,
            //     address_line: formData.direccion_2,
            //     dane_code: '1010',
            //     city: formData.ciudad,
            //     state: formData.departamento,
            //     country: 'CO',
            // };
    
            // Realizar consulta a stock_company
            const infoCollectionRef = collection(db, 'stock_company');
            const q = query(infoCollectionRef, where('idc', '==', formData.idc), where('kit', '==', formData.select_kit));
            const querySnapshot = await getDocs(q);
            const fetchedInfo = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            // Agrupar y ordenar los datos
            const groups = {};
            fetchedInfo.forEach(item => {
                if (!groups[item.ido]) {
                    groups[item.ido] = [];
                }
                groups[item.ido].push(item);
            });
    
            // Ordenar cada grupo por 'date_delete', de más antiguo a más nuevo
            Object.keys(groups).forEach(ido => {
                groups[ido].sort((a, b) => (a.date_delete.seconds - b.date_delete.seconds));
            });
    
            // Encuentra el grupo con el elemento más antiguo de todos
            let oldestOverall = [];
            let oldestDate = Infinity;
            Object.values(groups).forEach(group => {
                if (group[0].date_delete.seconds < oldestDate) {
                    oldestDate = group[0].date_delete.seconds;
                    oldestOverall = group;
                }
            });
    
            const groupedByIdo = oldestOverall;
            const documentIds = oldestOverall.map(item => item.id);
    
            // Variables de balance de servicios
            if (groupedByIdo.length !== 0) {
                // Reasignación de productos con stock en inventario de la compañía
                await addDoc(collection(db, 'balance_service'), {
                    balance: -1,
                    date: Timestamp.now(),
                    idc: formData.idc,
                    idu: id,
                    name_user: `${formData.nombre} ${formData.apellido}`,
                    serial: id,
                    status_balance: false,
                });
    
                // Actualizar datos de los nuevos productos
                const updatedItems = groupedByIdo.map(item => {
                    const { id, ...otherProps } = item; // Desestructuramos 'id' y almacenamos el resto de propiedades en 'otherProps'
                    return {
                        ...otherProps, // Usamos el operador de propagación para copiar las propiedades que excluyen 'id'
                        date_create: Timestamp.now(),
                        date_delete: '',
                        date_delivery: '',
                        status: true,
                    };
                });
    
                // Carga de datos de Orden de productos de montaje Velocity
                // let ordenProductsFull = updatedItems.map(product => {
                //     return {
                //         price: parseFloat(product.price),
                //         sku: product.sku,
                //         quantity: 1,
                //         tax: 0,
                //         discount: 0,
                //         is_custom_kit: false,
                //     };
                // });

                // Carga de datos de Orden de productos de montaje
                let ordenProductsOrden = updatedItems.map(product => {
                    return {
                        sku: product.sku,
                        quantity: 1,
                        name: product.name,
                        picture_url: product.img,
                        price: product.price,
                    };
                });
                // Datos de montaje de la orden en SAAS Velocity
                // const orderDatafull = {
                //     country_id: 1,
                //     payment_method: 1,
                //     integration_type_id: 5,
                //     cod_total: 0,
                //     total: parseFloat(totalOrden),
                //     order_type_id: 4,
                //     external_order_id: ido,
                //     order_number: ido,
                //     warehouse_id: 138,
                //     total_shipment: 0,
                //     business_id: formData.business_id,
                //     delivery_date: dateISO,
                //     customer: customer,
                //     shipping_information:shipping,
                //     products: ordenProductsFull,
                // };
                try {
                    // const response = await axios.post('https://api.velocity-x.co/orders', orderDatafull, {
                    //     headers: {
                    //         Authorization: `Bearer ${authToken}`
                    //     }
                    // });
                    // const orderId = response.data.id;
                    // if (orderId) {

                        // Agregar el ID del usuario al nuevo grupo
                        const upadeData = doc(db, 'orden', ido);
                        await updateDoc(upadeData, {
                            // order_velocity: orderId,
                            order_velocity: "",
                            deleveryData: {
                                name: formData.nombre,
                                lastname: formData.apellido,
                                country: formData.pais,
                                department: formData.departamento,
                                city: formData.ciudad,
                                address: formData.direccion_1,
                                lastaddress: formData.direccion_2,
                                lat: formData.latitud,
                                log: formData.longitud,
                                phone: formData.telefono,
                                email: formData.email,
                                cc: formData.cc,
                            },
                            products: products,
                            idk: formData.select_kit,
                            order_product: ordenProductsOrden,
                            order_status_id: 1,
                            total: totalOrden,
                            re_asign: true,
                            order_acceptance: true,
                        });
    
                        // Crear productos en la colección `products-assignment` con los IDs actualizados
                        for (const product of updatedItems) {
                            await addDoc(collection(db, 'products-assignment'), {
                                ...product,
                                ido: ido,
                                idu: id,
                                ovelocity:"",
                                // ovelocity: orderId,
                                orden_id:orden.order_siigo_id,
                            });
                        }
                        // Eliminación de los documentos identificados por documentIds
                        for (const id of documentIds) {
                            await deleteDoc(doc(db, 'stock_company', id));
                        }
                    // }
                } catch (error) {
                    if (error.response) {
                        console.error('Error en la solicitud:', error.response.data); // Muestra detalles del error


                        console.error('Status:', error.response.status);
                        console.error('Headers:', error.response.headers);
                        console.error('Data:', JSON.stringify(error.response.data, null, 2));
                    } else {
                        console.error('Error:', error.message);
                    }
                    console.error("Error en la solicitud: " + JSON.stringify(error.response.data));
                }
                
            } else {
                // Creador de asignación de productos sin stock en inventario de la compañía
                // let allProductsCreated = true;
                // for (const productData of createProducts) {
                //     try {
                //         const response = await axios.post('https://api.velocity-x.co/products', productData, {
                //             headers: {
                //                 Authorization: `Bearer ${authToken}`
                //             }
                //         });
                //         console.log('Producto creado:', response.data);
                //     } catch (error) {
                //         console.log("Error crear producto:", error.response);
                //         console.log("Data producto:", productData);
                //         allProductsCreated = false;
                //         break;
                //     }
                // }
    
                // if (!allProductsCreated) {
                //     toast.error("Error al crear uno o más productos");
                //     setLoading(false);
                //     return; // Detener la ejecución si no se crearon todos los productos
                // }
                await addDoc(collection(db, 'balance_service'), {
                    balance: +1,
                    date: Timestamp.now(),
                    idc: formData.idc,
                    idu: id,
                    name_user: `${formData.nombre} ${formData.apellido}`,
                    serial: id,
                    status_balance: true,
                });
                // const orderData = {
                //     country_id: 1,
                //     payment_method: 1,
                //     integration_type_id: 5,
                //     cod_total: 0,
                //     total: parseFloat(totalOrden),
                //     order_type_id: 4,
                //     external_order_id: ido,
                //     order_number: ido,
                //     warehouse_id: 138,
                //     total_shipment: 0,
                //     business_id: formData.business_id,
                //     delivery_date: dateISO,
                //     customer: customer,
                //     shipping_information: shipping,
                //     products: ordenProducts,
                // };
                try {
                    // const response = await axios.post('https://api.velocity-x.co/orders', orderData, {
                    //     headers: {
                    //         Authorization: `Bearer ${authToken}`
                    //     }
                    // });
                    // const orderId = response.data.id;
                    // if (orderId) {
                        const upadeData = doc(db, 'orden', ido);
                        await updateDoc(upadeData, {
                            // order_velocity: orderId,
                            order_velocity: "",
                            deleveryData: {
                                name: formData.nombre,
                                lastname: formData.apellido,
                                country: formData.pais,
                                department: formData.departamento,
                                city: formData.ciudad,
                                address: formData.direccion_1,
                                lastaddress: formData.direccion_2,
                                lat: formData.latitud,
                                log: formData.longitud,
                                phone: formData.telefono,
                                email: formData.email,
                                cc: formData.cc,
                            },
                            products: products,
                            idk: formData.select_kit,
                            order_product: ordenProductsVisual,
                            order_status_id: 1,
                            total: totalOrden,
                            re_asign: false,
                            order_acceptance: true,
                        });
    
                        for (const product of datesProducts) {
                            await addDoc(collection(db, 'products-assignment'), {
                                ...product,
                                status: true,
                                ido: ido,
                                idu: id,
                                // ovelocity: orderId,
                                ovelocity: "",
                                orden_id:orden.order_siigo_id,
                            });
                        }
                    // }
                } catch (error) {
                    console.log("Error en la solicitud:", error.response);
                }
            }
    
            // Volver al listado
            navigate('/order');
        } catch (error) {
            toast.error("Error creando la orden: ", error);
        } finally {
            setLoading(false);
        }
    };


    // Cerrar cuenta de productos
    const cancelSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const userDocRefs = doc(db, 'users', id);
        const date_delete = new Date();
        const updatedData = {
            fechaDelete: date_delete,
            status: false,
            idg: "",
            latitud: "",
            longitud: "",
        };
        try {
            // Actualizar el documento del usuario
            await updateDoc(userDocRefs, updatedData);
            // Verificar y borrar usuario
            // const userDocRef = doc(db, 'users', ido);
            // await deleteDoc(userDocRef);
            // Verificar y borrar órdene
            const ordenDocRef = doc(db, 'orden', ido);
            await deleteDoc(ordenDocRef);
            // Volver al listado
            navigate('/order');
            toast.success('Usuario borrado con éxito');
        } catch (error) {
            console.error("Error al actualizar el usuario: ", error);
            toast.error('Error al editar el usuario: ' + error.message);
        } finally {
            setLoading(false); // Desactivar el indicador de carga
        }
    };

    // Fecha de creacion de users
    let fechaFormateada = "";
    if(orden.creation) {
        try {
            const fecha = orden.creation.toDate(); 
            fechaFormateada = fecha.toLocaleDateString('es-ES'); 
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }
    
    const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..';
    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <div className="windons-equipo-admin">
            <div className="encabezado-list">
                <button className='button-new' onClick={goBack}>
                    <BiChevronLeft /> Volver
                </button>
                <h2 className="title">Editar pedido</h2>
            </div>
            <div className='scroll-equip-admin'>
                <form onSubmit={handleSubmit} className="form-contact-e">
                    <section className='column-img'>
                        <div className='section-img'>
                            <div className='input-img'>
                                <img
                                    className='user'
                                    alt='user'
                                    src={formData.img || defaultImage}
                                />
                            </div>
                        </div>
                    </section>
                    <section className='section-users'>
                        <div className='column-form2'>
                            <div className='input-date'>
                                <label>Nombre:</label>
                                <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} required  />
                            </div>
                            <div className='input-date'>
                                <label>Apellido:</label>
                                <input type="text" name="apellido" value={formData.apellido} onChange={handleChange} required   />
                            </div>
                            <div className='input-date'>
                                <label>Email:</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                {errors.email && <p className='error-message'>{errors.email}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Teléfono:</label>
                                <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} />
                                {errors.telefono && <p className='error-message'>{errors.telefono}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Cedula CC / PEP:</label>
                                <input type="tel" name="cc" value={formData.cc} onChange={handleChange}/>
                                {errors.cc && <p className='error-message'>{errors.cc}</p>}
                            </div>

                            <div className='input-date'>
                                <label>País:</label>
                                <select name="pais" value={formData.pais} onChange={handleChange}>
                                    <option value="Colombia">Colombia</option>
                                </select>
                            </div>
                            <div className='input-date'>
                                {Object.keys(colombia).includes(selectedDepartment) 
                                ?(<label>{`Departamento:`}</label>)
                                :(<label value={selectedDepartment}  className="error-input-label">{`**Error en Departamento**`}</label>)}
                                <select name="departamento" value={selectedDepartment} onChange={handleChange}>
                                    <option value="" disabled>Seleccione</option>
                                    {Object.keys(colombia).map((department) => (
                                        <option key={department} value={department}>{department}</option>
                                    ))}
                                    {!Object.keys(colombia).includes(selectedDepartment) && (
                                        <option value={selectedDepartment}  className="error-input">{`${selectedDepartment}`}</option>
                                    )}
                                </select>
                            </div>
                            <div className='input-date'>
                                {cities.includes(formData.ciudad) 
                                ?(<label>{`Ciudad:`}</label>)
                                :(<label value={selectedDepartment}  className="error-input-label">{`**Error en Ciudad**`}</label>)}
                                <select name="ciudad" value={formData.ciudad} onChange={handleChange}>
                                    <option value="" disabled>Seleccione</option>
                                    {cities.map((city) => (
                                        <option key={city} value={city}>{city}</option>
                                    ))}
                                    {!cities.includes(formData.ciudad) && (
                                        <option value={formData.ciudad} className="error-input">{`${formData.ciudad}`}</option>
                                    )}
                                </select>
                            </div>

                            <div className='input-date'>
                                <label>Dirección:</label>
                                <input type="text" name="direccion_1" value={formData.direccion_1} onChange={handleChange} />
                            </div>
                            <div className='input-date'>
                                <label>Dirección detallada:</label>
                                <input type="text" name="direccion_2" value={formData.direccion_2} onChange={handleChange}/>
                            </div>
                            <div className='input-date'>
                                <label>Kit asignado:</label>
                                {info&&<select name="select_kit" value={formData.select_kit} onChange={handleChange} required>
                                    <option value="" disabled hidden>Seleccionar kits</option>
                                    {info.map((kit) => (
                                        <option key={kit.id} value={kit.id}>{kit.name_kits}</option>
                                    ))}
                                </select>}
                            </div>
                        </div>
                        <div className='column-form2'>
                            <button className='button-delete' onClick={cancelSubmit}>
                                <FiAlertCircle />Cancelar
                            </button>
                            <div></div>
                            <div></div>
                            <button className='button-new' type="submit" ><LuSave />
                                Confirmar
                            </button>
                        </div>
                    </section>
                </form>
                <div className='container-set-users'>
                    <div className="iten-orden-user">
                        <section className="detail-orden-date">
                            <article className="orden-date">
                                <h4 className="subtitle3" >ID orden: {id}</h4>
                                {orden.order_siigo_id&&<p>Orden ID:{orden.order_siigo_id}</p>}
                            </article>
                            <article className="orden-description">
                                <p className="date-orden">Fecha creación: {fechaFormateada}</p>
                            </article>
                        </section>
                        <section className="detail-orden-products">
                            {ordenProductsVisual &&<div className="container-list-product">
                                <h2 className="subtitle3">Lista De Productos:</h2>
                                <div className="container-iten-produc">
                                    <p>Img</p>
                                    <p className="iten-produc-name">Nombre</p>
                                    <p className="iten-produc-sku">SKU</p>
                                </div>
                                {ordenProductsVisual.map((item, index) => (
                                    <div key={index + item.product_id} className="container-iten-produc">
                                        <img alt={item.name} src={item.picture_url} />
                                        <p className="iten-produc-name">{item.name}</p>
                                        <p className="iten-produc-sku">{item.sku}</p>
                                    </div>
                                ))}
                            </div>}
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
};