import { BiMessageAltError ,  BiPackage, BiHome , BiRocket , BiLoader , BiMessageAltX, BiTrip} from "react-icons/bi";

export const StatusRecollectios= ({status_delivery}) => {
    // Impresion de status de envio o la orden
    let status;
    if ( status_delivery === 0)  {
        status = <p className="verification-o"><BiMessageAltError /> Verificación</p>;
    } else if ( status_delivery === 1 ) {
        status = <p className="preparation-o"><BiTrip />Confirmado</p>;
    } else if ( status_delivery === 2 ) {
        status = <p className="route-o"><BiRocket /> Asignado</p>;
    } else if ( status_delivery === 3 ) {
        status = <p className="route-o"><BiRocket /> En camino</p>;
    } else if ( status_delivery === 4 ) {
        status = <p className="delivered-o"><BiHome /> Recogido</p>;
    } else if ( status_delivery === 5 ) {
        status = <p className="delivered-o"><BiPackage /> Inventariado</p>;
    } else if ( status_delivery === 6 ) {
        status = <p className="cancelled-o"><BiMessageAltX /> Cancelado</p>;
    } else if ( status_delivery === "" ) {
        status = <p className="loanding-o">... Cargando</p>;
    } else {
        status = <p className="unassigned-o"><BiLoader /> Sin asignar</p>;
    }
    return (
        <div className="status-orden">
            {status}
        </div>
    );
};