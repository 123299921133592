/* eslint-disable react/jsx-pascal-case */
import "./Sigo.scss";
import { useState } from "react";
import { FormSigo } from "../../UI/molecules/Form_Sigo/Form_Sigo";
import { Section2 } from "../../UI/organisms/Section_2/Section_2";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { Section3 } from "../../UI/organisms/Section_3/Section_3";
import { useLocation } from "react-router-dom";
import { FormSigoPost } from "../../UI/molecules/Form_Sigo/Form_SigoPost";
import { Forms_Diagnostic } from "../../UI/molecules/Form_Demo/Forms_Diagnostic";
import { useLoginContext } from "../../../context/LoginContext";

// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 1000 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};


export const Sigo = ({setIsOpenR, setIdInfo, setIsWindow}) => {
    const{ setLoading } = useLoginContext ();
    const [error, setError] = useState(null);
    const location = useLocation(); 

    const settings2 = {
        dots: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
    };


    if (error) return <p>Error: {error.message}</p>;
    return ( 
        <div className="main-home">
            <div className="ellipse-div"/>
            <FadeInSection>
                {location.pathname === "/aliados/siigoPos" ? (
                    <FormSigoPost />
                ) : location.pathname === "/diagnostico/test" ? (
                    <Forms_Diagnostic />
                ) : (
                    <FormSigo />
                )}
            </FadeInSection>
            <>
                {location.pathname === "/aliados/siigoPos" || location.pathname === "/diagnostico/test" 
                    ? (
                        <div className="space-siigo"></div>
                    ) : (
                        <>
                            <FadeInSection>
                                <Section2 
                                    settings={settings2} 
                                    setIsOpenR={setIsOpenR} 
                                    setLoading={setLoading} 
                                    setError={setError}
                                />
                            </FadeInSection>
                            <FadeInSection>
                                <Section3 
                                    setLoading={setLoading} 
                                    setError={setError} 
                                    setIdInfo={setIdInfo} 
                                    setIsWindow={setIsWindow}
                                />
                            </FadeInSection>
                        </>
                    )
                }
            </>
        </div>
    );
}
