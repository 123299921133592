import "./Equipos.scss";
import { useEffect, useMemo, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { ListUsers } from "../../../UI/organisms/ListUsers/ListUsers";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "lodash";

const statusList = [
    {
        id: "S000",
        name: "Verificación",
        status: [0],
    },
    {
        id: "S001",
        name: "Sin Asignar",
        status: [99],
    },
    {
        id: "S003",
        name: "Preparación",
        status: [1, 2, 15, 13, 14, 4],
    },
    {
        id: "S002",
        name: "En camino",
        status: [12, 11, 5],
    },
    {
        id: "S004",
        name: "Entregado",
        status: [6],
    },
    {
        id: "S005",
        name: "Cancelado",
        status: [7, 8],
    },
    {
        id: "S999",
        name:"Todos...",
        status: [1, 2, 15, 13, 14, 4, 20,5, 12, 11,0,6,7,8,20, 99],
    },
];

export const Equipo = ({ idc, compañia, id, rol, typecompany, setIsCreatorUsers }) => {
    const itemsPerPage = 20;
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState('nombre');
    const [sortDirection, setSortDirection] = useState('asc');
    const [filterRole, setFilterRole] = useState(''); 
    const [searchTerm, setSearchTerm] = useState('');
    const [filterOrderStatus, setFilterOrderStatus] = useState(''); 
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [itemsUser, setItemsUser]= useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        setCurrentPage(1); // Resetear a la primera página al cambiar filtros
    
        const fetchUsers = async () => {
            try {
                const usersCollectionRef = collection(db, 'users');
                const querySnapshot = await getDocs(usersCollectionRef);
                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
    
                let filteredUsers;
                if (rol === "SA-001") {
                    filteredUsers = users;
                } else {
                    filteredUsers = idc ? users.filter(user => user.idc === idc) : users;
                }
                filteredUsers = id ? filteredUsers.filter(user => user.id !== id) : filteredUsers;
    
                setItemsUser(filteredUsers);
    
                if (startDate) {
                    const start = new Date(startDate);
                    filteredUsers = filteredUsers.filter(user => user.fechaCreacion?.toDate() >= start);
                }
                if (endDate) {
                    const end = new Date(endDate);
                    filteredUsers = filteredUsers.filter(user => user.fechaCreacion?.toDate() <= end);
                }
    
                if (filterOrderStatus) {
                    const selectedStatus = statusList.find(status => status.id === filterOrderStatus);
                    if (selectedStatus) {
                        filteredUsers = filteredUsers.filter(user => selectedStatus.status.includes(user.order_status_id));
                    }
                }
    
                const sortedUsers = filteredUsers.sort((a, b) => {
                    let comparison = 0;
                    if (sortType === 'nombre') {
                        comparison = a.nombre.localeCompare(b.nombre);
                    } else if (sortType === 'fechaCreacion') {
                        const dateA = a.fechaCreacion ? a.fechaCreacion.toDate() : new Date(0);
                        const dateB = b.fechaCreacion ? b.fechaCreacion.toDate() : new Date(0);
                        comparison = dateB - dateA;
                    }
                    return sortDirection === 'desc' ? comparison : -comparison;
                });
    
                const finalUsersList = sortedUsers.filter(user => 
                    (!filterRole || user.rol === filterRole)
                );
    
                if (isMounted) setInfo(finalUsersList);
                setLoading(false);
            } catch (error) {
                if (isMounted) setError(error);
                toast.error("Error fetching users: " + error.message);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchUsers();
        return () => { isMounted = false; };
    }, [idc, id, sortType, sortDirection, filterRole, filterOrderStatus, rol, startDate, endDate]);
    
    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        handler();
        return () => {
            handler.cancel();
        };
    }, [searchTerm]);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const usersItems = useMemo(() => 
        info.filter(user => 
            user.nombre.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.ciudad.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.apellido.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.cc.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        ), [debouncedSearchTerm, info]
    );

    const numberOfPages = Math.ceil(usersItems.length / itemsPerPage);
    const displayItems = usersItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const toggleClose = () => {
        setIsCreatorUsers(true);
    };

    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            {itemsUser&&(itemsUser.length!==0)
                ?<div className="equipo-admin">
                    <ListUsers
                        idc = {idc}
                        compañia={compañia}
                        id = {id}
                        info = {info}
                        rol = {rol}
                        setSortType = {setSortType}
                        setSortDirection = {setSortDirection}
                        setFilterRole = {setFilterRole}
                        filterRole = {filterRole}
                        handleChange = {handleChange}
                        sortDirection = {sortDirection}
                        displayItems = {displayItems}
                        numberOfPages = {numberOfPages}
                        currentPage = {currentPage}
                        setSearchTerm = {setSearchTerm}
                        searchTerm = {searchTerm}
                        typecompany={typecompany} 
                        setIsCreatorUsers={setIsCreatorUsers} 
                        setFilterOrderStatus={setFilterOrderStatus}
                        startDate = {startDate}
                        setStartDate = {setStartDate}
                        endDate= {endDate}
                        setEndDate= {setEndDate}
                        filterOrderStatus={filterOrderStatus}
                    />
                    
                </div>
                :<div className="windons">
                    <div className="encabezado-list">
                        <h2 className="title">Equipo: {compañia}</h2>
                    </div>
                    <div className="windons-quiz-init">
                        <div className="section-quiz-init">
                            <div className="quiz-init-img">
                                <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.."/>
                                <h2 className="title-shofi-loanding">Comencemos a crear<br></br>tu equipo de trabajo.</h2>
                            </div>
                            <div className="quiz-init-contect">
                                <h3 className="contect-shofi-loanding">Registra a tu equipo de trabajo<br/>y comienza a trabajar y disponer de tus recursos</h3>
                                {typecompany=== "C000001"
                                    ?<Link className='button-new' to="/usuarios/creador">
                                        <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                        Crear Usuario
                                    </Link>
                                    :<button className='button-new' onClick={toggleClose}>
                                        <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                        Crear Usuario
                                    </button>
                                }
                                <h3 className="contect-shofi-loanding  flex-section">Registra a tus colaboradores individualmente o<br />Cárgalos de forma masiva usando esta Plantilla CSV:</h3>
                                <Link className='button-asing' to="/usuarios/csv">
                                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Frender-3d-manos-aplaudiendo-efecto-sonido-PhotoRoom.png-PhotoRoom%20(1).png?alt=media&token=bd7dbc6d-3932-4a38-a7ee-85f548a5c488&_gl=1*177i2do*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MDQ1LjYwLjAuMA.." />
                                    Ir carga de CVS
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}