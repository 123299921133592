export  const TitleMessenger = () => {
    return (
        <>
            <p>Img</p>
            <p>Nombre</p>
            <p>Teléfono</p>
            <p>Departamento</p>
            <p>Ciudad</p>
            <p>Status</p>
        </>
    );
}
