export  const TitleSeller = () => {
    return (
        <>
            <p>Img</p>
            <p>Nombre</p>
            <p>Email</p>
            <p>Teléfono</p>
            <p>RUT</p>
        </>
    );
}
