import { reduceLength } from "../../../../tools/reduceLength";

export const ItemSeller = ({imageUrl, namecompany , emailfacturacion , telefono , rut}) => {
    const av = reduceLength((namecompany || "").toUpperCase(), 1);
    return (  
        <>
            <div className="avatar">
                {imageUrl ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={imageUrl}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{av}</h2>
                )}
            </div>
            <p>{namecompany}</p>
            <p>{emailfacturacion}</p>
            <p>{telefono}</p>
            <p>{rut}</p>
        </>
    );
}