import "./ItenListTemplate.scss";
import { useIdContext } from "../../../../context/IdContext";
import { ItemCategoryModule } from "../module/category/ItemCategoryModule";
import { ItemProducModule } from "../module/product/ItemProductModule";
import { ItemWarehouse } from "../module/warehouse/ItemWarehouse";
import { ItemRecollections } from "../module/recollections/ItemRecollections";
import { ItemMessenger } from "../module/messenger/ItemMessenger";
import { ItemSeller } from "../seller/ItemSeller";
import { ItemLicense } from "../roles/ItemLicense";

export  const ItenListTemplate = ({column, item, location, setIsOpenCreate }) => {
    const { setIdItem } = useIdContext(); // Usa el setter del contexto

    const toggleOpen = (idItem) => {
        setIdItem(idItem); // Almacena el id en el contexto
        setIsOpenCreate((prev) => !prev);
    };

    return (
        <button
            onClick={() => toggleOpen(item.id)}
            className="item-list-template"
            style={{
                display: 'grid',
                gridTemplateColumns: `40px repeat(${column}, 1fr) 100px`,
            }}
        >
            {/* Item de productos de las tablas */}
            { location.pathname === "/stock/productos/categorias"  && <ItemCategoryModule {...item} />}
            { location.pathname === "/stock/productos/base" &&<ItemProducModule {...item}/>}
            { location.pathname === "/seller" && <ItemSeller  {...item}/>}
            { location.pathname === "/company/warehouse" && <ItemWarehouse  {...item}/>}
            { location.pathname === "/messengers" && <ItemMessenger  {...item}/>}
            { (location.pathname === "/company/recolecciones" || location.pathname === "/recoleccion/search") && <ItemRecollections  {...item}/>}
            { location.pathname === "/admin/license" && <ItemLicense {...item}/>}
        </button>
    );
}
