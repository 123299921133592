import questions from '../encuestas/PreguntasBase';
import { useEffect, useState } from "react";
import { addDoc, collection, getDocs, query, where, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import restquestions from '../adminquizopen/RestBase';
import { MdOutlineDesk, MdOutlineElectricalServices } from "react-icons/md";
import { PiCardholderBold, PiCloudBold, PiHeadsetBold, PiOfficeChair } from "react-icons/pi";
import { TbLamp2 } from "react-icons/tb";
import { FiMonitor } from "react-icons/fi";
import { BsMouse3, BsOutlet } from "react-icons/bs";
import { LuKeyboard } from "react-icons/lu";
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';
import { toast } from 'react-toastify';
import { useLoginContext } from '../../../../context/LoginContext';

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/createUserOpen"; 

async function createUserOpen(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            toast.success("Email enviado con exito");
        } else {
            toast.error("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            toast.error("Error body:", errorBody);
        }
    } catch (error) {
        toast.error("Error de envio email:", error);
    }
}

export const ContectAdminProvicional = ({  setIsRegisterOpen }) => {
    const { user, setShouldReload, setLoading } = useLoginContext();
    const { idc, compañia, email, nombre, apellido, telefono, id} = user;
    const [results, setResults] = useState({});
    const [info, setInfo] = useState ([]);
    const [users, setUsers] = useState ([]);
    const [error, setError] = useState(null);
    const [updateQuiz, setupdateQuiz] = useState(0);
    const [formData, setFormData] = useState({
        answer:false,
        cc: "",
        company:compañia,
        date:new Date(),
        email: email,
        height: "",
        idc: idc,
        ider:"",
        lastname:apellido,
        name: nombre,
        phone: telefono,
        sex:"",
        status:true,
        weight:"",
        year:"",
        pais:'Colombia',
        department: '',
        city: '',
        address: '',
        address2: '',
    });

    useEffect(() => {
        const fetchAnswers = async () => {
            try {
                const answersCollectionRef = collection(db, 'quizanswer_open');
                const q = query(answersCollectionRef, where("idc", "==", idc));
                const querySnapshot = await getDocs(q);
                const answersData = querySnapshot.docs.map(doc => doc.data());
                compareAnswers(answersData); // Llamar a compareAnswers aquí
                setInfo(answersData )
                setLoading(false);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchAnswers();
    }, [idc, setLoading, updateQuiz]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const answersCollectionRef = collection(db, 'users_open');
                const q = query(answersCollectionRef, where("idc", "==", idc));
                const querySnapshot = await getDocs(q);
                const answersData = querySnapshot.docs.map(doc => doc.data());
                setUsers(answersData )
                setLoading(false);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers ();
    }, [idc, setLoading, updateQuiz]);

    const compareAnswers = (allAnswers) => {
        const aggregatedResults = {};

        allAnswers.forEach(givenAnswers => {
            restquestions.forEach(question => {
                const mainId = question.id;
                const givenMainAnswer = givenAnswers.respuestas[mainId]?.respuesta?.opcione || "";
                const isMainCorrect = Array.isArray(question.mainQuestion) ? question.mainQuestion.includes(givenMainAnswer) : givenMainAnswer === question.mainQuestion;

                let totalCorrect = isMainCorrect; // Comienza asumiendo que la respuesta principal es correcta

                // Verificar y contar las subpreguntas
                if (question.subquestions) {
                    Object.keys(question.subquestions).forEach(subQId => {
                        const givenSubAnswer = givenAnswers.respuestas[mainId]?.subquestions[subQId]?.respuesta?.opcione || "";
                        const isSubCorrect = givenSubAnswer === question.subquestions[subQId];
                        totalCorrect = totalCorrect && isSubCorrect; // Solo si todas son correctas, se mantiene true
                    });
                }
                // Agregar el resultado total para la pregunta y todas sus subpreguntas
                aggregatedResults[mainId] = aggregatedResults[mainId] || { true: 0, false: 0 };
                aggregatedResults[mainId][totalCorrect ? 'true' : 'false'] += 1;
            });
        });

        setResults(aggregatedResults);
    };

    const getResultsById = (results, questionId) => {
        if (results[questionId]) {
            return results[questionId].false;
        }
        return 0;
    };
    
    const getResultsByIdTrue = (results, questionId) => {
        if (results[questionId]) {
            return results[questionId].true;
        }
        return 0;
    };
    

    //Consulta ID 'Q00000000001'
    const Res1 = getResultsById(results, 'Q00000000001');
    const finalResult1 = (Res1 === 0 )
    //Consulta ID 'Q00000000001'
    const Res2 = getResultsById(results, 'Q00000000002');
    const finalResult2 = (Res2 === 0 )
    // /Consulta ID 'Q00000000003' 'Q00000000004'
    const Res3 = getResultsById(results, 'Q00000000003');
    const Res3T = getResultsByIdTrue(results, 'Q00000000003');
    const Res4 = getResultsById(results, 'Q00000000004');
    const Res4T = getResultsByIdTrue(results, 'Q00000000004');
    const Some = ((Res3+Res3T+Res4+Res4T));
    const Some3_4 = ((Some-(Res3T+Res4T)));
    const finalResult4 = (Some3_4 === 0 );
    //Consulta ID 'Q00000000005'
    const Res5 = getResultsById(results, 'Q00000000005');
    const finalResult5 = (Res5 === 0 )
    //Consulta ID 'Q00000000006'
    const Res6 = getResultsById(results, 'Q00000000006');
    const finalResult6 = (Res6 === 0 )
    //Consulta ID 'Q00000000006'
    const Res7 = getResultsById(results, 'Q00000000007');
    const finalResult7 = (Res7 === 0 )
    //Consulta ID 'Q00000000008'
    const Res8 = getResultsById(results, 'Q00000000008');
    const finalResult8 = (Res8 === 0 )
    //Consulta ID 'Q00000000009'
    const Res9 = getResultsById(results, 'Q00000000009');
    const finalResult9 = (Res9 === 0 )
    //Consulta ID 'Q00000000010'
    const Res10 = getResultsById(results, 'Q00000000010');
    const finalResult10 = (Res10 === 0 )
    //Consulta ID 'Q00000000010'
    const Res11 = getResultsById(results, 'Q00000000011');
    const finalResult11 = (Res11 === 0 )
    //Consulta ID 'Q00000000012'
    const Res12 = getResultsById(results, 'Q00000000012');
    const finalResult12 = (Res12 === 0 )

    const someTotal = info.reduce((acumulador, objetoActual) => acumulador + objetoActual.resultado, 0);
    const divTotal = info.length;
    const userTotal = users.length;
    // Preparar los datos para la gráfica circular
    const porcentajeRedondeado = Math.round(someTotal/divTotal) || 0; // Redondea el porcentaje a un número entero
    const porcentajeRestanteRedondeado = 100 - porcentajeRedondeado; 
    const datosResult = [
        { label: 'Positivo', value: porcentajeRedondeado },
        { label: 'Negativo', value: porcentajeRestanteRedondeado }
    ];

    // Calculamos el porcentaje de 'userTotal' respecto a 'divTotal'
    const poRedo = (divTotal/userTotal) * 100;
    const poRedoRedondeado = Math.round(poRedo); 
    const porRestRest = 100 - poRedoRedondeado; 
    const datosResultResp = [
        { label: 'Contestado', value: poRedo },
        { label: 'Pendiente', value: porRestRest }
    ];

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setLoading(true); // Activar el indicador de carga
        try {
            const creationDate = new Date(); // Obtener la fecha y hora actual
            const docSnap = await addDoc(collection(db, 'quiz'), {
                title:"Diagnóstico de puestos de trabajo",
                questions: questions,
                idc: idc,
                status: true,
                send: true,
                editable: false,
                creationDate,
                nameCompany: compañia,
            });
            const docRef = doc(collection(db, 'users_open'), id);
            // Guarda los datos en el documento referenciado
            await setDoc(docRef, { ...formData, ide: docSnap.id});
            const companyRef = doc(db, "company", idc);
            await updateDoc(companyRef, {
                ide: docSnap.id
            });
            await createUserOpen({ ...formData, ide:docSnap.id, id: docRef.id });
            setFormData({
                answer:false,
                cc: "",
                company:compañia,
                date:new Date(),
                email: "",
                height: "",
                idc: idc,
                ide:"",
                ider:"",
                lastname:"",
                name:"",
                phone:"",
                sex:"",
                status:true,
                weight:"",
                year:"",
                pais:'Colombia',
                department: '',
                city: '',
                address: '',
                address2: '',
            });
            setupdateQuiz(prev => prev + 1);
        } catch (error) {
            console.error("Error al crear encuesta: ", error);
        }
        setLoading(false); 
    };

    const toggleRegisterone = async (e) => {
        e.preventDefault();
        await handleSubmit();
        setShouldReload(true); 
        setIsRegisterOpen(true);
    };

    if (error) return <p>Error: {error.message}</p>;
    return (
        <div className="windons-quiz-result">
            <div className="windons-title-result">
                <div className="title-info">
                    <h1 className="title-aswer">Resultados de la Evaluación</h1>
                </div>
            </div>
            <section className="wimdons-quiz-contador-result-answer">
                {users.length !== 0
                    ?<div className="cabezera-quiz-contador">
                        <section className="card-quiz-contador">
                            <Stack direction="row">
                                <PieChart
                                    colors={['#1E67E2', '#85D0D5']} // Colores de tu elección
                                    series={[{
                                        paddingAngle: 1,
                                        innerRadius: 60,
                                        outerRadius: 80,
                                        cornerRadius: 19,
                                        data: datosResultResp,
                                    }]}
                                    margin={{ right: 5 }}
                                    width={200}
                                    height={200}
                                    legend={{ hidden: true }}
                                />
                            </Stack>
                            <div>
                                <h1>Encuestas contestadas:</h1>
                                <h2>{poRedoRedondeado}%</h2>
                                <p>Es el porcentaje de encuestas enviadas que ya fueron contestadas por el equipo de trabajo.</p>
                            </div>
                        </section>
                        <section className="card-quiz-contador">
                            <Stack direction="row">
                            {results.length}
                                <PieChart
                                    colors={['#1E67E2', '#85D0D5']} // Colores de tu elección
                                    series={[{
                                        paddingAngle: 1,
                                        innerRadius: 60,
                                        outerRadius: 80,
                                        cornerRadius: 19,
                                        data: datosResult,
                                    }]}
                                    margin={{ right: 5 }}
                                    width={200}
                                    height={200}
                                    legend={{ hidden: true }}
                                />
                            </Stack>
                            <div>
                                <h1>Valoración de la compañía:</h1>
                                <h2>{porcentajeRedondeado}%</h2>
                                <p>Esta es la media con la que la compañía está calificada con respecto al cumplimiento de la situación ergonómica adecuada con sus colaboradores.</p>
                            </div>
                        </section>
                    </div>
                    :<div className="date-user-prove-quiz-init">
                        <div className="quiz-init-img">
                            <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.."/>
                        </div>
                        <div className="quiz-init-contect">
                            <h2 className="title-shofi">Comencemos a encuestar a tu equipo de trabajo.</h2>
                            <h3 className="contect-shofi-loanding">Registra y envía la encuesta diagnóstica para identificar los puntos de mejora.</h3>
                            <button onClick={toggleRegisterone} className='button-new'>
                                <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                Crear usuario
                            </button>
                        </div>
                    </div>
                }
                <div className="section-icon-result-quiz">
                    <div className={(!finalResult1)? "result-pending": "result-positive"}>
                        <div className="counter-result"><MdOutlineDesk /></div>
                        <h2>Escritorio</h2>
                        {(!finalResult1)?
                        <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res1}</h2></div> <p>escritorio(s)</p></>
                        :<p>Está dotado con lo adecuado</p>}
                    </div>
                    <div className={(!finalResult2)? "result-pending": "result-positive"}>
                        <PiOfficeChair />
                        <h2>Silla</h2>
                        {(!finalResult2)? <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res2}</h2></div> <p> silla(s)</p></>
                        :<p>Está dotado con lo adecuado</p>}
                    </div>
                    <div className={(!finalResult4)? "result-pending": "result-positive"}>
                        <PiCloudBold />
                        <h2>Descansa pies</h2>
                        {(!finalResult4)? <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Some3_4}</h2></div> <p>descansapiés</p></>
                        :<p>Su espacio es adecuado</p>}
                    </div>
                    <div className={(!finalResult5)? "result-pending": "result-positive"}>
                        <TbLamp2 />
                        <h2>Lámparas</h2>
                        {(!finalResult5)?<><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res5}</h2></div> <p>lámpara(s)</p></>
                        :<p>Está dotado con lo adecuado</p>}
                    </div>
                    <div className={(!finalResult6)? "result-pending": "result-positive"}>
                        <FiMonitor />
                        <h2>Monitor</h2>
                        {(!finalResult6 )? <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res6}</h2></div> <p>monitor(s)</p></>
                        :<p>Está dotado con lo adecuado</p>}
                    </div>
                    <div className={(!finalResult7)? "result-pending": "result-positive"}>
                        <BsMouse3 />
                        <h2>Mouse</h2>
                        {(!finalResult7 )? <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res7}</h2></div> <p>mouse(s)</p></>
                        :<p>Está dotado con uno</p>}
                    </div>
                    <div className={(!finalResult8)? "result-pending": "result-positive"}>
                        <LuKeyboard />
                        <h2>Teclado</h2>
                        {(!finalResult8)? <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res8}</h2></div> <p> teclado(s)</p></>
                        :<p>Está dotado con uno</p>}
                    </div>
                    <div className={(!finalResult9)? "result-pending": "result-positive"}>
                        <PiHeadsetBold />
                        <h2>Auriculares</h2>
                        {(!finalResult9)? <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res9}</h2></div> <p>  auriculares</p></>
                        :<p>Está dotado con uno o no les necesita</p>}
                    </div>
                    <div className={(!finalResult10)? "result-pending": "result-positive"}>
                        <BsOutlet />
                        <h2>Reguladores de electricidad</h2>
                        {(!finalResult10)? <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res10}</h2></div> <p>regulador de energía para cuidar sus equipos</p></>
                        :<p>Su flujo eléctrico es adecuado</p>}
                    </div>
                    <div className={(!finalResult11)? "result-pending": "result-positive"}>
                        <MdOutlineElectricalServices />
                        <h2>Cableado eléctrico</h2>
                        {(!finalResult11 )? <><p>Necesita arreglar:</p><div className="notification-counter"><h2>{Res11}</h2></div> <p>espacio(s)</p></>
                        :<p>Tiene una zona segura y ordenada</p>}
                    </div>
                    <div className={(!finalResult12)? "result-pending": "result-positive"}>
                        <PiCardholderBold/>
                        <h2>Bases</h2>
                        {(!finalResult12 )? <><p>Necesita dotar:</p><div className="notification-counter"><h2>{Res9}</h2></div> <p> bases</p></>
                        :<p>Cumple con las necesidades ergonómicas</p>}
                    </div>                              
                </div>
            </section>
        </div>
    );
};
