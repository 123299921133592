import React, { createContext, useContext, useState } from 'react';

// Crear el contexto
const LoadContext = createContext();

// Proveedor del contexto
export const LoadProvider = ({ children }) => {
    const [reload, setReload] = useState(false);
    // Función para solicitar una recarga
    const triggerReload = () => {
        setReload(prev => !prev); // Cambiar el estado para disparar la recarga
    };
    return (
        <LoadContext.Provider value={{ reload, triggerReload }}>
            {children}
        </LoadContext.Provider>
    );
};

// Hook personalizado para usar el contexto
export const useLoad = () => {
    return useContext(LoadContext);
};
