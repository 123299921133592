// OpenGallery.js
import React from 'react';
import { useIdContext } from '../../../../context/IdContext'; // Importa el contexto
import { BiX } from 'react-icons/bi';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useSpring, animated } from '@react-spring/web';

export const OpenGallery = () => {
    const { selectedIndex, orderPictures, isModalOpen, setIsModalOpen } = useIdContext(); // Accede al estado del modal

    const closeModal = () => setIsModalOpen(false); // Cierra el modal

    const fade = useSpring({ opacity: isModalOpen ? 1 : 0 });

    if (!isModalOpen) return null;

    return (
        <animated.div style={fade} className="open-create">
            <button className="button-x-flotant" onClick={closeModal}>
                <BiX />
            </button>
            <div className="section-open-create-form-one">
                <Carousel 
                    selectedItem={selectedIndex} 
                    showThumbs={false}
                >
                    {orderPictures.map((item, index) => (
                        <div key={index}>
                            <img src={item.file} alt={`Imagen ${index + 1}`} />
                        </div>
                    ))}
                </Carousel>
            </div>
        </animated.div>
    );
};
