import { useEffect, useMemo, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useLoad } from "../../../../context/LoadContext";
import { PageListTemplate } from "../template/PageListTemplate";


export const Seller = ({idc, setIsOpenCreate}) => {
    const column = 3;
    const itemsPage = 20;
    const buttonTitle = "Crear Compañia";
    const { reload } = useLoad();
    const [info, setInfo] = useState ([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        setCurrentPage(1); // Resetear a la primera página al cambiar filtros
        const fetchUsers = async () => {
            try {
                const q = collection(db, 'company');
                const querySnapshot = await getDocs(q);
                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                if (isMounted) setInfo(users);
                setLoading(false);
            } catch (error) {
                if (isMounted) setError(error);
                toast.error("Error fetching users: " + error.message);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchUsers();
        return () => { isMounted = false; };
    }, [idc, reload]);


    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);
        handler();
        return () => {
            handler.cancel();
        };
    }, [searchTerm]);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const usersItems = useMemo(() => 
        info.filter(info => 
            info.namecompany.toLowerCase().includes(debouncedSearchTerm.toLowerCase()),
        ), [debouncedSearchTerm, info]
    );
    return (
        <div className="list-template">
            <PageListTemplate
                column={column} 
                itemsPage={itemsPage}
                info={info}
                usersItems={usersItems}
                loading={loading}
                error={error}
                currentPage={currentPage}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleChange={handleChange}
                setIsOpenCreate={setIsOpenCreate}
                buttonTitle={buttonTitle}
            />
        </div>
    );
}
