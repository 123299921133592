import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { AuthContext } from "../../../../context/AuthContext";
import "./ShippingInfomation.scss";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { FiX , FiRotateCcw, FiShoppingBag , FiEye, FiThumbsUp ,FiTag, FiServer, FiTruck ,  FiZap } from "react-icons/fi";

export const ShippingInfomation = ({order_velocity, orden, setOrden}) => {
    const { authToken } = useContext(AuthContext);
    const [error, setError] = useState(null); 

    useEffect(() => {
        const fetchOrden = async () => {
            try {
                const url = `https://api.velocity-x.co/orders/${order_velocity}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setOrden(response.data);
            } catch (error) {
                setError(error)
            }
        };
        fetchOrden ();
    }, [authToken, order_velocity, setOrden]);

    let status = orden ? orden.order_status_id : null;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <>
            {( status === 1 || status === 15 || status === 16 )&&
                <div class="empty-form">
                    <img className="img-shofi-loanding" alt="Quiz Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fsend%2Fwepik-export-202401011654424TrB%20(1).png?alt=media&token=95dc3c69-d496-4501-9848-a6c08369f918" />
                    <h2 className="title-shofi-loanding">Tus productos están siendo preparados, en un máximo de 5 días haremos la entrega.</h2>
                </div>
            }
            <Timeline position="alternate">
                {/* '1','Pendiente' */}
                {(status  === 13 || status  === 2 || status  === 1 || status  === 14 || status  === 4 || status  === 5|| status === 7 || status === 6)&&
                    <TimelineItem>
                        <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                            <FiEye />
                        </TimelineDot>
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">Pendiente</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">Solicitud procesado</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

                {/* '2','Confirmado' */}
                {(status  === 13 || status  === 2 || status === 14 || status === 4 || status === 5 || status === 7 || status === 8 || status === 6)&&
                    <TimelineItem>
                        <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                            <FiThumbsUp />
                        </TimelineDot>
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">Confirmado</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">Inicio de proceso logísticos</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

                {/* '13','Alistado' */}
                {(status === 13 || status  === 14 || status === 4 || status === 5 || status === 7 || status === 8 || status === 6 || status === 15)&&
                    <TimelineItem>
                        <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                            <FiTag />
                        </TimelineDot>
                        <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">Alistando</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">Iniciamos preparacion de equipos</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

                {/* '14','Empacado' */}
                {(status  === 14 || status === 4 || status  === 5 || status === 7 || status === 8 || status === 6)&&
                    <TimelineItem>
                        <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                            <FiShoppingBag />
                        </TimelineDot>
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">Empacado</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">Enbalaje de salida</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

                {/* '4','Esperando recogida' */}
                {(status  === 4 || status  === 5 || status === 7 || status === 8 || status === 6)&&
                    <TimelineItem>
                        <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                            <FiServer />
                        </TimelineDot>
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">Recogida</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">Esperando mensajero</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

                {/* '5','Enviado'*/}
                {(status === 5 || status === 7 || status === 8 || status === 6)&&
                    <TimelineItem>
                        <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                            <FiTruck />
                        </TimelineDot>
                        <TimelineConnector  sx={{ bgcolor: 'secondary.main' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">En Ruta</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">En camino a tu locación</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

                {/* '6','Entregado' */}
                {(status === 7 || status === 8 || status === 6)&&
                    <TimelineItem>
                        <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                        <TimelineDot color="secondary">
                            < FiZap />
                        </TimelineDot>
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">Entregado</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">Entrega en destino</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

                {/* '7','Devolución' */}
                {(status === 7 || status === 8)&&
                    <TimelineItem>
                        <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineConnector  />
                        <TimelineDot>
                            <FiRotateCcw />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: 'error.main' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">Devolución</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">Novedad en la entrega</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

                {/* '8','Cancelado' */}
                {(status === 8)&&
                    <TimelineItem>
                        <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'error.main' }} />
                        <TimelineDot color="error">
                            <FiX />
                        </TimelineDot>
                        <TimelineConnector  sx={{ bgcolor: 'error.main' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            <p className="title-timeline">Cancelado</p>
                        </Typography>
                        <Typography>
                            <p className="contect-timeline">Cancelación del pedido</p>
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                }

            </Timeline>
        </>
    );
};
