import { addDoc, collection } from "firebase/firestore";
import { useCallback, useState } from "react";
import { FiSave } from "react-icons/fi";
import { toast } from "react-toastify";
import { db } from "../../../../firebase/config";
import { useLoad } from "../../../../context/LoadContext";
import { inventory, modules } from "./Modules";

export const CreatorLicense = ({ setLoading, setIsOpenCreate }) => {
  const { triggerReload } = useLoad();
  const [formData, setFormData] = useState({
    name: "",
    typecompany: "",
    module: [],
  });

  const validateForm = () => {
    let isValid = true;
    let errorMessage = "";
    if (!formData.name) {
      errorMessage = "El nombre es obligatorio.";
      isValid = false;
    } else if (!formData.typecompany) {
      errorMessage = "El tipo de compañia es importante.";
      isValid = false;
    } else if (!formData.module || formData.module.length === 0) {
      errorMessage = "Debes escoger los modulos disponibles.";
      isValid = false;
    }
    if (!isValid) {
      toast.error(errorMessage);
    }
    return isValid;
  };

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  // Al seleccionar un inventario se actualiza el tipo de compañía y se reinician los módulos seleccionados
  const handleTypeCompanySelect = (id) => {
    setFormData((prev) => ({
      ...prev,
      typecompany: id,
      module: [],
    }));
  };

  // Seleccionar o deseleccionar un módulo
  const handleModuleSelect = (moduleId) => {
    setFormData((prev) => {
      const alreadySelected = prev.module.includes(moduleId);
      const newModules = alreadySelected
        ? prev.module.filter((id) => id !== moduleId)
        : [...prev.module, moduleId];
      return { ...prev, module: newModules };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    try {
      await addDoc(collection(db, "list_use_license"), {
        ...formData,
        update: new Date(),
      });
    toast.success("Documento creado con éxito.");
    setIsOpenCreate((prev) => !prev);
    triggerReload();
    setFormData({
        name: "",
        typecompany: "",
        module: [],
      });
    } catch (error) {
      console.error("Error creando el documento:", error.message);
      toast.error("Error creando el documento: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Obtenemos el inventario seleccionado
  const selectedInventory = inventory.find(
    (item) => item.id === formData.typecompany
  );

  // Filtramos los módulos: se muestran aquellos que tengan el valor del campo "modal" del inventario
  const filteredModules = selectedInventory
    ? modules.filter(
      (mod) =>
        mod.modal.includes(selectedInventory.modal) || mod.modal.length === 0
    )
  : [];

  return (
    <div
      className="section-open-create-form"
      style={{ display: "grid", gridTemplateColumns: "1fr" }}
    >
      <form onSubmit={handleSubmit} className="open-create-form-dates">
        <div className="input-form-create">
          <label>Nombre licencia</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="section-form-create">
          <label>Tipo de compañia</label>
          <div className="button-group-create">
            {inventory.map((item) => (
              <button
                type="button"
                key={item.id}
                className={`option-button ${
                  formData.typecompany === item.id ? "of-selected-module" : "selected-module"
                }`}
                onClick={() => handleTypeCompanySelect(item.id)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        {formData.typecompany 
            ?(<div className="section-form-create">
                <label>Selecciona los módulos</label>
                <div className="button-group-create" style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
                    {filteredModules.map((mod) => ( 
                        <button
                        key={mod.id}
                        type="button"
                        className={`option-button ${
                            formData.module.includes(mod.id) ?  "of-selected-module" : "selected-module"
                        }`}
                        onClick={() => handleModuleSelect(mod.id)}
                        >
                        {mod.name}
                        </button>
                    ))}
                </div>
            </div>)
            :(<div className="section-form-create">
                <label>Selecciona los módulos</label>
                <h2>Selecciona un tipo de compañía</h2>
            </div>)
        }
        <button className="button-new space-top" type="submit">
            Crear licencia <FiSave />
        </button>
      </form>
    </div>
  );
};
