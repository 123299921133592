import "./MenuLeft.scss";
import { Link} from "react-router-dom";
import { NavMenuAdmin } from "../../atoms/NavMenuAdmin/NavMenuAdmin";

export const MenuLeft = ({ rol, imageUrl, module_active}) => {

    return (
        <article className="menu-left">
            {!imageUrl
                ?<Link className="brand-menu-left" to={"/"}>
                    <img className="logo-shofi" alt="Admin Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"/>
                    <img className="icon-shofi" alt="Admin Icon Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"/>
                </Link>
                :<Link className="brand-left" to={"/"}>
                    <img className="logo-shofi" alt="Admin Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"/>
                    <img className="logo-shofi2" alt="Admin Shofi" src={imageUrl}/>
                    {imageUrl &&
                        <img className="icon-shofi" alt="Admin Icon Shofi" src={imageUrl}/>
                    }
                </Link>
            }
            <NavMenuAdmin rol={rol} module_active={module_active}/>
            <div className="logout-left">
                V 2.01.01
            </div>
        </article>
    );
};
