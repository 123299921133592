// IdContext.js
import React, { createContext, useContext, useState } from 'react';

const IdContext = createContext();

export const IdProvider = ({ children }) => {
    const [idItem, setIdItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [orderPictures, setOrderPictures] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <IdContext.Provider value={{ 
            idItem, 
            setIdItem, 
            selectedIndex, 
            setSelectedIndex, 
            orderPictures, 
            setOrderPictures, 
            isModalOpen, 
            setIsModalOpen,
        }}>
            {children}
        </IdContext.Provider>
    );
};

export const useIdContext = () => useContext(IdContext);
