import { BiRocket , BiMessageAltX} from "react-icons/bi";

export const ItemMessenger = ({img, name , phone, state, city, status}) => {
    return (  
        <>
            <div className="avatar">
                {img ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{name}</h2>
                )}
            </div>
            <p>{name}</p>
            <p>{phone}</p>
            <p>{state}</p>
            <p>{city}</p>
            <div>
                {status
                    ?<p className="route-o"><BiRocket /> Activo</p>
                    :<p className="cancelled-o"><BiMessageAltX /> Baja </p>
                }
            </div>
        </>
    );
}
