import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useCallback, useState } from "react";
import { FiSave } from "react-icons/fi";
import { HiOutlinePhotograph } from "react-icons/hi";
import { toast } from "react-toastify";
import { db } from "../../../../../firebase/config";
import { useLoad } from "../../../../../context/LoadContext";
import { GetCroppedImg } from "../../template/CropImageHelper";
import { MdAspectRatio, MdOutlineCancel } from "react-icons/md";
import Cropper from "react-easy-crop";
import { v4 as uuidv4 } from 'uuid'; 

export const CreatorCategoryModule = ({ idc, setLoading, setIsOpenCreate }) => {
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const { triggerReload } = useLoad();
    const [skuError, setSkuError] = useState('');
    const [formData, setFormData] = useState({
        idc: idc,
        img: "",
        name: "",
        description: "",
        indicative_sku: "",
    });

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === "indicative_sku") {
            const regex = /^[a-zA-Z0-9]{0,3}$/;
            if (!regex.test(value)) {
                setSkuError("Solo se permiten letras, números y no más de 3 caracteres.");
                return; // No actualizar el valor si no es válido
            } else {
                setSkuError(''); // Limpiar el error si el valor es válido
            }
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);
    
    

    const validateForm = () => {
        let isValid = true;
        let errorMessage = ""; // Variable para acumular los mensajes de error
        // Validar campos vacíos
        if (!formData.name) {
            errorMessage = "El nombre es obligatorio.";
            isValid = false;
        } else if (!formData.indicative_sku) {
            errorMessage = "El SKU es obligatorio.";
            isValid = false;
        } else if (!/^[a-zA-Z0-9]{3,}$/.test(formData.indicative_sku)) {
            errorMessage = "El SKU debe tener al menos 3 caracteres, solo letras y números.";
            isValid = false;
        } else if (!formData.img) {
            errorMessage = "La imagen es obligatoria.";
            isValid = false;
        } else if (!formData.description) {
            errorMessage = "La descripción es obligatoria.";
            isValid = false;
        }
        // Si no es válido, mostrar un solo mensaje de error con toast
        if (!isValid) {
            toast.error(errorMessage);
        }
        // Retornar si el formulario es válido o no
        return isValid;
    };
    


    const checkSKUExists = async (indicative_sku, idc) => {
        try {
            const q = query(
                collection(db, "category_stock_company"),
                where("indicative_sku", "==", indicative_sku),
                where("idc", "==", idc)
            );
            const docs = await getDocs(q);
            return !docs.empty; // Si hay documentos, retorna true
        } catch (error) {
            console.error("Error al verificar SKU:", error);
            return false; // Error significa que no se encontró
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImageSrc(reader.result); // Mostrar en el Cropper
            };
        }
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    // Nueva función para redimensionar la imagen
    const resizeImage = (image, width, height) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const resizedImage = new File([blob], "croppedImage.jpg", { type: blob.type });
                resolve(resizedImage);
            }, 'image/jpeg');
        });
    };

    const cropImage = async () => {
        try {
            // Obtener la imagen recortada
            const croppedImage = await GetCroppedImg(imageSrc, croppedAreaPixels);
    
            // Crear un objeto Image para redimensionar
            const img = new Image();
            img.src = URL.createObjectURL(croppedImage);
    
            img.onload = async () => {
                // Calcular el tamaño para mantener la proporción
                const maxSize = 1000;
                let width = img.width;
                let height = img.height;
    
                if (width > height) {
                    height = (height * maxSize) / width;
                    width = maxSize;
                } else {
                    width = (width * maxSize) / height;
                    height = maxSize;
                }
    
                // Redimensionar la imagen usando la nueva función
                const resizedImage = await resizeImage(img, width, height);
    
                // Actualizar el estado con la imagen redimensionada
                setFormData((prev) => ({ ...prev, img: resizedImage }));
                setImagePreviewUrl(URL.createObjectURL(resizedImage));
                setImageSrc(null); // Cerrar el cropper
            };
        } catch (error) {
            console.error("Error recortando la imagen", error);
            toast.error("Error recortando la imagen.");
        }
    };
    

    const uploadImageToStorage = async (file, customName) => {
        try {
            const storage = getStorage(); // Inicializar el storage
            const storageRef = ref(storage, `images/${customName}`); // Usar nombre personalizado
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref); // Obtener la URL de descarga
            return downloadURL;
        } catch (error) {
            console.error("Error subiendo la imagen:", error);
            throw new Error("Error subiendo la imagen.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validar el formulario
        if (!validateForm()) {
            return; // Si hay errores, no continuar
        }
        // Verificar si el SKU ya existe
        const skuExists = await checkSKUExists(formData.indicative_sku, formData.idc);
        if (skuExists) {
            toast.error('Este indicativo ya está registrado.');
            setLoading(false);
            return;
        }
        try {
            setLoading(true);
            // Subir imagen a Firebase Storage si se ha seleccionado una
            let imgURL = "";
            if (formData.img instanceof File) {
                // Generar un nombre personalizado para la imagen
                const customName = `${formData.indicative_sku}-${new Date().toISOString()}-${uuidv4()}.jpg`;
                // Subir la imagen con el nombre personalizado
                imgURL = await uploadImageToStorage(formData.img, customName);
            }
            // Guardar el documento en Firestore, incluyendo la URL de la imagen
            await addDoc(collection(db, "category_stock_company"), {
                ...formData,
                img: imgURL, // Guardamos la URL de la imagen
            });
            toast.success("Documento creado con éxito.");
            setIsOpenCreate(isOpenCreate => !isOpenCreate);
            // Después de guardar, disparar la recarga
            triggerReload();
            setFormData({
                idc: idc,
                img: "",
                name: "",
                description: "",
                indicative_sku: "",
            });
        } catch (error) {
            console.error("Error creando el documento:", error.message);
            toast.error("Error creando el documento: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="section-open-create-form">
            {imageSrc ? (
                <div className="crop-container">
                    <div className="crop-area">
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1} // Proporción 1:1
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            style={{
                                containerStyle: {
                                    width: '100%',
                                    height: '400px',
                                    maxWidth: '500px',
                                    borderRadius: '30px',
                                    overflow: 'hidden',
                                },
                            }}
                        />
                    </div>
                    <div className="crop-buttons">
                        <button onClick={cropImage} className="button-new">
                            <MdAspectRatio /> Recortar
                        </button>
                        <button onClick={() => setImageSrc(null)} className="button-delete">
                            <MdOutlineCancel />Cancelar
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <section className="open-create-form-img">
                        <div className="input-img">
                            <img
                                className="user"
                                alt="user"
                                src={imagePreviewUrl || 'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'}
                            />
                            <div className="input-upload">
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <HiOutlinePhotograph /> {imagePreviewUrl ? 'Cambiar' : 'Subir imagen'}
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                    </section>
                    <form onSubmit={handleSubmit} className="open-create-form-dates">
                        <div className='input-form-create'>
                            <label>Nombre(Obligatorio)</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange}/>
                        </div>
                        <div className='input-form-create'>
                            <label>Indicativo SKU: (Obligatorio)</label>
                            <input type="text" name="indicative_sku" value={formData.indicative_sku} onChange={handleChange} />
                            {skuError && <p className="error-message">{skuError}</p>}
                        </div>
                        <div className='input-form-create'>
                            <label>Descripción(Obligatorio)</label>
                            <textarea type="text" name="description" value={formData.description} onChange={handleChange} />
                        </div>
                        <button className="button-new space-top" type="submit">
                            Crear categoria <FiSave />
                        </button>
                    </form>
                </>
            )}
        </div>
    );
}
