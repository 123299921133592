import { Home } from '../components/page/home/Home';
import { NavBar } from '../components/UI/organisms/Navbar/NavBar';
import { Login } from '../components/page/login/Login'
import { Footer } from '../components/UI/organisms/footer/Footer';
import { Nosotros } from '../components/page/nosotros/Nosotros';
import { Politicas } from '../components/page/politicas/Politicas';
import { Tratamiento } from '../components/page/politicas/Tratamientos';
import { FormDemo } from '../components/page/formdemo/FormDemo';
import { ScrollToTop } from '../components/UI/atoms/ScrollToTop/ScrollToTop'
import { Sigo } from '../components/page/sigo/Sigo';
//Paqueterias
import { useState } from 'react';
import { Route, Routes, Navigate ,useLocation } from 'react-router-dom';
import { Terminos } from '../components/page/politicas/Terminos';
import { TerminosSiigo } from '../components/page/politicas/TerminosSiigo';
import { TerminosSiigoPOS } from '../components/page/politicas/TerminosSiigoPOS';
import { PasswordResetPage } from '../components/page/passwordreset/PasswordResetPage';
import { RestQuizOpen } from '../components/page/quizopen/RestQuizOpen';
import { Catalogue } from '../components/page/productos/Catalogue';
import { PopupWindowInfo } from '../components/UI/organisms/Section_3/PopupWindowInfo';
import { Productos } from '../components/page/productos/Productos';
import { Plataforma } from '../components/page/plataforma/Platafoma';
import { Forms } from '../components/page/forms/Forms';

const PublicRouter = () => {
    const [isOpenR, setIsOpenR] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isCatalogue, setIsCatalogue ] = useState(false);
    const [isWindow, setIsWindow ] = useState(false);
    const [idCatalog , setIdCatalog] = useState("");
    const [idInfo , setIdInfo] = useState("");
    const location = useLocation();

    const shouldHideNavBarAndFooter = () => {
        const hideForRoutes = [
            "/login",
            "/contact/demo",
            "/gallery",
            "/register/guia",
            "/startco",
            "/startco/",
            "/test/diagnostic",
            new RegExp("/password-reset/.+/.+"),
            new RegExp("/quiz/rest/.+/.+/.+"),
        ];
        return hideForRoutes.some(route => {
            if (route instanceof RegExp) {
                return route.test(location.pathname);
            } else {
                return route === location.pathname;
            }
        });
    };

    const hideNavBarAndFooter = shouldHideNavBarAndFooter();
    return (
        <>
            {isOpenR&&<FormDemo setIsOpenR={setIsOpenR}/>}
            {isLogin&&<Login setIsLogin={setIsLogin}/>}
            {isWindow&&<PopupWindowInfo setIsWindow={setIsWindow} id={idInfo}/>}
            {isCatalogue&&<Catalogue setIsCatalogue={setIsCatalogue} idCatalog={idCatalog}/>}
            {!hideNavBarAndFooter && <NavBar setIsLogin={setIsLogin}/>}
            <ScrollToTop />
            <Routes >
                <Route path='/' element={<Home setIsOpenR={setIsOpenR} setIdInfo={setIdInfo} setIsWindow ={setIsWindow }/>}/>
                <Route path='/subscripcion' element={<Home  setIsOpenR={setIsOpenR} setIdInfo={setIdInfo} setIsWindow ={setIsWindow }/>} />
                <Route path='/subscripcion/true' element={<Home  setIsOpenR={setIsOpenR} setIdInfo={setIdInfo} setIsWindow ={setIsWindow }/>} />
                <Route path='/productos' element={<Productos setIsOpenR={setIsOpenR} setIsCatalogue={setIsCatalogue} setIdCatalog={setIdCatalog}/>}/>
                <Route path='/nosotros' element={<Nosotros  setIsOpenR={setIsOpenR}/>}/>
                <Route path='/plataforma' element={<Plataforma  setIsOpenR={setIsOpenR}/>}/>
                <Route path='/politicas_condiciones' element={<Politicas />}/>
                <Route path='/tratamiento_de_datos_personales' element={<Tratamiento/>}/>
                <Route path='/terminos_condiciones_shofi' element={<Terminos/>}/>
                <Route path="/password-reset/:token/:email" element={<PasswordResetPage setIsLogin={setIsLogin}/>} /> 
                <Route path='/quiz/rest/:idc/:ide/:idu' element={<RestQuizOpen  to={'/quiz/rest/:idc/:ide/:idu'}/>} />
                <Route path='/login/:assignment' element={<Home setIsOpenR={setIsOpenR} setIsLogin={setIsLogin} />} />
                {/*Ruta de formularios de la web para contacto*/}
                <Route path='/index.php/invitacion-2/' element={<Sigo setIsOpenR={setIsOpenR} setIsWindow ={setIsWindow } setIdInfo={setIdInfo}/>}/>
                <Route path='/aliados/siigo' element={<Sigo setIsOpenR={setIsOpenR} setIsWindow ={setIsWindow } setIdInfo={setIdInfo}/>}/>
                <Route path='/diagnostico/test' element={<Sigo setIsOpenR={setIsOpenR} setIsWindow ={setIsWindow } setIdInfo={setIdInfo}/>}/>
                <Route path='/aliados/sigo/terminos_condiciones' element={<TerminosSiigo />}/>
                <Route path='/aliados/sigo/terminos_condiciones_POS' element={<TerminosSiigoPOS />}/>
                <Route path='/aliados/siigoPos' element={<Sigo setIsOpenR={setIsOpenR} setIsWindow ={setIsWindow } setIdInfo={setIdInfo}/>}/>
                <Route path='/aliados/treli' element={<Forms  setIsOpenR={setIsOpenR} setIsWindow ={setIsWindow } setIdInfo={setIdInfo} />}/>
                <Route path='/webinars/shofi' element={<Forms  setIsOpenR={setIsOpenR} setIsWindow ={setIsWindow } setIdInfo={setIdInfo} />}/>
                <Route path='/download/guide' element={<Forms  setIsOpenR={setIsOpenR} setIsWindow ={setIsWindow } setIdInfo={setIdInfo} />}/>
                {/*Ruta por defecto*/}
                <Route path='*' element={<Navigate to={"/"} /> }/>
            </Routes>
            {!hideNavBarAndFooter && <Footer setIsOpenR={setIsOpenR}  setIsLogin={setIsLogin}/>}
        </>
    );
}
export default PublicRouter;