import { useState } from "react";
import { SearchBar } from "../../molecules/SearchBar/SearchBar";
import { User } from "../../molecules/User/User";
import { MovileMenuAdmin } from "../../molecules/MovileMenuAdmin/MovileMenuAdmin";
import "./MenuHeader.scss"

export const MenuHeader = ({nombre, apellido, email, img, rol , id, setIsPassword } ) => {
    const [isOpena, setIsOpena] = useState(false);
    const toggleMovilA = () => {
        setIsOpena(!isOpena);
    };
    const closeMovila = () => {
        setIsOpena(false);
    };
    return (
        <div className="menu-header">
            <SearchBar
                toggleMenuA = {toggleMovilA} 
                isOpena = {isOpena}
            />
            <User nombre={nombre} apellido={apellido} email={email} img={img} id={id} setIsPassword={setIsPassword}/>
            <MovileMenuAdmin
                closeMovila = {closeMovila}
                isOpena ={isOpena}
                setIsOpena={setIsOpena}
                rol={rol}
            />
        </div>
    );
}
