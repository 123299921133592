import "./OrdenRegister.scss";
import { DeliveryStatusOrden } from "../../atoms/DeliveryStatusOrden/DeliveryStatusOrden";
import { useIdContext } from '../../../../context/IdContext';

export const OrdenRegister = ({ order_velocity, id, order_product, order_pictures, order_status_id, creation, date_delete, date_delivery, updated_at, order_siigo_id, setIsOpenGallery}) => {
    // Manejo del estado del modal y la imagen seleccionada
    const { setSelectedIndex, setOrderPictures, setIsModalOpen} = useIdContext(); 
    

    const formatDate = (date) => {
        try {
            return date ? date.toDate().toLocaleDateString('es-ES') : '';
        } catch (e) {
            console.error("Error al convertir fecha:", e);
            return '';
        }
    };

    const openModal = (index) => {
        setIsOpenGallery(true);
        setSelectedIndex(index);
        setOrderPictures(order_pictures);
        setIsModalOpen(true);
    };

    const fechaFormateada = formatDate(creation);
    const fechaFormateadaDelivery = formatDate(date_delivery);
    const fechaFormateadaUpdated = formatDate(updated_at);
    const fechaFormateadaCancel = formatDate(date_delete);

    return (
        <>
            {id && (
                <div className="iten-orden-user">
                    <section className="detail-orden-date">
                        <article className="orden-date">
                            <h4 className="subtitle3">ID orden: {id}</h4>
                            {order_siigo_id && <p>Orden ID: {order_siigo_id}</p>}
                            <p>{order_velocity}</p>
                        </article>
                        <article className="orden-description">
                            <DeliveryStatusOrden order_status_id={order_status_id} />
                            <p className="date-orden">Fecha creación: {fechaFormateada}</p>
                            {order_status_id === 6 && (
                                <p className="date-sale">
                                    Fecha de entrega: {fechaFormateadaDelivery || fechaFormateadaUpdated}
                                </p>
                            )}
                            {(order_status_id === 7 || order_status_id === 8) && (
                                <p className="date-cancel">Fecha cancelado: {fechaFormateadaCancel}</p>
                            )}
                        </article>
                    </section>

                    <section className="detail-orden-products">
                        <div className="container-list-product">
                            <h2 className="subtitle3">Lista De Productos:</h2>
                            <div className="container-iten-produc">
                                <p>Img</p>
                                <p className="iten-produc-name">Nombre</p>
                                <p className="iten-produc-sku">SKU</p>
                            </div>
                            {order_product.map((item, index) => (
                                <div key={index + item.product_id} className="container-iten-produc">
                                    <img alt={item.name} src={item.picture_url} />
                                    <p className="iten-produc-name">{item.name}</p>
                                    <p className="iten-produc-sku">{item.sku}</p>
                                </div>
                            ))}
                        </div>
                        {order_pictures.length > 0 && (
                            <div className="container-orden-pintures">
                                <h2 className="subtitle3">Firma & Registro Fotográfico:</h2>
                                <div className="orden-pintures">
                                {order_pictures.map((item, index) => (
                                    <img
                                        key={index}
                                        src={item.file}
                                        alt={`Imagen ${index + 1}`}
                                        onClick={() => openModal(index)}
                                        className="thumbnail"
                                    />
                                ))}
                                </div>
                            </div>
                        )}
                    </section>
                </div>
            )}
        </>
    );
};
