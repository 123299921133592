import { useContext, useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import "./Equipos.scss";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { Link } from "react-router-dom";
import { ListStatusDelivery } from "../../../UI/organisms/ListStatusDelivery/ListStatusDelivery";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthContext";
import axios from 'axios';

export const TeamStatus = ({ idc, compañia, id }) => {
    const itemsPerPage = 10;
    const { authToken } = useContext(AuthContext);
    const [ group, setGroup ] = useState([]);
    const [ orden, setOrden ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [ selectedUsers, setSelectedUsers ] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(1);

    useEffect(() => {
        setLoading(true);
        const fetchInfo = async () => {
            try {
                if (!idc) throw new Error("Company undefined");
                const kitApplicationRef = collection(db, 'kit_application');
                const q = query(kitApplicationRef, where('idc', '==', idc));
                const querySnapshot = await getDocs(q);
                let usersIdArray = [];
                querySnapshot.docs.forEach(doc => {
                    const data = doc.data();
                    if (data.users && Array.isArray(data.users)) {
                        usersIdArray = [...usersIdArray, ...data.users];
                    }
                });
                const uniqueUsersIdArray = [...new Set(usersIdArray)]; // Filtrar duplicados
                const usersDataArray = [];
                for (const userId of uniqueUsersIdArray) {
                    const userRef = doc(db, 'users', userId);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userData = { id: userId, ...userSnap.data() };
                        // Consulta a la colección 'orden' para este usuario
                        const ordenesRef = collection(db, 'orden');
                        const qOrdenes = query(ordenesRef, where('idu', '==', userId));
                        const ordenesSnapshot = await getDocs(qOrdenes);
                        // Recopilar el campo 'order_velocity' de las órdenes encontradas
                        const orderVelocityValues = ordenesSnapshot.docs.map(doc => doc.data().order_velocity);
                        // Agregar el array de 'order_velocity' al objeto del usuario
                        userData.orders = orderVelocityValues;
                        usersDataArray.push(userData);
                    } else {
                        toast.error(`No se encontró el usuario con ID: ${userId}`);
                    }
                }
                // Actualizar el estado con la información de los usuarios incluyendo sus valores de 'order_velocity'
                setGroup(usersDataArray);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchInfo();
    }, [idc]);


    useEffect(() => {
        const fetchInfo = async () => {
            try {
                if (!selectedUsers) throw new Error("Company undefined");
                const uniqueUsersIdArray = [...new Set(selectedUsers)];
                const usersDataArray = [];
                for (const userId of uniqueUsersIdArray) {
                    const userRef = doc(db, 'users', userId);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userData = { id: userId, ...userSnap.data(), orders: [] };
                        const ordenesRef = collection(db,'orden');
                        const qOrdenes = query(ordenesRef, where('idu','==', userId));
                        const ordenesSnapshot = await getDocs(qOrdenes);
                        const orderVelocityIds = ordenesSnapshot.docs.map(doc => doc.data().order_velocity);
                        // Aquí realizas las peticiones para cada order_velocity
                        const ordersDataPromises = orderVelocityIds.map(async (order_velocity) => {
                            const url = `https://api.velocity-x.co/orders/${order_velocity}`;
                            try {
                                const response = await axios.get(url, {
                                    headers: {
                                        Authorization: `Bearer ${authToken}`
                                    }
                                });
                                return response.data; // Suponiendo que esto devuelve los datos de la orden
                            } catch (error) {
                                console.error("Error fetching order data:", error);
                                return null; // Manejar errores individualmente y continuar con la siguiente petición
                            }
                        });
                        // Esperar a que todas las promesas se resuelvan
                        const ordersData = await Promise.all(ordersDataPromises);
                        // Filtrar las respuestas nulas (en caso de error)
                        userData.orders = ordersData.filter(order => order !== null);
                        usersDataArray.push(userData);
                    } else {
                        toast.error(`No se encontró el usuario con ID: ${userId}`);
                    }
                }
                setOrden(usersDataArray);
            } catch (err) {
                setError(err);
            }
        };
        fetchInfo();
    }, [idc, authToken, selectedUsers]); // Añadir authToken a las dependencias si es necesario

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const numberOfPages = Math.ceil( group.length / itemsPerPage);
    const displayItems = group.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!group) return <p>No user info</p>;
    return (
        <>
            { group&&( group.length!==0)
                ?<div className="equipo-admin">
                    <ListStatusDelivery
                        idc = {idc}
                        compañia={compañia}
                        id = {id}
                        info = {group}
                        orden = {orden}
                        handleChange = {handleChange}
                        displayItems = {displayItems}
                        numberOfPages = {numberOfPages}
                        currentPage = {currentPage}
                        selectedUsers= {selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                    />
                </div>
                :<div className="windons">
                    <div className="encabezado-list">
                        <h2 className="title">Equipo: {compañia}</h2>
                    </div>
                    <div className="windons-quiz-init">
                        <div className="section-quiz-init">
                            <div className="quiz-init-img">
                                <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.."/>
                                <h2 className="title-shofi-loanding">Comencemos a crear<br></br>tu equipo de trabajo.</h2>
                            </div>
                            <div className="quiz-init-contect">
                                <h3 className="subtitle2">Registra a tu equipo de trabajo<br/>y comienza a trabajar y disponer de tus recursos</h3>
                                <Link to={"/usuarios/creador"} className='button-new'>
                                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                    Crear usuario
                                </Link>
                                <button className='button-asing'>
                                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Frender-3d-manos-aplaudiendo-efecto-sonido-PhotoRoom.png-PhotoRoom%20(1).png?alt=media&token=bd7dbc6d-3932-4a38-a7ee-85f548a5c488&_gl=1*177i2do*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MDQ1LjYwLjAuMA.." />
                                    Cargar tu equipo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}