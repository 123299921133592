import "./Equipos.scss";
// Llamado de Api
import axios from 'axios';
// Llamado de base datos
import rolusers from './RolUser';
import PerfilRol from './PerfilRol';
import PerfilCompany from './PerfilCompany';
import colombia from '../../../../firebase/colombia';
import { db} from '../../../../firebase/config';
import { addDoc, arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, query, updateDoc, where,  deleteDoc, getFirestore } from 'firebase/firestore';
// Paqueterias
import React, { useState ,  useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiChevronLeft} from 'react-icons/bi';
import { LuSave } from "react-icons/lu";
import { HiOutlinePhotograph } from "react-icons/hi";
// Componentes
import { InfoLoading } from '../../../../Loanding';
import { FiAlertCircle, FiPaperclip } from 'react-icons/fi';
import { MdDeleteOutline } from "react-icons/md";
import { useLoginContext } from '../../../../context/LoginContext';
import { RiLockPasswordLine } from 'react-icons/ri';
import { ListOrden } from "../../../UI/organisms/ListOrden/ListOrden";

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendCancelUsers"; 

const getUserDetailsFromUserIds = async (userIds) => {
  const userDetails = [];
  for (const userId of userIds) {
    const userRef = doc(db, 'users', userId);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      if (userData.email) {
        userDetails.push({ email: userData.email });
      } else {
        console.warn(`User ${userId} has no email.`);
      }
    }
  }
  return userDetails;
};


async function sendSupport(data) {
    try {
        const payload = {
            ...data,
        };
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        if (response.ok) {
            toast.success("Ticket enviado con éxito");
        } else {
            const errorBody = await response.text();
            toast.error(`Error de envío de ticket: ${response.statusText}`);
            toast.error(`Detalle del error: ${errorBody}`);
        }
    } catch (error) {
        console.error("Error en Ticket:", error);
        toast.error(`Error de envío de email: ${error.message}`);
    }
}

export const EditorEquipo = ({ rol, setIsOpenGallery}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { sendPasswordResetEmail } = useLoginContext();
    const [ info, setInfo] = useState([]);
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ group, setGroup ] = useState([]);
    const [ selectedGroup, setSelectedGroup ] = useState('');
    const [ filteredGroups, setFilteredGroups ] = useState([]);
    const [ rolUser, setRolUser ] = useState('');
    const [ nameRol, setNamerol ]  = useState('');
    const [ users, setUsers] = useState([]);
    const [ products, setProducts] = useState([]);
    const [ idOs, setIdOs ] = useState([]);
    const [ idProducts, setIdProducts ] = useState([]);
    const [ filteredRolUsers, setFilteredRolUsers ] = useState([]);
    // Inicializar formData con valores por defecto
    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        telefono: '',
        apellido: '',
        pais: 'Colombia',
        departamento: '',
        ciudad: '',
        direccion_1: '',
        direccion_2: '',
        edad: '',
        sexo: '',
        img: '',
        business_id: '',
        compañia: '',
        idc: '',
        rol: '',
        latitud: '',
        longitud: '',
        idg: '',
        cc: '',
        fechaDelete: '',
        has_completed_tour: '',
        typecompany: '',
        answer:'',
        ider:'',
        id_commercial:'',
    });

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!id) throw new Error("Id Foil is undefined");
                const docRef = doc(db, 'users', id);
                const docData = await getDoc(docRef);
                if (docData.exists() && isMounted) {
                    const userData = docData.data();
                    setUsers(userData);
                    setFormData({ ...formData, ...userData });
                    setSelectedDepartment(userData.departamento);
                    if (userData.idc) {
                        // Cargar la información de la compañía asociada
                        const companyDocRef = doc(db, 'company', userData.idc);
                        const companyDoc = await getDoc(companyDocRef);
                        if (companyDoc.exists()) {
                            const companyData = companyDoc.data();
                            setFormData(formData => ({
                                ...formData,
                                compañia: companyData.namecompany,
                                business_id: companyData.business_id,
                                typecompany: companyData.typecompany,
                                id_commercial: companyData.id_commercial,
                                selectcompañia: userData.idc,  // Asegúrate de que el valor coincide con el ID de la compañía
                            }));
                        }
                    }
                }                
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => {isMounted = false;}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);   
    
    useEffect(() => {
        setLoading(true);
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!rol) throw new Error("Rol undefined");
                let fetchedInfo = [];
                const infoCollectionRef = collection(db, 'equipment_group');
                if (rol === "SA-001") {
                    const querySnapshot = await getDocs(infoCollectionRef);
                    fetchedInfo = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                } else {
                    const q = query(infoCollectionRef, where('idc', '==', formData.idc));
                    const querySnapshot = await getDocs(q);
                    fetchedInfo = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                }
                if (isMounted) setGroup(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        setLoading(false);
        return () => { isMounted = false; }
    }, [rol, formData.idc]); 

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'company');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({
        email: null,
        telefono: null,
    });
    // Carga de ciudades
    useEffect(() => {
        if (selectedDepartment) {
            setCities(colombia[selectedDepartment] || []); // Si no existe, asigna un arreglo vacío.
        }
    }, [selectedDepartment]);    

    // Llamados datos de productos asignados a este usuario 
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const infoCollectionRef = collection(db, 'products-assignment');
                const q = query(infoCollectionRef, where('idu', '==', id));
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                if (isMounted) {
                    setProducts(fetchedInfo);
                    // Crear array con los ids de los documentos
                    const ids = fetchedInfo.map(item => item.id);
                    setIdProducts(ids);
                    // Crear array con valores únicos de 'ido que es el id de las ordenes'
                    const uniqueIdOs = Array.from(new Set(fetchedInfo.map(item => item.ido)));
                    setIdOs(uniqueIdOs);
                }
            } catch (err) {
                toast.error('Error al cargar productos asignados a este usuario');
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [id]);
    // Control de cambio en el formilario de datos
    const handleChange = (e) => {
        const { name, value } = e.target;
        // Manejar la selección de una empresa
        if (name === 'selectcompañia' && rol === 'SA-001') {
            const selectedEmpresa = info.find(empresa => empresa.id === value);
            if (selectedEmpresa) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    idc: value, // Actualiza el ID de la empresa seleccionada
                    selectcompañia: selectedEmpresa.id,
                    compañia: selectedEmpresa.namecompany,
                    business_id: selectedEmpresa.business_id,
                    typecompany : selectedEmpresa.typecompany,
                    id_commercial: selectedEmpresa.id_commercial,
                }));
                // setSelectedCompanyName(selectedEmpresa.namecompany); // Actualiza el nombre de la empresa seleccionada
                // Filtra los grupos basándose en la compañía seleccionada
                const filtered = group.filter(g => g.idc === selectedEmpresa.id);
                setFilteredGroups(filtered);
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    idc: '',
                    selectcompañia: '',
                    compañia: '',
                    business_id: '',
                    typecompany: '',
                    id_commercial: '',
                }));
                // setSelectedCompanyName(''); // Limpia el nombre de la empresa seleccionada
                setFilteredGroups([]); // Limpia los grupos filtrados
            }
        };
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));      
        // Manejo de otros cambios en el formulario
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        if (name === 'departamento') {
            setSelectedDepartment(value);
        }
        // Grupo de compañia
        if (name === 'idg') {
            setSelectedGroup(value);
            const selectedGroupName = group.find(g => g.id === value)?.name_group || '';
            setFormData(prevState => ({ ...prevState, name_group: selectedGroupName }));
        }
        // Rol de usuario
        if (name === 'rol') {
            setRolUser(value);
            const rolUser = rolusers.find(r=> r.id === value)?.name_rol || '';
            setFormData(prevState => ({ ...prevState, name_rol: rolUser }));
        }
    };
    // Carga de nueva imagen
    const [ imagePreviewUrl, setImagePreviewUrl ] = useState(users.img);
    const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..';
    const handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
        if (file) {
            reader.onloadend = () => {
                let imagen = reader.result;
                setFormData(prevFormData => ({
                    ...prevFormData,
                    img: imagen
                }));
                setImagePreviewUrl(imagen);
            };
            reader.readAsDataURL(file);
        }
        setImagePreviewUrl(reader.result);
        }
        if (file) reader.readAsDataURL(file);
    }

    useEffect(() => {
        //Perfiles por compañia
        const allowedRolesCompany = PerfilCompany[formData.typecompany] || [users.typecompany];
        const filteredRolCompany  = rolusers.filter(ru =>  allowedRolesCompany.includes(ru.id));
        // Obtener el nombre del rol actual
        const currentRole = rolusers.find(ru => ru.id === formData.rol);
        if (currentRole) {
            setNamerol(currentRole.name_rol);
        } else {
            setNamerol("");
        }
        // Filtrar roles permitidos para el usuario actual
        const filterRolActualiti =  filteredRolCompany.filter(ru => ru.id !== formData.rol);
        const allowedRoles = PerfilRol[rol];  
        const filteredRolUsers = filterRolActualiti.filter(ru => allowedRoles.includes(ru.id));
        setFilteredRolUsers(filteredRolUsers);
    }, [formData, rol, users.typecompany]);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            toast.error('Por favor, introduce un email válido.');
            setLoading(false);
            return;
        }
        // Verificar si se ha seleccionado una empresa
        if (!formData.idc) {
            toast.error('Por favor, selecciona una empresa.');
            setLoading(false);
            return;
        }
        // Concatenar dirección para geocodificación
        const fullAddress = `${formData.direccion_1}, ${formData.ciudad}, ${formData.departamento}, ${formData.pais}`;
        let latTemp, lngTemp; // Variables temporales para lat y lng
        try {
            const encodedAddress = encodeURIComponent(fullAddress);
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg`);
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                latTemp = lat;
                lngTemp = lng;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                setLoading(false);
                return;
            }
        } catch (error) {
            toast.error("Error al obtener las coordenadas: " + error.message);
            return;
        }

        // Actualizar formData directamente con latitud y longitud
        const updatedFormData = { ...formData };
        if (latTemp && lngTemp) {
            updatedFormData.latitud = latTemp;
            updatedFormData.longitud = lngTemp;
        }
        // Eliminar el campo id_commercial de updatedFormData
        delete updatedFormData.id_commercial;
        // Define el documento a actualizar
        const userDocRef = doc(db, 'users', id);
        try {
            // Actualizar el documento del usuario
            await updateDoc(userDocRef, formData);
            // Eliminar el ID del usuario del grupo anterior, si es necesario
            if (users.idg) {
                if (formData.idg && formData.idg !== users.idg) {
                    const oldGroupRef = doc(db, 'equipment_group', users.idg);
                    await updateDoc(oldGroupRef, {
                        group: arrayRemove(id)
                    });
                }
                // Agregar el ID del usuario al nuevo grupo
                const newGroupRef = doc(db, 'equipment_group', formData.idg);
                await updateDoc(newGroupRef, {
                    group: arrayUnion(id)
                });
            }
            toast.success('Usuario editado con éxito');
            navigate('/usuarios');
        } catch (error) {
            setLoading(false);
            toast.error("Error al registrar usuario: " + error.message);
        }
    };
    // Resetear la clave
    const name = formData.nombre+" "+formData.apellido
    const handlePasswordReset = async () => {
        setLoading(true);
        await sendPasswordResetEmail(formData.email , name);
        toast.info("Correo de restablecimiento enviado si el email existe.");
        navigate('/usuarios');
    };
    // Manejo de edicion de edicion por perfil 
    const C2 = formData.typecompany !== "C000002";
    // Perfiles
    const functions_team ={
        value:"Cancelacion de cuentas",
        id_team:[
            "yK3rZNV80XSNWhIxOH71m4bA6lm2",
            "QPbqy71UXwOPNscDzfrZeXfCQAx1",
            "0YbxmJDyabQIsF2FlVCjU8Mod7q1",
            "QPbqy71UXwOPNscDzfrZeXfCQAx1",
            "mC6N844tFtNRh0yuT9KVbNVSqgp2",
            formData.id_commercial,
        ],
    }
    // Cerrar cuenta de un usuario
    const cancelSubmit = async (e) => {
        setLoading(true);
        try {
            // Define el documento a actualizar
            const userDocRef = doc(db, 'users', id);
            const userData = (await getDoc(userDocRef)).data();
            const idg = userData.idg;

            // Prepara los datos a actualizar
            const date_delete = new Date();
            const updatedData = {
                fechaDelete: date_delete,
                status: false,
                idg: "",
                latitud: "",
                longitud: "",
                order_status_id: 8,
            };

            // Actualizar el documento del usuario
            await updateDoc(userDocRef, updatedData);

            // Eliminar el ID del usuario del grupo si está en uno y la opción C2 está activa
            if (C2 && idg) {
                const oldGroupRef = doc(db, 'equipment_group', idg);
                await updateDoc(oldGroupRef, {
                    group: arrayRemove(id),
                });
            }

            // Registro en balance de servicios de la compañía
            await addDoc(collection(db, 'balance_service'), {
                balance: -1,
                date: date_delete,
                idc: userData.idc,
                idu: id,
                name_user: `${userData.nombre} ${userData.apellido}`,
                serial: id,
                status_balance: false,
            });

            // Procesar productos y stock
            const productPromises = products.map(({ id, ...productWithoutId }) =>
                addDoc(collection(db, 'stock_company'), {
                    ...productWithoutId,
                    date_delete: date_delete,
                })
            );

            // Actualizar estado de los productos asignados
            const productAssignmentPromises = idProducts.map(producId => {
                const ordenDocRef = doc(db, 'products-assignment', producId);
                return updateDoc(ordenDocRef, {
                    status: false,
                    date_delete: date_delete,
                });
            });

            // Actualizar los documentos de las órdenes
            const orderUpdatePromises = idOs.map(ordenId => {
                const ordenDocRef = doc(db, 'orden', ordenId);
                return updateDoc(ordenDocRef, {
                    status: false,
                    order_status_id: 8,
                    date_delete: date_delete,
                });
            });

            // Pasar los IDs directamente a getUserDetailsFromUserIds
            const userDetails = await getUserDetailsFromUserIds(functions_team.id_team);
            const payload = {
                users: userDetails,
                timestamp: new Date(),
                event: "Usuario dado de baja",
                company:formData.compañia,
                name: formData.nombre,
                lastName: formData.apellido ,
            };

            // Enviar el soporte con el payload completo
            await sendSupport(payload);

            // Ejecutar todas las operaciones concurrentemente
            await Promise.all([...productPromises, ...productAssignmentPromises, ...orderUpdatePromises]);

            // Deshabilitar la cuenta de usuario en Firebase Authentication si C2 está activa
            if (C2) {
                await axios.post('https://us-central1-goshofi-c578e.cloudfunctions.net/disableUser', { email: userData.email });
            }

            // Navegar de vuelta a la página de usuarios y mostrar un mensaje de éxito
            navigate('/usuarios');
            toast.success('Usuario editado con éxito');

        } catch (error) {
            console.error("Error al actualizar el usuario: ", error);
            toast.error('Error al editar el usuario: ' + error.message);
        } finally {
            setLoading(false); // Desactivar el indicador de carga
        }
    };
    // Borrado de usuario
    const deleteSubmit = async (e) => {
        e.preventDefault();  // Evita que se recargue la página
        setLoading(true);
        try {
            const db = getFirestore();
            // Eliminar todas las órdenes encontradas
            const ordenesRef = collection(db, "orden"); 
            const qOrdenes = query(ordenesRef, where("idu", "==", id));
            const querySnapshotOrdenes = await getDocs(qOrdenes);
            querySnapshotOrdenes.forEach(async (docSnapshot) => {
                await deleteDoc(docSnapshot.ref);
            });
            // Eliminar todos los productos asignados encontrados
            const producRef = collection(db, "products-assignment"); 
            const qProductos = query(producRef, where("idu", "==", id));
            const querySnapshotProductos = await getDocs(qProductos);
            querySnapshotProductos.forEach(async (docSnapshot) => {
                await deleteDoc(docSnapshot.ref);
            });
            // Eliminar todos los balances encontrados
            const balanceRef = collection(db, "balance_service"); 
            const qBalance = query(balanceRef, where("idu", "==", id));
            const querySnapshotBalance = await getDocs(qBalance);
            querySnapshotBalance.forEach(async (docSnapshot) => {
                await deleteDoc(docSnapshot.ref);
            });
            // Eliminar el usuario
            const userDocRef = doc(db, 'users', id);
            await deleteDoc(userDocRef);
            navigate('/usuarios');
            toast.success('Todos los datos fueron borrados');
        } catch (error) {
            console.error("Error al borrar usuario: ", error);
            toast.error('Error al borrar usuario:  ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const goBack = () => {
        navigate(-1); // Mueve el historial hacia atrás en una entrada
    };
    
    let dateFormat = "";
    if(formData.fechaDelete) {
        try {
            const fecha = formData.fechaDelete.toDate(); // Convertir Timestamp a Date
            dateFormat = `${fecha.toLocaleDateString('es-ES')} ${fecha.toLocaleTimeString('es-ES')}`;
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="windons-equipo-admin">
            <div className="encabezado-list">
                <button className='button-new' onClick={goBack}>
                    <BiChevronLeft /> Volver
                </button>
                <h2 className="title">Editar usuario</h2>
            </div>
            {!formData.status&&
                <div className='alert-close'>
                    <FiAlertCircle /> Esta cuenta ya fue cerrada el {dateFormat}, si tienes una noveda con ella escribenos a <Link to={"/soporte"}>Soporte</Link>
                </div>
            }
            <div className='scroll-equip-admin'>
                <form onSubmit={handleSubmit} className="form-contact-e">
                    <section className='column-img'>
                        <div className='section-img'>
                            <div className='input-img'>
                                <img
                                    className='user'
                                    alt='user'
                                    src={imagePreviewUrl || users.img || defaultImage}
                                />
                            </div>
                            {formData.status && C2 && 
                                <div className='input-upload'>
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <HiOutlinePhotograph />   {imagePreviewUrl ? 'Cambiar' : 'Subir imagen'}
                                    </label>
                                    <input id="file-upload" type="file" onChange={handleImageChange} style={{display: 'none'}} />
                                </div>
                            }
                        </div>
                    </section>
                    <section className='section-users'>
                        <div className='column-form2'>
                            <div className='input-date'>
                                <label>Nombre:</label>
                                <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} required  {...((!formData.status)&& { readOnly: true })}/>
                            </div>
                            <div className='input-date'>
                                <label>Apellido:</label>
                                <input type="text" name="apellido" value={formData.apellido} onChange={handleChange} required  {...((!formData.status)&& { readOnly: true })} />
                            </div>
                            <div className='input-date'>
                                <label>Email:</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} required {...((C2)&& { readOnly: false })}/>
                                {errors.email && <p className='error-message'>{errors.email}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Teléfono:</label>
                                <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} {...((!formData.status)&& { readOnly: true })} />
                                {errors.telefono && <p className='error-message'>{errors.telefono}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Cedula CC / PEP:</label>
                                <input type="tel" name="cc" value={formData.cc} onChange={handleChange} {...((!formData.status )&& { readOnly: true })} />
                                {errors.cc && <p className='error-message'>{errors.cc}</p>}
                            </div>
                            {C2 &&
                                <div className='input-date'>
                                    <label>Sexo:</label>
                                    {formData.status
                                        ?<select name="sexo" value={formData.sexo} onChange={handleChange}>
                                            <option value="" disabled>Seleccione</option>
                                            <option value="hombre">Hombre</option>
                                            <option value="mujer">Mujer</option>
                                            <option value="otro">Otro</option>
                                        </select>
                                        :<input type="text" value={formData.sexo} {...(!C2 && { readOnly: true })} />
                                    }
                                </div>
                            }
                            {C2 &&
                                <div className='input-date'>
                                    <label>Edad:</label>
                                    <input type="number" name="edad" value={formData.edad} onChange={handleChange} min="18" max="99" {...((!formData.status || !C2)&& { readOnly: true })}/>
                                </div>
                            }
                            {rol === "SA-001" && (
                                <div className='input-date'>
                                    <label>Empresa:</label>
                                    {(formData.status)
                                        ?<select name="selectcompañia" value={formData.selectcompañia} onChange={handleChange} required>
                                            <option value="" disabled hidden>Seleccione una empresa</option>
                                            {info.map((empresa) => (
                                                <option key={empresa.id} value={empresa.id}>{empresa.namecompany}</option>
                                            ))}
                                        </select>
                                        :<input type="text" value={info.find(g => g.id === formData.selectcompañia)?.namecompany || ''} {...(!C2 && { readOnly: true })} />
                                    }
                                </div>
                            )}
                            <div className='input-date'>
                                <label>Rol:</label>
                                {(formData.status && (rol === "SA-001" || rol === "AA-001"))
                                    ?<select name="rol" value={rolUser} onChange={handleChange}>
                                        <option value={formData.rol}>{nameRol}</option>
                                        {filteredRolUsers.map((users) => (
                                            <option key={users.id} value={users.id}>{users.name_rol}</option>
                                        ))}
                                    </select>
                                    :<input type="text" value={rolusers.find(g => g.id === formData.rol)?.name_rol || ''}  {...(!C2 && { readOnly: true })} />
                                }
                            </div>
                            {rol === "AA-001" && (
                                <div className='input-date'>
                                    <label>Grupo:</label>
                                    {formData.status 
                                        ?<select name="idg" value={selectedGroup} onChange={handleChange}>
                                            <option value="" disabled>Seleccione un grupo</option>
                                            {group.map((group) => (
                                                <option key={group.id} value={group.id}>{group.name_group}</option>
                                            ))}
                                        </select>
                                        :<input type="text" value={group.find(g => g.id === formData.idg)?.name_group || ''}  {...(!C2 && { readOnly: true })} />
                                    }
                                </div>
                            )}
                            {rol === "SA-001" && (
                                <div className='input-date'>
                                    <label>Grupo:</label>
                                    {formData.status 
                                        ?<select name="idg" value={selectedGroup} onChange={handleChange}>
                                            <option value="" disabled>Seleccione un grupo</option>
                                            {filteredGroups.map((group) => (
                                                <option key={group.id} value={group.id}>{group.name_group}</option>
                                            ))}
                                        </select>
                                        :<input type="text" value={filteredGroups.find(g => g.id === formData.idg)?.name_group || ''}  {...(!C2 && { readOnly: true })} />
                                    }
                                </div>
                            )}
                            <div className='input-date'>
                                <label>País:</label>
                                {formData.status
                                    ?<select name="pais" value={formData.pais} onChange={handleChange}>
                                        <option value="Colombia">Colombia</option>
                                    </select>
                                    :<input type="text" value={formData.pais} {...(!C2 && { readOnly: true })} />
                                }
                            </div>
                            <div className='input-date'>
                                {Object.keys(colombia).includes(selectedDepartment) 
                                    ?(<label>{`Departamento:`}</label>)
                                    :(<label value={selectedDepartment}  className="error-input-label">{`**Error en Departamento**`}</label>)
                                }
                                {formData.status
                                    ?<select name="departamento" value={selectedDepartment} onChange={handleChange}>
                                        <option value="" disabled>Seleccione</option>
                                        {Object.keys(colombia).map((department) => (
                                            <option key={department} value={department}>{department}</option>
                                        ))}
                                        {!Object.keys(colombia).includes(selectedDepartment) && (
                                            <option value={selectedDepartment}  className="error-input">{`${selectedDepartment}`}</option>
                                        )}
                                    </select>
                                    :<input type="text" value={formData.departamento} {...((!formData.status )&& { readOnly: true })} />
                                }
                            </div>
                            <div className='input-date'>
                                {cities.includes(formData.ciudad) 
                                    ?(<label>{`Ciudad:`}</label>)
                                    :(<label value={selectedDepartment}  className="error-input-label">{`**Error en Ciudad**`}</label>)
                                }
                                {formData.status 
                                    ?<select name="ciudad" value={formData.ciudad} onChange={handleChange}>
                                        <option value="" disabled>Seleccione</option>
                                        {cities.map((city) => (
                                            <option key={city} value={city}>{city}</option>
                                        ))}
                                        {!cities.includes(formData.ciudad) && (
                                            <option value={formData.ciudad} className="error-input">{`${formData.ciudad}`}</option>
                                        )}
                                    </select>
                                    :<input type="text" value={formData.ciudad} {...((!formData.status )&& { readOnly: true })} />
                                }
                            </div>
                            <div className='input-date'>
                                <label>Dirección:</label>
                                <input type="text" name="direccion_1" value={formData.direccion_1} onChange={handleChange} {...((!formData.status)&& { readOnly: true })} />
                            </div>
                            <div className='input-date'>
                                <label>Dirección detallada:</label>
                                <input type="text" name="direccion_2" value={formData.direccion_2} onChange={handleChange} {...((!formData.status) && { readOnly: true })}/>
                            </div>
                        </div>
                        <div className='column-form2'>
                            {formData.status&&
                                <>
                                    {(rol === "SA-001" || rol === "AA-001" )?
                                        <>
                                            <div>
                                                {formData.answer===true
                                                    ?<Link to={`/encuestas/diagnostic/${formData.ider}`} className='button-question'>
                                                        <FiPaperclip />Diagnóstico
                                                    </Link>
                                                    :<>
                                                        {([0, 99, 1, 2, 15, 13, 14, 4].includes(formData.order_status_id)) && (rol === "SA-001" )  && (
                                                            <button className='button-delete' onClick={deleteSubmit}>
                                                                <MdDeleteOutline />Borrar Usuario
                                                            </button>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                            <button className='button-password' type="button" onClick={handlePasswordReset} disabled={!formData.email}>
                                                <RiLockPasswordLine />Reset contraseña
                                            </button>
                                            <button className='button-new' type="submit" disabled={loading}>
                                                <LuSave /> Guardar cambios
                                            </button>
                                        </>
                                        :<><div></div><div></div><button className='button-new' type="submit" disabled={loading}>
                                        <LuSave /> Guardar cambios
                                    </button></>
                                    }
                                    <button className='button-delete' onClick={cancelSubmit}>
                                        <FiAlertCircle />Cancelar cuenta
                                    </button>
                                </>
                            }
                        </div>
                    </section>
                </form>
                <div className='container-set-users'>
                    <ListOrden idu={id} rol={rol} setIsOpenGallery={setIsOpenGallery}/>
                </div>
            </div>
        </div>
    );
}