import "./CreatorUsers.scss";
import React, { useState } from 'react';
import Papa from 'papaparse';
import { db } from '../../../../firebase/config';
import { Timestamp,addDoc,collection,query,where,getDocs,doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { BiRefresh } from "react-icons/bi";
import { TbFileTypeCsv } from "react-icons/tb";
import { LuCopy } from "react-icons/lu";

// Componente para mostrar el progreso con etiqueta
function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} size={"150px"} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
}
  
CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export const BulkUpload = ({ idc, business_id, company , typecompany }) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [dataReady, setDataReady] = useState(false);
    const [entries, setEntries] = useState([]);
    const [textErrors, setTextErrors] = useState([]);
    const [textAproved, setTextAproved] = useState([]);

    const csvURL = "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fplantilla%2FFormato_Carga_Masivo_Siigo.xlsx?alt=media&token=5db61456-b3f8-4760-bcd6-aabf6302dce7";

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                setEntries(results.data);
                setDataReady(true);  // Indica que los datos están listos para ser revisados
            }
        });
    };

    const handleUploadClick = async () => {
        setLoading(true);
        const total = entries.length;
        let processed = 0;

        for (const formData of entries) {
            if (await validateData(formData)) {
                try {
                    await processSingleEntry(formData);
                } catch (error) {
                    toast.error(`Error al procesar la entrada: ${error.message}`);
                }
            }
            processed++;
            setProgress((processed / total) * 100); // Actualizar el progreso
        }
        setLoading(false);
        setDataReady(false);
    };
    // Validar que los datos no existan
    const validateData = async (item) => {
        // Validaciones de formato y existencia
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(item.email) ||
            !/^[0-9]{10}$/.test(item.telefono) ||
            !/^[0-9]+$/.test(item.cc) ||
            await checkExists('email', item.email) ||
            await checkExists('telefono', item.telefono) ||
            await checkExists('cc', item.cc)) {
            setTextErrors(prevErrors => [
                ...prevErrors,
                `Los datos de ${item.email} ya están registrados; recuerda que los datos CC, email, orden id y teléfono deben ser únicos.`
            ]);
            return false;
        }
        return true;
    };
    const checkExists = async (field, value) => {
        const q = query(collection(db, "users"), where(field, "==", value));
        const docs = await getDocs(q);
        return !docs.empty;
    };

    const processSingleEntry = async (formData) => {
        try {
            // Función para generar un código aleatorio de letras y números
            const generateOrderCode = () => {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                const length = 12;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * characters.length));
                }
                return result;
            };
    
            // Función para verificar si el código de orden ya existe
            const checkOrderCodeExists = async (code) => {
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('code_orden', '==', code));
                const querySnapshot = await getDocs(q);
                return !querySnapshot.empty;
            };
    
            const generateUniqueOrderCode = async () => {
                let newOrderCode = generateOrderCode();
                while (await checkOrderCodeExists(newOrderCode)) {
                    newOrderCode = generateOrderCode();
                }
                return newOrderCode;
            };
    
            const orderCode = await generateUniqueOrderCode();
    
            // Validar el valor del kit antes de proceder
            const kitId = formData.select_kit;
            if (!kitId || kitId.trim() === "" || kitId === "Desconocido") {
                throw new Error(`El select_kit o Kit asignado tiene un valor inválido: "${kitId}" ó no existe`);
            }
    
            // Busqueda del id de los productos y armado de la orden
            const idProducts = await fetchProductKits(kitId);
            const productDetails = await fetchProductsDetails(idProducts);
            const ordenProducts = productDetails.map(product => ({
                name: product.name,
                price: product.price,
                sku: product.SKU + "-" + orderCode,
                picture_url: product.img,
                quantity: 1,
            }));
            const totalOrden = ordenProducts.reduce((sum, product) => sum + product.price, 0);
    
            // Separar la propiedad orden_id del resto de datos
            const { orden_id, ...dataToSave } = formData;
    
            // Asignar cadena vacía a los campos undefined o null,
            for (const key in dataToSave) {
                if (dataToSave[key] === undefined || dataToSave[key] === null) {
                    dataToSave[key] = key === "direccion_2" ? " " : "";
                }
            }
    
            // Filtrar cualquier campo con nombre de propiedad vacío
            const filteredDataToSave = {};
            Object.keys(dataToSave).forEach(key => {
                if (key.trim() !== "") {
                    filteredDataToSave[key] = dataToSave[key];
                }
            });

            // Aplicar la limpieza para los campos que deben eliminar espacios
            if (filteredDataToSave.email) {
                filteredDataToSave.email = filteredDataToSave.email.replace(/\s+/g, '');
            }
            if (filteredDataToSave.telefono) {
                filteredDataToSave.telefono = filteredDataToSave.telefono.replace(/\s+/g, '');
            }
            if (filteredDataToSave.cc) {
                filteredDataToSave.cc = filteredDataToSave.cc.replace(/\s+/g, '');
            }
    
            // Validar que los campos requeridos (excepto direccion_2) no estén vacíos
            const requiredFields = [
                "email", "telefono", "cc", "select_kit", 
                "nombre", "apellido", "departamento", 
                "direccion_1"
            ];
            const emptyFields = [];
            requiredFields.forEach(field => {
                if (!filteredDataToSave[field] || filteredDataToSave[field].toString().trim() === "") {
                    emptyFields.push(field);
                }
            });
            if (emptyFields.length > 0) {
                throw new Error(`Los siguientes campos están vacíos: ${emptyFields.join(", ")}`);
            }
    
            // Crear documento de usuario en Firestore usando los datos filtrados
            const userDoc = await addDoc(collection(db, "users"), {
                ...filteredDataToSave,
                pais: "Colombia",
                edad: '',
                sexo: '',
                img: '',
                business_id: business_id,
                compañia: company,
                code_orden: orderCode,
                idc: idc,
                rol: 'US-004',
                idg: '',
                fechaDelete: '',
                has_completed_tour: true,
                typecompany: typecompany,
                status: true,
                fechaCreacion: Timestamp.now(),
                latitud: "",
                longitud: "",
                order_status_id: 0,
                id_orden: [formData.orden_id],
            });
    
            // Crear la orden en Firestore
            await addDoc(collection(db, 'orden'), {
                business_id: business_id,
                date_delivery: "",
                creation: Timestamp.now(),
                date_delete: "",
                deleveryData: {
                    name: formData.nombre,
                    lastname: formData.apellido,
                    country: "Colombia",
                    department: formData.departamento,
                    address: formData.direccion_1,
                    lastaddress: filteredDataToSave.direccion_2,  // Se usará " " si estaba vacío
                    lat: "",
                    log: "",
                    phone: formData.telefono,
                    email: formData.email,
                    cc: formData.cc,
                },
                idc: idc,
                idk: formData.select_kit,
                idu: userDoc.id,
                order_pictures: "",
                order_product: ordenProducts,
                order_status_id: 0,
                order_velocity: "",
                products: idProducts,
                total: totalOrden,
                status: true,
                updated_at: "",
                re_asign: true,
                order_acceptance: false,
                order_siigo_id: formData.orden_id,
            });
            setTextAproved(prevAproved => [
                ...prevAproved,
                `Registro exitoso: el usuario ${formData.email} fue creado correctamente.`
            ]);
        } catch (error) {
            // Mostrar mensaje de error detallado
            setTextErrors(prevErrors => [
                ...prevErrors,
                `Error: ${formData.email} - ${error.message}.`
            ]);
        }
    };
    

    const fetchProductKits = async (kitId) => {
        // Validar el kit antes de intentar obtenerlo de Firestore
        if (!kitId || kitId.trim() === "" || kitId === "Desconocido") {
            throw new Error(`El valor del kit es inválido: "${kitId}"`);
        }
        // Usar doc para obtener una referencia al documento específico por ID
        const docRef = doc(db, "produc_kits", kitId);
        // Obtener el documento
        const docSnapshot = await getDoc(docRef);
        // Verificar si el documento existe y retornar los datos
        if (docSnapshot.exists()) {
            const kitsData = docSnapshot.data().kits; // Asegúrate de que 'kits' es el campo correcto
            return kitsData; // Retorna los datos de los kits
        } else {
            throw new Error(`Kit no encontrado con identificación: ${kitId}`);
        }
    };
    
    const fetchProductsDetails = async (prodIds) => {
        const products = [];
        // Iterar sobre cada ID de producto y obtener el documento correspondiente
        for (const id of prodIds) {
            // Obtener una referencia al documento específico por ID
            const docRef = doc(db, "produc", id);
            // Obtener el documento
            const docSnapshot = await getDoc(docRef);
            // Verificar si el documento existe y agregar sus datos al array de productos
            if (docSnapshot.exists()) {
                products.push({ id, ...docSnapshot.data() });
            } else {
                console.log(`No se encontró ningún producto con ID: ${id}`); // Opcional: manejar el caso de que no se encuentre el documento
            }
        }
        return products;
    };

    // Función para copiar los errores al portapapeles
    const handleCopyErrors = () => {
        // Elimina las etiquetas HTML de cada error
        const textToCopy = textErrors
        .map((error, index) => `${index + 1}.- ${error.replace(/<[^>]*>?/gm, '')}`)
        .join('\n');
        navigator.clipboard.writeText(textToCopy)
        .then(() => {
            toast.success("Errores copiados al portapapeles");
        })
        .catch(err => {
            toast.error("Error al copiar: " + err.message);
        });
    };

    // Función para copiar los errores al portapapeles
    const handleCopyAproved = () => {
        // Elimina las etiquetas HTML de cada error
        const textToCopy = textAproved
        .map((error, index) => `${index + 1}.- ${error.replace(/<[^>]*>?/gm, '')}`)
        .join('\n');
        navigator.clipboard.writeText(textToCopy)
        .then(() => {
            toast.success("Informe copiado en portapapeles");
        })
        .catch(err => {
            toast.error("Error al copiar informe: " + err.message);
        });
    };
  
    return (
        <>
            {!loading
                ?<div className="container-bulkupload">
                    {(textAproved.length === 0  && textErrors.length === 0)
                        ?(
                            <>
                                <h3 className="subtitle2">Carga de forma masiva usando esta Plantilla CSV: </h3>
                                <a
                                    href={csvURL}
                                    download="cargamasiva.csv"
                                    className="button-download"
                                    rel="noopener noreferrer"
                                >
                                    Plantilla Excel
                                </a>
                                <div className='input-upload'>
                                    <label htmlFor="file" className="custom-file-upload">
                                        {dataReady
                                            
                                            ?<><BiRefresh />Cambiar CVS</> : <><TbFileTypeCsv /> Cargar CSV</> 
                            
                                        }
                                    </label>
                                    <input id="file" type="file" onChange={handleFileChange} accept=".csv" style={{display: 'none'}} />
                                </div>
                            </>
                        )
                        :(
                            <>
                                <h3 className="subtitle2">Carga de forma masiva una nueva plantilla CSV:</h3>
                                <div className='input-upload'>
                                    <label htmlFor="file" className="custom-file-upload">
                                        {dataReady
                                            ?<><BiRefresh />Cambiar CVS</> : <><TbFileTypeCsv /> Carga CSV</> 
                            
                                        }
                                    </label>
                                    <input id="file" type="file" onChange={handleFileChange} accept=".csv" style={{display: 'none'}} />
                                </div>
                            </>
                        )
                    }
                    {dataReady && (
                        <button className='button-load-cvs' onClick={handleUploadClick}>
                            <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                            Cargar Datos
                        </button>
                    )}
                    {textAproved.length !== 0 && (
                        <>
                            <div className="aprovedtext">
                                {textAproved.map((error, index) => (
                                <p key={index} dangerouslySetInnerHTML={{ __html: `${index + 1}.-${error}` }} ></p>
                                ))}
                            </div>
                            <div className="container-button-copy">
                                <button className="button-new2" onClick={handleCopyAproved}><LuCopy /></button>
                            </div>
                        </>
                    )}
                    {textErrors.length !== 0 && (
                        <>
                            <div className="errortext">
                                {textErrors.map((error, index) => (
                                <p key={index} dangerouslySetInnerHTML={{ __html: `${index + 1}.-${error}` }} ></p>
                                ))}
                            </div>
                            <div className="container-button-copy">
                                <button className="button-new2" onClick={handleCopyErrors}><LuCopy /></button>
                            </div>
                        </>
                    )}

                </div>
                :<div  className="container-bulkupload">
                    <h3 className="subtitle2">Carga de forma masiva usando esta Plantilla CSV:</h3>
                    <CircularProgressWithLabel value={progress} />
                </div>
            }
        </>
    );
};

