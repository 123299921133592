import "./Productos.scss"
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
// Secciones componentes de kits
import { Psection0 } from "../../UI/organisms/P_Section_0/P_Section_0";
import { PSection1 } from "../../UI/organisms/P_Section_1/P_Section_1";
import { PSection2 } from "../../UI/organisms/P_Section_2/P_Section_2";
import { useLoginContext } from "../../../context/LoginContext";
// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 1200 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const Productos = ({setIsOpenR, setIsCatalogue, setIdCatalog }) => {
    const{ setLoading } = useLoginContext ();
    
    return (
        <div className="main-inte">
            <div className="ellipse-div-3"/>
            <FadeInSection>
                <Psection0 setIsOpenR={setIsOpenR} setLoading={setLoading}/>
            </FadeInSection>
            <FadeInSection>
                <PSection1 setLoading={setLoading}/>
            </FadeInSection>
            <FadeInSection>
                <PSection2 setIsCatalogue={setIsCatalogue}  setIdCatalog={setIdCatalog}/>
            </FadeInSection>
        </div>
    );
}