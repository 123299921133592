import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useLoginContext } from '../../../context/LoginContext';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { BiHide, BiShow, BiX } from 'react-icons/bi';
import { toast } from 'react-toastify';

// Componente para efecto de fade in
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const PasswordResetPage = ({ setIsLogin }) => {
    const navigate = useNavigate();
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const { token, email } = useParams();
    const { setLoading, sendPasswordReset, verifyToken } = useLoginContext();
    const [ verify, setVerify ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const passwordValidationRegex = /^(?=.*[A-Za-z])(?=.*\W)[A-Za-z\d\W]{6,}$/;

    // Verificar el token al montar el componente
    useEffect(() => {
        const checkToken = async () => {
            const result = await verifyToken(email, token);
            if (result.success) {
                setLoading(false);
            } else {
                setVerify(true);
                setLoading(false);
            }
        };
        checkToken();
    }, [email, token, verifyToken, navigate, setLoading]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordValidationRegex.test(newPassword) && newPassword === confirmPassword) {
            setLoading(true);
            const result = await sendPasswordReset(email, token, newPassword);
            setLoading(false);
            if (result.success) {
                toast.success(result.message);
                navigate('/');
                setIsLogin(prevIsLogin => !prevIsLogin);
            } else {
                toast.error(result.message);
            }
        } else {
            if (!passwordValidationRegex.test(newPassword)) {
                toast.error("La contraseña debe tener al menos 6 caracteres, incluyendo una letra y un carácter especial.");
            }
            if (newPassword !== confirmPassword) {
                toast.error("Las contraseñas no coinciden.");
            }
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="container-login">
            <FadeInSection>
                <div className="section-log">
                    <article className="container-register">
                        <Link to="/" className='button-x'>
                            <BiX />
                        </Link>
                        <Link to="/" className="brand">
                            <img 
                                alt="Shofi" 
                                src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" 
                            />
                        </Link>
                        {verify
                            ?<>
                                <h1 className="subtitle1">Upss.......</h1>
                                <p className="subtitle2">Token vencido solicitad uno nuevo...</p>
                                <Link to="/"className='button-new'>Volver a home</Link>
                            </>
                            :<>
                                <h1 className="subtitle1">Bienvenidos</h1>
                                <p className="subtitle2">Crea tu nueva contraseña</p>
                                <form className="form-contact" onSubmit={handleSubmit}>
                                    <div className='input-date'>
                                        <label>Email:</label>
                                        <input 
                                            className='input'
                                            type="email"
                                            value={email}
                                            disabled
                                        />
                                    </div>
                                    <div className='input-clave'>
                                        <label className="label-t">Ingresa tu clave</label>
                                        <input 
                                            className='input-clave'
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Nueva contraseña"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                        />
                                        <button className="button-password" type="button" onClick={toggleShowPassword}>
                                            {showPassword ? <BiHide /> : <BiShow />}
                                        </button>
                                    </div>
                                    <div className='input-clave'>
                                        <label className="label-t">Confirma tu clave</label>
                                        <input 
                                            className='input-clave'
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Confirma contraseña"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                        <button className="button-password" type="button" onClick={toggleShowPassword}>
                                            {showPassword ? <BiHide /> : <BiShow />}
                                        </button>
                                    </div>
                                    <button className='button-form' type="submit">Crear contraseña</button>
                                </form>
                            </>
                            
                        }
                    </article>
                </div>
            </FadeInSection>
        </div>
    );
};
