import {FormRegister} from "../../UI/molecules/Form_Register/Form_Register";
import "./FormDemo.scss";

export const FormDemo = ({setIsOpenR}) => {
    return ( 
        <div className="main-form2">
            <FormRegister setIsOpenR={setIsOpenR} />
        </div>
    );
}
