import { Link } from 'react-router-dom';

export const Error = () => {
    return ( 
        <section className="section-contect-siigo">
            <div className="section-siigo-contect-container">
                <div className="section-siigo-title">
                    <div className="register-sigo-title">
                        <h2 className="title-page-1">
                            <span>Error 404: </span>No encontrado <br></br> esta ruta o formulario 
                            no existe.
                        </h2>
                        <Link to="/" className='button-new'> Volver a inicio</Link>
                    </div>
                </div>
                <div className="section-siigo-img">
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2Fsigo%2Fyoung-handsome-smiling-man-casual-outfit-sitting-table-working-laptop%201.png?alt=media&token=831f76e8-820c-48c8-bd08-ef3935abafbc" className="img-siigo"/>
                </div>
            </div>
        </section>
    );
}


