export  const TitleRecollections = () => {
    return (
        <>
            <p></p>
            <p>ID</p>
            <p>Departamento</p>
            <p>Ciudad</p>
            <p>Creación</p>
            <p>Status</p>
            <p>Items</p>
        </>
    );
}
