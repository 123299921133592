import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useCallback, useEffect, useState } from "react";
import { FiSave } from "react-icons/fi";
import { HiOutlinePhotograph } from "react-icons/hi";
import { toast } from "react-toastify";
import { db } from "../../../../../firebase/config";
import { useLoad } from "../../../../../context/LoadContext";
import { GetCroppedImg } from "../../template/CropImageHelper";
import { MdAspectRatio, MdOutlineCancel } from "react-icons/md";
import Cropper from "react-easy-crop";
import { v4 as uuidv4 } from 'uuid'; 


export const EditProducModule = ({ idc, setLoading, setIsOpenCreate, idItem, setIdItem}) => {
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const { triggerReload } = useLoad();
    const [ info, setInfo] = useState([]);
    const [ error, setError ] = useState(null);
    const [ numbError, setNumbError] = useState('');
    const [ costeError, setCosteError] = useState('');
    const [formData, setFormData] = useState({
        idc: idc,
        id_category:"",
        name: "",
        description: "",
        sku: "",  
        img: "",
        images:[],
        price:"",
        coste:"",
        timestamp:"",
        life_time:"",
        internal_serial:"",
    });

    useEffect(() => {
        const loadData = async () => {
            const docRef = doc(db, "base_product_stock_company", idItem);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const itemData = docSnap.data();
                setFormData({
                    ...itemData 
                });
                setImagePreviewUrl(itemData.img);
            } else {
                toast.error("No such document!");
            }
        };
        loadData();
    }, [idItem]);

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'category_stock_company');
                const u = query( q, where('idc', '==', idc));
                const querySnapshot = await getDocs(u);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [idc, setLoading]);


    const handleChange = useCallback(async (e) => {
        const { name, value, type } = e.target;
        // Manejo de radio buttons para internal_serial
        if (name === 'internal_serial' && type === 'radio') {
            setFormData((prevState) => ({
                ...prevState,
                internal_serial: value === 'true'
            }));
            return; // Detener aquí para no ejecutar la lógica de abajo
        }
        // Tiempo en meses de vida útil
        if (name === 'life_time') {
            const regex = /^[0-9]*$/; // Validación: Solo números sin signos ni símbolos
            if (!regex.test(value)) {
                setNumbError('Solo se permiten números.');
                return; // Detener actualización si no es válido
            }
            const numericValue = parseInt(value, 10); // Convertir a número entero
            if (numericValue < 0 || numericValue > 240) {
                setNumbError('Debe ingresar un valor entre 0 y 240.');
                return; // Detener si está fuera del rango
            }

            setNumbError(''); // Limpiar el error si es válido
        }
        // Validación de Costos
        if (name === 'coste') {
            const regex = /^[0-9]*$/; // Validación: Solo números sin signos ni símbolos
            if (!regex.test(value)) {
                setCosteError('Solo se permiten números.');
                return; // Detener actualización si no es válido
            }

            setCosteError(''); // Limpiar el error si es válido
        }
        // Actualizar el estado del formulario
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }, []);
    

    const validateForm = () => {
        let isValid = true;
        let errorMessage = ""; // Variable para acumular los mensajes de error
        // Validar campos vacíos
        if (!formData.name) {
            errorMessage = "El nombre es obligatorio.";
            isValid = false;
        }  else if (!formData.img) {
            errorMessage = "La imagen es obligatoria.";
            isValid = false;
        } else if (!formData.description) {
            errorMessage = "La descripción es obligatoria.";
            isValid = false;
        }
        // Si no es válido, mostrar un solo mensaje de error con toast
        if (!isValid) {
            toast.error(errorMessage);
        }
        // Retornar si el formulario es válido o no
        return isValid;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImageSrc(reader.result); // Mostrar en el Cropper
            };
        }
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    // Nueva función para redimensionar la imagen
    const resizeImage = (image, width, height) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const resizedImage = new File([blob], "croppedImage.jpg", { type: blob.type });
                resolve(resizedImage);
            }, 'image/jpeg');
        });
    };

    const cropImage = async () => {
        try {
            // Obtener la imagen recortada
            const croppedImage = await GetCroppedImg(imageSrc, croppedAreaPixels);
    
            // Crear un objeto Image para redimensionar
            const img = new Image();
            img.src = URL.createObjectURL(croppedImage);
    
            img.onload = async () => {
                // Calcular el tamaño para mantener la proporción
                const maxSize = 1000;
                let width = img.width;
                let height = img.height;
    
                if (width > height) {
                    height = (height * maxSize) / width;
                    width = maxSize;
                } else {
                    width = (width * maxSize) / height;
                    height = maxSize;
                }
    
                // Redimensionar la imagen usando la nueva función
                const resizedImage = await resizeImage(img, width, height);
    
                // Actualizar el estado con la imagen redimensionada
                setFormData((prev) => ({ ...prev, img: resizedImage }));
                setImagePreviewUrl(URL.createObjectURL(resizedImage));
                setImageSrc(null); // Cerrar el cropper
            };
        } catch (error) {
            console.error("Error recortando la imagen", error);
            toast.error("Error recortando la imagen.");
        }
    };


    const uploadImageToStorage = async (file, customName) => {
        try {
            const storage = getStorage(); // Inicializar el storage
            const storageRef = ref(storage, `images/${customName}`); // Usar nombre personalizado
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref); // Obtener la URL de descarga
            return downloadURL;
        } catch (error) {
            console.error("Error subiendo la imagen:", error);
            throw new Error("Error subiendo la imagen.");
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validar el formulario
        if (!validateForm()) {
            return; // Si hay errores, no continuar
        }
        try {
            setLoading(true);
            // Subir imagen a Firebase Storage si se ha seleccionado una
            let imgURL = formData.img; // Mantener la URL anterior si no hay nueva imagen
            if (formData.img instanceof File) {
                // Generar un nombre personalizado para la imagen
                const customName = `${formData.indicative_sku}-${new Date().toISOString()}-${uuidv4()}.jpg`;
                // Subir la imagen con el nombre personalizado
                imgURL = await uploadImageToStorage(formData.img, customName);
            }
            const docRef = doc(db, "base_product_stock_company", idItem); // Referencia al documento
            // Actualizar o crear el documento en Firestore
            await setDoc(docRef, { ...formData, img: imgURL }, { merge: true });
            toast.success("Documento creado con éxito.");
            setIsOpenCreate(isOpenCreate => !isOpenCreate);
            // Después de guardar, disparar la recarga
            setIdItem(null); 
            triggerReload();
        } catch (error) {
            console.error("Error creando el documento:", error.message);
            toast.error("Error creando el documento: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="section-open-create-form">
            {imageSrc ? (
                <div className="crop-container">
                    <div className="crop-area">
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1} // Proporción 1:1
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            style={{
                                containerStyle: {
                                    width: '100%',
                                    height: '400px',
                                    maxWidth: '500px',
                                    borderRadius: '30px',
                                    overflow: 'hidden',
                                },
                            }}
                        />
                    </div>
                    <div className="crop-buttons">
                        <button onClick={cropImage} className="button-new">
                            <MdAspectRatio /> Recortar
                        </button>
                        <button onClick={() => setImageSrc(null)} className="button-delete">
                            <MdOutlineCancel />Cancelar
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <section className='open-create-form-img'>
                        <div className='input-img'>
                            <img
                                className='user'
                                alt='user'
                                src={imagePreviewUrl || 'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'}
                            />
                            <div className='input-upload'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <HiOutlinePhotograph /> {imagePreviewUrl ? 'Cambiar' : 'Subir imagen'}
                                </label>
                                <input id="file-upload" type="file" onChange={handleImageChange} style={{ display: 'none' }} />
                            </div>
                        </div>
                    </section>
                    <form onSubmit={handleSubmit} className="open-create-form-dates">
                        <div className='input-form-create'>
                            <label>Nombre(Obligatorio)</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange}/>
                        </div>
                        <div className='input-form-create'>
                            <label>Categoria(Obligatorio)</label>
                            <select name="id_category" value={formData.id_category} onChange={handleChange} required>
                                <option value="" disabled hidden>Seleccionar...</option>
                                {info.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-form-create'>
                            <label>Costo de compra</label>
                            <input type="numb" name="coste" className={costeError&&"input-error"} value={formData.coste} onChange={handleChange}/>
                            {costeError&&<p className="error-message">{costeError}</p>}
                        </div>
                        <div className='input-form-create'>
                            <label>Tiempo de vida (En meses)</label>
                            <input type="numb" name="life_time" className={numbError&&"input-error"}  value={formData.life_time} onChange={handleChange}/>
                            {numbError&&<p className="error-message">{numbError}</p>}
                        </div>
                        <div className='input-form-create'>
                            <label>Serialización</label>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="internal_serial"
                                        value="true"
                                        checked={formData.internal_serial === true}
                                        onChange={handleChange}
                                    />
                                    Serialización interna
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="internal_serial"
                                        value="false"
                                        checked={formData.internal_serial === false}
                                        onChange={handleChange}
                                    />
                                    Sin serialización (SKU asignado)
                                </label>
                            </div>
                        </div>
                        <div className='input-form-create'>
                            <label>Descripción(Obligatorio)</label>
                            <textarea type="text" name="description" value={formData.description} onChange={handleChange} />
                        </div>
                        <button className="button-new space-top" type="submit">
                            Guardar cambios<FiSave />
                        </button>
                    </form>
                </>
            )}
        </div>
    );
}
