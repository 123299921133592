import { Link } from "react-router-dom";
import { BiServer, BiUser, BiUserPlus } from "react-icons/bi";
import "./ItenUsers.scss";
import { useContext, useEffect} from "react";
import { collection, doc, getDoc, getDocs, query, setDoc, where, Timestamp } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import { DeliveryStatus } from "../../atoms/DeliveryStatus/DeliveryStatus";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}

export const ItenUsers = ({nombre, apellido , email, rol , compañia, fechaCreacion , img , id, order_status_id}) => {
    const {authToken} = useContext(AuthContext);
    const shortenedName1 = reduceLength((nombre || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength(apellido ? nombre + " " + apellido : nombre, 16);
    const shortenedEmail = reduceLength(email|| "", 25);
    // Fecha de creacion de users
    let fechaFormateada = "";
    if(fechaCreacion) {
        try {
            const fecha = fechaCreacion.toDate(); 
            fechaFormateada = fecha.toLocaleDateString('es-ES'); 
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }
    // Rol del usuario
    let rolElement;
    if ( rol === 'AA-001' || rol === 'AA-002' || rol === 'AA-003' )  {
        rolElement = <p className="admin"><BiUserPlus /> Admin</p>;
    } else if ( rol === 'US-001' || rol === 'US-002' || rol === 'US-003' ) {
        rolElement = <p className="usuario"><BiUser /> Usuario</p>;
    } else if ( rol === 'SA-001' || rol === 'SA-002' || rol === 'SA-003' || rol === 'SA-004' || rol === 'SA-005') {
        rolElement = <p className="admin"><BiUserPlus /> Super Admin</p>;
    } else if ( rol === 'US-004' ) {
        rolElement = <p className="usuario"><BiUser /> Colaborador</p>;
    } else {
        rolElement = <p className="super-admin"><BiServer />Null</p>;
    }
    // Llamado de Array de Orden
    const fetchInfo = async (id, authToken, db) => {
        try {
            if (!id) throw new Error("Id del usuario no se consigue");
            const infoCollectionRef = collection(db, 'orden');
            let q = query(infoCollectionRef, where('idu', '==', id));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                const docSnapshot = querySnapshot.docs[0];
                const docSnapshot2 = querySnapshot.docs;
                const { order_status_id, order_velocity } = docSnapshot.data();
                const documentId = docSnapshot.id;
    
                const documentDetails = docSnapshot2.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        order_velocity: data.order_velocity,
                        creation: data.creation,
                        order_status_id: data.order_status_id,
                    };
                });
    
                if (![0 ,6, 7, 8, 99].includes(order_status_id)) {
                    const url = `https://api.velocity-x.co/orders/${order_velocity}`;
                    const response = await axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    });
                    const orderData = response.data;
                    const productDetails = orderData.order_details.map(detail => ({
                        name: detail.product.name,
                        picture_url: detail.product.picture_url,
                        sku: detail.product.sku,
                        quantity: detail.quantity
                    }));
    
                    const orderDoc = doc(db, 'orden', documentId);
                    const orderDocUser = doc(db, 'users', id);
    
                    // Obtener datos actuales de Firestore
                    const currentOrderDoc = await getDoc(orderDoc);
                    const currentOrderDocUser = await getDoc(orderDocUser);
    
                    // Verificar cambios antes de actualizar
                    const updatedOrderData = {};
                    const updatedOrderDataUser = {};
    
                    if (currentOrderDoc.exists()) {
                        const currentData = currentOrderDoc.data();
                        if (currentData.order_status_id !== orderData.order_status_id) {
                            updatedOrderData.order_status_id = orderData.order_status_id;
                        }
                        if (JSON.stringify(currentData.order_pictures) !== JSON.stringify(orderData.order_pictures)) {
                            updatedOrderData.order_pictures = orderData.order_pictures;
                        }
                        if (JSON.stringify(currentData.order_product) !== JSON.stringify(productDetails)) {
                            updatedOrderData.order_product = productDetails;
                        }
                    } else {
                        // Si el documento no existe, añadir todos los datos
                        updatedOrderData.order_status_id = orderData.order_status_id;
                        updatedOrderData.order_pictures = orderData.order_pictures;
                        updatedOrderData.order_product = productDetails;
                    }
    
                    if (currentOrderDocUser.exists()) {
                        const currentDataUser = currentOrderDocUser.data();
                        if (currentDataUser.order_status_id !== orderData.order_status_id) {
                            updatedOrderDataUser.order_status_id = orderData.order_status_id;
                        }
                        if (JSON.stringify(currentDataUser.ordens) !== JSON.stringify(documentDetails)) {
                            updatedOrderDataUser.ordens = documentDetails;
                        }
                    } else {
                        // Si el documento no existe, añadir todos los datos
                        updatedOrderDataUser.order_status_id = orderData.order_status_id;
                        updatedOrderDataUser.ordens = documentDetails;
                    }
    
                    // Obtener updated_at de la respuesta de la API y convertirla a timestamp
                    const updatedAtString = orderData.updated_at;
                    const updatedAtDate = new Date(updatedAtString);
                    const updatedAtTimestamp = Timestamp.fromDate(updatedAtDate);
                    updatedOrderData.updated_at = updatedAtTimestamp;
    
                    // Actualizar Firestore solo si hay cambios
                    if (Object.keys(updatedOrderData).length > 0) {
                        await setDoc(orderDoc, updatedOrderData, { merge: true });
                    }
                    if (Object.keys(updatedOrderDataUser).length > 0) {
                        await setDoc(orderDocUser, updatedOrderDataUser, { merge: true });
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            fetchInfo(id, authToken, db);
        }
        return () => {
            isMounted = false;
        };
    }, [authToken, id]);

    return (
        <Link to={`/usuarios/${id}`} className="item-user">
            <div className="avatar">
                {img ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{shortenedName1}</h2>
                )}
            </div>
            <p className="name-user">{shortenedName2}</p>
            <p className="email">{shortenedEmail}</p>
            {/* <p className="phone">{compañia}</p> */}
            <div className="phone">
                <DeliveryStatus info={order_status_id} />
            </div>
            <div className="rol">
                {rolElement}
            </div>
            <p className="date">{fechaFormateada}</p>
        </Link>
    );
}
