import { useEffect, useMemo, useState } from "react";
import { PageListTemplate } from "../../template/PageListTemplate";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from "../../../../../firebase/config";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useLoad } from "../../../../../context/LoadContext";


export const Recollections = ({idc, setIsOpenCreate, rol}) => {
    const column = 5;
    const itemsPage = 20;
    const buttonTitle = "Crear orden";
    const { reload } = useLoad();
    const [info, setInfo] = useState ([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    useEffect(() => {
        let isMounted = true; // Bandera para el desmontaje del componente
        setLoading(true);
        setCurrentPage(1); // Resetear a la primera página al cambiar filtros
    
        const fetchUsers = async () => {
            try {
                // Obtener datos de collection_recollections
                const recollectionsRef = collection(db, 'collection_recollections');
                let recollectionsQuery = []
                if (rol==="SA-001"){
                    recollectionsQuery = query(recollectionsRef);
                }else{
                   recollectionsQuery = query(recollectionsRef, where('idc', '==', idc));
                }
                const recollectionsSnapshot = await getDocs(recollectionsQuery);
    
                const users = await Promise.all(
                    recollectionsSnapshot.docs.map(async (docSnapshot) => {
                        const data = docSnapshot.data();
                        const id_warehouse = data.id_warehouse;
    
                        try {
                            // Obtener datos de collection_warehouse usando id_warehouse
                            const warehouseRef = doc(db, 'collection_warehouse', id_warehouse);
                            const warehouseDoc = await getDoc(warehouseRef);
                            let warehouseData = {};
    
                            if (warehouseDoc.exists()) {
                                const { city, country, state } = warehouseDoc.data();
                                warehouseData = { city, country, state };
                            }
    
                            // Combinar datos de la orden y warehouse
                            return { id: docSnapshot.id, ...data, ...warehouseData };
                        } catch (warehouseError) {
                            console.error('Error fetching warehouse data:', warehouseError);
                            return { id: docSnapshot.id, ...data }; // Devolver solo datos de la orden si falla warehouse
                        }
                    })
                );
    
                // Ordenar los datos por date_create del más nuevo al más viejo
                const sortedUsers = users.sort((a, b) => b.date_create.toMillis() - a.date_create.toMillis());
    
                if (isMounted) setInfo(sortedUsers);
            } catch (error) {
                if (isMounted) setError(error);
                toast.error("Error fetching users: " + error.message);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
    
        fetchUsers();
        return () => {
            isMounted = false; // Actualización de la bandera para el desmontaje
        };
    }, [idc, reload, rol]);
    

    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        handler();
        return () => {
            handler.cancel();
        };
    }, [searchTerm]);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const usersItems = useMemo(() =>
        info.filter((user) =>
            // Filtrar por `id`, `city` o `state` usando el término de búsqueda
            user.id.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            (user.city && user.city.toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (user.state && user.state.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
        ),
        [debouncedSearchTerm, info]
    );
    
    return (
        <div className="list-template">
            <PageListTemplate 
                column={column} 
                itemsPage={itemsPage}
                info={info}
                usersItems={usersItems}
                loading={loading}
                error={error}
                currentPage={currentPage}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleChange={handleChange}
                setIsOpenCreate={setIsOpenCreate}
                buttonTitle={buttonTitle}
            />
        </div>
    );
}
