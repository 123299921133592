import { reduceLength } from "../../../../../tools/reduceLength";

export const ItemWarehouse = ({name, state, city, andress, phone, img}) => {
    const av = reduceLength((state || "").toUpperCase(), 1);
    return (  
        <>
            <div className="avatar">
                {img ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{av}</h2>
                )}
            </div>
            <p>{name}</p>
            <p>{city}</p>
            <p>{andress}</p>
            <p>{phone}</p>
        </>
    );
}