import "./Plataforma.scss";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { PlSection0 } from "../../UI/organisms/Pl_Section_0/Pl_Section_0";
import { PlSection1 } from "../../UI/organisms/Pl_Section_1/Pl_Section_1";
import { PlSection2 } from "../../UI/organisms/Pl_Section_2/Pl_Section_2";
import { PlSection3 } from "../../UI/organisms/Pl_Section_3/Pl_Section_3";
import { PlSection4 } from "../../UI/organisms/Pl_Section_4/Pl_Section_4";
import { PlSection5 } from "../../UI/organisms/Pl_Section_5/Pl_Section_5";
import { PlSection6 } from "../../UI/organisms/Pl_Section_6/Pl_Section_6";
import { useLoginContext } from "../../../context/LoginContext";

// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 2000 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const Plataforma = ({setIsOpenR}) => {
    const{ setLoading } = useLoginContext ();
    
    return (
        <div className="main-home">
            <div className="ellipse-div" />
            <FadeInSection>
                <PlSection0 setLoading={setLoading} setIsOpenR={setIsOpenR}/>
            </FadeInSection>
            <div>
                <FadeInSection>
                    <PlSection1 setLoading={setLoading}/>
                </FadeInSection>
                <FadeInSection>
                    <PlSection2 setLoading={setLoading}/>
                </FadeInSection>
                <FadeInSection>
                    <PlSection3 setLoading={setLoading}/>
                </FadeInSection>
                <FadeInSection>
                    <PlSection4 setLoading={setLoading}/>
                </FadeInSection>
                <FadeInSection>
                    <PlSection5 setLoading={setLoading}/>
                </FadeInSection>
                <FadeInSection>
                    <PlSection6 setLoading={setLoading} setIsOpenR={setIsOpenR}/>
                </FadeInSection>
            </div>
        </div>
    );
}
