export  const TitleProductModule = () => {
    return (
        <>
            <p>Img</p>
            <p>Nombre</p>
            <p>Descripción</p>
            <p>Categoria</p>
            <p>SKU</p>
        </>
    );
}
