// Llamado de Api
import axios from 'axios';
// Llamado de base datos
import rolusers from './RolUser';
import PerfilRol from './PerfilRol';
import PerfilCompany from './PerfilCompany';
import colombia from '../../../../firebase/colombia';
import { db, storage } from '../../../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, getDocs, query, where } from 'firebase/firestore';
// Paqueterias
import { useLoginContext } from '../../../../context/LoginContext';
import React, { useState ,  useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { BiChevronLeft} from 'react-icons/bi';
import { LuSave } from "react-icons/lu";
import { HiOutlinePhotograph } from "react-icons/hi";
// Componentes
import { InfoLoading } from '../../../../Loanding';

export const CreadorEquipo = ({idc, uscompañia, business_id, typecompany, rol}) => {
    const navigate = useNavigate();
    const { user } = useLoginContext();
    const [ info, setInfo] = useState([]);
    const [ imagePreviewUrl, setImagePreviewUrl ] = useState('');
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const { registerProv } = useLoginContext();
    const { register } = useLoginContext();
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ group, setGroup ] = useState([]);
    const [ selectedGroup, setSelectedGroup ] = useState('');
    const [ selectedSend, setSelectedSend] = useState(false);
    const [ filteredGroups, setFilteredGroups ] = useState([]);
    const [ rolUser, setRolUser ] = useState('');

    useEffect(() => {
        setLoading(true);
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!idc) throw new Error("Company undefined");
                if (!rol) throw new Error("Rol undefined");
                let fetchedInfo = [];
                const infoCollectionRef = collection(db, 'equipment_group');
                if (rol === "SA-001") {
                    const querySnapshot = await getDocs(infoCollectionRef);
                    fetchedInfo = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                } else {
                    const q = query(infoCollectionRef, where('idc', '==', idc));
                    const querySnapshot = await getDocs(q);
                    fetchedInfo = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                }
                if (isMounted) setGroup(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        setLoading(false);
        return () => { isMounted = false; }
    }, [idc, rol]); 

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'company');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({
        email: null,
        telefono: null,
    });
  
    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        telefono: '',
        apellido: '',
        pais: 'Colombia',
        departamento: '',
        ciudad: '',
        direccion_1: '',
        direccion_2: '',
        edad: '',
        sexo: '',
        img: '',
        business_id: user?.rol === 'AA-001' ? business_id:'',
        compañia: user?.rol === 'AA-001' ? uscompañia : '',
        idc: user?.rol === 'AA-001' ? idc : '',
        rol: 'US-001',
        latitud: '',
        longitud:'',
        idg:'',
        cc:'',
        fechaDelete: '',
        has_completed_tour:false,
        typecompany: typecompany,
    });
    
    useEffect(() => {
        if (selectedDepartment) {
            setCities(colombia[selectedDepartment]);
        }
    }, [selectedDepartment]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        // Manejar la selección de una empresa
        if (name === 'selectcompañia' && rol === 'SA-001') {
            const selectedEmpresa = info.find(empresa => empresa.id === value);
            if (selectedEmpresa) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    idc: value, // Actualiza el ID de la empresa seleccionada
                    selectcompañia: selectedEmpresa.id,
                    compañia: selectedEmpresa.namecompany,
                    business_id: selectedEmpresa.business_id,
                    typecompany : selectedEmpresa.typecompany,
                }));
                // setSelectedCompanyName(selectedEmpresa.namecompany); // Actualiza el nombre de la empresa seleccionada
                // Filtra los grupos basándose en la compañía seleccionada
                const filtered = group.filter(g => g.idc === selectedEmpresa.id);
                setFilteredGroups(filtered);
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    idc: '',
                    selectcompañia: '',
                    compañia: '',
                    business_id: '',
                    typecompany: '',
                }));
                // setSelectedCompanyName(''); // Limpia el nombre de la empresa seleccionada
                setFilteredGroups([]); // Limpia los grupos filtrados
            }
        }
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));      
        // Manejo de otros cambios en el formulario
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        if (name === 'departamento') {
            setSelectedDepartment(value);
        }
        // Grupo de compañia
        if (name === 'idg') {
            setSelectedGroup(value);
            const selectedGroupName = group.find(g => g.id === value)?.name_group || '';
            setFormData(prevState => ({ ...prevState, name_group: selectedGroupName }));
        }
        // Rol de usuario
        if (name === 'rol') {
            setRolUser(value);
            const rolUser = rolusers.find(r=> r.id === value)?.name_rol || '';
            setFormData(prevState => ({ ...prevState, name_rol: rolUser }));
        }
    };
    
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const ext = file.name.split('.').pop().toLowerCase();
            if (ext === 'jpg' || ext === 'png') {
                setFormData({ ...formData, img: file });
                // Generar una vista previa de la imagen.
                const objectUrl = URL.createObjectURL(file);
                setImagePreviewUrl(objectUrl);
                
            } else {
                toast.error("Solo se permiten archivos .png y .jpg");
            }
        }
    };

    //Perfiles por compañia
    const allowedRolesCompany = PerfilCompany[formData.typecompany] || [typecompany];
    const filteredRolCompany  = rolusers.filter(ru =>  allowedRolesCompany.includes(ru.id));

    // Filtrar roles permitidos para el usuario actual
    const allowedRoles = PerfilRol[rol];  
    const filteredRolUsers = filteredRolCompany.filter(ru => allowedRoles.includes(ru.id));

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            toast.error('Por favor, introduce un email válido.');
            return;
        }
        // Verificar si se ha seleccionado una empresa
        if (!formData.idc) {
            toast.error('Por favor, selecciona una empresa.');
            return;
        }
        // Concatenar dirección para geocodificación
        const fullAddress = `${formData.direccion_1}, ${formData.ciudad}, ${formData.departamento}, ${formData.pais}`;
        let latTemp, lngTemp; // Variables temporales para lat y lng
        try {
            setLoading(true);
            const encodedAddress = encodeURIComponent(fullAddress);
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg`);
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                latTemp = lat;
                lngTemp = lng;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                setLoading(false);
                return;
            }
        } catch (error) {
            toast.error("Error al obtener las coordenadas: " + error.message);
            return;
        }
        let fileURL = ''; // Inicializamos fileURL como una cadena vacía
        if (formData.img) {
            const newFileName = `${uuidv4()}_${Date.now()}`;
            const fileRef = ref(storage, `users/${newFileName}`);
            try {
                await uploadBytes(fileRef, formData.img);
                fileURL = await getDownloadURL(fileRef);
            } catch (error) {
                toast.error("Error subiendo imagen: " + error.message);
                setLoading(false);
                return; // Asegúrate de salir de la función si hay un error
            }
        }
        // Actualizar formData directamente con latitud y longitud
        if (latTemp && lngTemp) {
            formData.latitud = latTemp;
            formData.longitud = lngTemp;
        }
        // Ahora, llamamos a register
        try {
            if(!selectedSend){
                // Registro provicional
                await registerProv({
                    ...formData,
                    img: fileURL,
                }); 
            }else{
                //Registro regular con notificaciones
                await register({
                    ...formData,
                    img: fileURL,
                });
            }
            toast.success('Usuario creado con éxito!');
            // Volver al listado
            navigate('/usuarios');
        } catch (error) {
            setLoading(false);
            toast.error("Error al registrar usuario: " + error.message);
        }
    };

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="windons-equipo-admin">
            <div className="encabezado-list">
                <Link className='button-new' to="/usuarios"><BiChevronLeft />Volver</Link>
                <h2 className="title">Nuevo usuario</h2>
            </div>
            <div className='scroll-equip-admin'>
                <form onSubmit={handleSubmit} className="form-contact-e">
                    <div className='column-img'>
                        <div className='section-img'>
                            <div className='input-img'>
                                <img
                                    className='user'
                                    alt='user'
                                    src={imagePreviewUrl ||'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'}
                                />
                            </div>
                            <div className='input-upload'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <HiOutlinePhotograph />   {imagePreviewUrl ? 'Cambiar' : 'Subir imagen'}
                                </label>
                                <input id="file-upload" type="file" onChange={handleImageChange} style={{display: 'none'}} />
                            </div>
                        </div>
                    </div>
                    <section className='section-users'>
                        <div className='column-form2'>
                            <div className='input-date'>
                                <label>Nombre:</label>
                                <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} required />
                            </div>
                            <div className='input-date'>
                                <label>Apellido:</label>
                                <input type="text" name="apellido" value={formData.apellido} onChange={handleChange} required />
                            </div>
                            <div className='input-date'>
                                <label>Email:</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                {errors.email && <p className='error-message'>{errors.email}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Teléfono:</label>
                                <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} />
                                {errors.telefono && <p className='error-message'>{errors.telefono}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Cedula CC / PEP:</label>
                                <input type="tel" name="cc" value={formData.cc} onChange={handleChange} />
                                {errors.cc && <p className='error-message'>{errors.cc}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Sexo:</label>
                                <select name="sexo" value={formData.sexo} onChange={handleChange}>
                                    <option value="" disabled>Seleccione</option>
                                    <option value="hombre">Hombre</option>
                                    <option value="mujer">Mujer</option>
                                    <option value="otro">Otro</option>
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Edad:</label>
                                <input type="number" name="edad" value={formData.edad} onChange={handleChange} min="18" max="99"/>
                            </div>
                            {rol === "SA-001" && (
                                <>
                                    <div className='input-date'>
                                        <label>Empresa:</label>
                                        <select name="selectcompañia" value={formData.selectcompañia} onChange={handleChange} required>
                                            <option value="" disabled hidden>Seleccione una empresa</option>
                                            {info.map((empresa) => (
                                                <option key={empresa.id} value={empresa.id}>{empresa.namecompany}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='input-date'>
                                        <label>Notificación:</label>
                                        <select 
                                            name="selectedSend" 
                                            value={selectedSend} 
                                            onChange={(e) => setSelectedSend(e.target.value === "true")} 
                                            required
                                        >
                                            <option value="" disabled hidden>Seleccione</option>
                                            <option value="true">Enviar</option>
                                            <option value="false">No Enviar</option>
                                        </select>
                                    </div>
                                </>
                            )}
                            <div className='input-date'>
                                <label>Rol:</label>
                                <select name="rol" value={rolUser} onChange={handleChange}>
                                    <option value="" disabled>Seleccione</option>
                                    {filteredRolUsers.map((users) => (
                                        <option key={users.id} value={users.id}>{users.name_rol}</option>
                                    ))}
                                </select>
                            </div>
                            {rol === "AA-001" && (
                                <div className='input-date'>
                                    <label>Grupo:</label>
                                    <select name="idg" value={selectedGroup} onChange={handleChange}>
                                        <option value="" disabled>Seleccione un grupo</option>
                                        {group.map((group) => (
                                            <option key={group.id} value={group.id}>{group.name_group}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            {rol === "SA-001" && (
                                <div className='input-date'>
                                    <label>Grupo:</label>
                                    <select name="idg" value={selectedGroup} onChange={handleChange}>
                                        <option value="" disabled>Seleccione un grupo</option>
                                        {filteredGroups.map((group) => (
                                            <option key={group.id} value={group.id}>{group.name_group}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <div className='input-date'>
                                <label>País:</label>
                                <select name="pais" value={formData.pais} onChange={handleChange}>
                                    <option value="Colombia">Colombia</option>
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Departamento:</label>
                                <select name="departamento" value={selectedDepartment} onChange={handleChange}>
                                    <option value="" disabled>Seleccione </option>
                                    {Object.keys(colombia).map((department) => (
                                    <option key={department} value={department}>{department}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Ciudad:</label>
                                <select name="ciudad" value={formData.ciudad} onChange={handleChange}>
                                    <option value="" disabled>Seleccione</option>
                                    {cities.map((city) => (
                                    <option key={city} value={city}>{city}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Dirección:</label>
                                <input type="text" name="direccion_1" value={formData.direccion_1} onChange={handleChange}  />
                            </div>
                            <div className='input-date'>
                                <label>Dirección detallada:</label>
                                <input type="text" name="direccion_2" value={formData.direccion_2} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='column-form2'>
                            <button className='button-new' type="submit" ><LuSave />
                                Crear usuario
                            </button>
                        </div>
                    </section>
                </form>
            </div>
        </div>
    );
}