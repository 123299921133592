import questions from '../encuestas/PreguntasBase';
import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { addDoc, collection, doc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { useNavigate } from "react-router-dom";
import { ListUsersOpen } from "../../../UI/organisms/ListUsersOpen/ListUsersOpen";
import { toast } from 'react-toastify';
import { useLoginContext } from '../../../../context/LoginContext';

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/createUserOpen"; 

async function createUserOpen(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            toast.success("Email enviado con exito");
        } else {
            toast.error("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            toast.error("Error body:", errorBody);
        }
    } catch (error) {
        toast.error("Error de envio email:", error);
    }
}

export const ListQuizOpen = ({ idc, compañia, id, rol , setIsRegisterOpen , name, lastname, email, phone, setShouldReload, setIsEditorOpen, setSelectedId, retur}) => {
    const { setLoad, load} = useLoginContext();
    const itemsPerPage = 20;
    const navigate = useNavigate(); 
    const [info, setInfo] = useState([]);
    const [userList, setUserList ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const [currentPage, setCurrentPage] = useState(1);
    const [updateQuiz, setupdateQuiz] = useState(0);
    const [sortType, setSortType] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [filterRole, setFilterRole] = useState('');
    const [formData, setFormData] = useState({
        answer:false,
        cc: "",
        company:compañia,
        date:new Date(),
        email: email,
        height: "",
        idc: idc,
        ider:"",
        lastname:lastname,
        name: name,
        phone: phone,
        sex:"",
        status:true,
        weight:"",
        year:"",
        pais:'Colombia',
        department: '',
        city: '',
        address: '',
        address2: '',
    });


    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        const fetchUsers = async () => {
            try {
                const usersCollectionRef = collection (db, 'users_open');
                const querySnapshot = await getDocs(usersCollectionRef);
                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                const filteredUsers = idc ? users.filter(user => user.idc === idc) : users;
                const sortedUsers = filteredUsers.sort((a, b) => {
                    let comparison = 0;
                    if (sortType === 'name') {
                        comparison = a.name.localeCompare(b.name);
                    } else if (sortType === 'date') {
                        const dateA = a.date ? a.date.toDate() : new Date(0);
                        const dateB = b.date ? b.date.toDate() : new Date(0);
                        comparison = dateA - dateB;
                    }
                    return sortDirection === 'desc' ? -comparison : comparison;
                });
                // Aplicar el filtro de estado basado en filterRole
                let finalUsersList = sortedUsers;
                if (filterRole === "Terminados") {
                    finalUsersList = sortedUsers.filter(user => user.answer);
                } else if (filterRole === "Pendientes") {
                    finalUsersList = sortedUsers.filter(user => !user.answer);
                } else {
                    finalUsersList = sortedUsers;
                }

                if (isMounted) setInfo(finalUsersList);
                setUserList(sortedUsers);
                setLoading(false);
            } catch (error) {
                if (isMounted) setError(error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchUsers();
        return () => { isMounted = false; };
    }, [idc, id, sortType, sortDirection, filterRole, rol , updateQuiz, retur, setLoad, load]);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };
    
    const numberOfPages = Math.ceil(info.length / itemsPerPage);
    const displayItems = info.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setLoading(true); // Activar el indicador de carga
        try {
            const creationDate = new Date(); // Obtener la fecha y hora actual
            const docSnap = await addDoc(collection(db, 'quiz'), {
                title:"Diagnóstico de puestos de trabajo",
                questions: questions,
                idc: idc,
                status: true,
                send: true,
                editable: false,
                creationDate,
                nameCompany: compañia,
            });
            const docRef = doc(collection(db, 'users_open'), id);
            // Guarda los datos en el documento referenciado
            await setDoc(docRef, { ...formData, ide: docSnap.id});
            const companyRef = doc(db, "users", id);
            await updateDoc(companyRef, {
                ide: docSnap.id
            });
            await createUserOpen({ ...formData, ide:docSnap.id, id: docRef.id });
            setFormData({
                answer:false,
                cc: "",
                company:compañia,
                date:new Date(),
                email: "",
                height: "",
                idc: idc,
                ide:"",
                ider:"",
                lastname:"",
                name:"",
                phone:"",
                sex:"",
                status:true,
                weight:"",
                year:"",
                pais:'Colombia',
                department: '',
                city: '',
                address: '',
                address2: '',
            });
            setupdateQuiz(prev => prev + 1);
        } catch (error) {
            console.error("Error al crear encuesta: ", error);
        }
        setLoading(false); 
    };

    const handleSubmitQuiz = async (e) => {
        e.preventDefault();
        await handleSubmit();
        setShouldReload(true); 
        navigate('/open/invitations/csv');
    };

    const toggleRegisterone = async (e) => {
        e.preventDefault();
        await handleSubmit();
        setShouldReload(true); 
        setIsRegisterOpen(true);
    };
    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            {userList&&(userList.length!==0)
                ?<div className="equipo-admin">
                    <ListUsersOpen
                        idc = {idc}
                        compañia={compañia}
                        id = {id}
                        info = {info}
                        setSortType = {setSortType}
                        setSortDirection = {setSortDirection}
                        setFilterRole = {setFilterRole}
                        filterRole = {filterRole}
                        handleChange = {handleChange}
                        sortDirection = {sortDirection}
                        displayItems = {displayItems}
                        numberOfPages = {numberOfPages}
                        currentPage = {currentPage}
                        setIsRegisterOpen = {setIsRegisterOpen}
                        setIsEditorOpen = {setIsEditorOpen}
                        setSelectedId={setSelectedId}
                    />
                </div>
                :<div className="windons">
                    <div className="encabezado-list">
                        <h2 className="title">Equipo: {compañia}</h2>
                    </div>
                    <div className="windons-quiz-init">
                        <div className="section-quiz-init">
                            <div className="quiz-init-img">
                                <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.."/>
                                <h2 className="title-shofi-loanding">Comencemos a encuestar<br></br>a tu equipo de trabajo.</h2>
                            </div>
                            <div className="quiz-init-contect">
                                <h3 className="contect-shofi-loanding">Registra y enviemos la encuesta diagnostica<br/>y asi podemos darte las falencias que presentan</h3>
                                <button onClick={toggleRegisterone} className='button-new'>
                                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                    Crear usuario
                                </button>
                                <h3 className="contect-shofi-loanding  flex-section">Registra a tus colaboradores individualmente o<br />Cárgalos de forma masiva usando esta Plantilla CSV:</h3>
                                <button onClick={handleSubmitQuiz} className='button-asing'>
                                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Frender-3d-manos-aplaudiendo-efecto-sonido-PhotoRoom.png-PhotoRoom%20(1).png?alt=media&token=bd7dbc6d-3932-4a38-a7ee-85f548a5c488&_gl=1*177i2do*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MDQ1LjYwLjAuMA.." />
                                    Ir carga de CSV
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};