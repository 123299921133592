import { formateDate } from "../../../../tools/formateDate";
import { reduceLength } from "../../../../tools/reduceLength";

export const ItemLicense = ({name, id, module, update}) => {
    const av = reduceLength((name || "").toUpperCase(), 1);
    const date = formateDate(update);
    
    return (  
        <>
            <div className="avatar">
                <h2 className="avatar-letter">{av}</h2>
            </div>
            <p>{name}</p>
            <p>{id}</p>
            <p>{date}</p>
            <p>{module.length}</p>
        </>
    );
}