import { useEffect, useState } from "react";
import { collection, getDocs} from "firebase/firestore"; 
import { OrdenRegister } from "../../molecules/OrdenRegister/OrdenRegister";
import { db } from "../../../../firebase/config";

export const ListOrden = ({idu, rol, setIsOpenGallery}) => {
    const [ orden, setOrden] = useState([]);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false)
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection (db, 'orden');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }))
                    .filter(orden => orden.idu === idu);

                if (isMounted) setOrden(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [idu]);

    if (loading) return <div>Loanding ordenes...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div>
            {orden.map((ordens) => (
                <OrdenRegister key={ordens.id} rol={rol} {...ordens} setIsOpenGallery={setIsOpenGallery}/>
            ))}
        </div>
    );
}
