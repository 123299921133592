import "./NavMenuAdmin.scss";
import { NavLink } from "react-router-dom";
import { BiArchive, BiArchiveOut, BiBarChartAlt2, BiBarChartSquare, BiBarcode, BiBasket, BiBlanket, BiBook, BiCabinet, BiChevronDown, BiChevronUp, BiCollection, BiCycling, BiFoodMenu,  BiGridAlt,  BiGroup, BiHomeAlt, BiHomeAlt2, BiLayer, BiLibrary, BiMessageSquareDetail, BiRepeat, BiStore, BiTrip, BiUserPlus, BiUserVoice } from "react-icons/bi";
import { FiCheckCircle, FiCodesandbox, FiFeather, FiSettings, FiShoppingBag } from "react-icons/fi";
import { LuFileStack } from "react-icons/lu";
import { MdOutlineQuiz } from "react-icons/md";
import { useState } from "react";
import { TbTools } from "react-icons/tb";
import { MdEventNote } from "react-icons/md";
import { CgBrowser , CgAlbum} from "react-icons/cg";
import { LuBookUp2, LuWarehouse } from "react-icons/lu";

export  const NavMenuAdmin = ({rol, setIsOpena, module_active }) => {
    const [activeMenu, setActiveMenu] = useState(null);
    const toggleMenu = (menuId) => {
        setActiveMenu((prev) => (prev === menuId ? null : menuId));
    };
    // Función para cerrar todos los menús y la navegación en pantallas pequeñas
    const closenav = () => {
        setActiveMenu(null);
        if (window.innerWidth < 550) {
        setIsOpena(false);
        }
    };

    return (
        <nav className="nav-menu-left">
            <NavLink onClick={closenav} to="/" className="button-menu-left" activeClassName="active-link"><BiHomeAlt2 /><p>Home </p></NavLink>
            {(rol === "AA-003")&&(
                <>
                    <NavLink onClick={closenav} to="/kits/lista" className="button-menu-left" activeClassName="active-link"><CgAlbum /> <p>Lista Kits</p></NavLink>
                    <NavLink onClick={closenav} to="/usuarios" className="button-menu-left" activeClassName="active-link"><BiGroup /> <p>Colaboradores</p></NavLink>
                    
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={() => toggleMenu("report")}>
                            <div className="sub-menu">
                                <CgBrowser /> 
                                <p>Reportes</p>
                            </div>
                            <div className="sub-icon-menu">
                                { activeMenu === "report" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                            </div>
                        </button>
                        {activeMenu === "report" && (
                            <div className="submenu-left">
                                <NavLink to="/reportes" className="button-submenu-left" activeClassName="active-link"><LuBookUp2 /> <p>Creador informes</p></NavLink>
                                <NavLink to="/inventario/stock" className="button-submenu-left" activeClassName="active-link"><LuWarehouse /> <p>Stock por asignar</p></NavLink>
                                <NavLink to="/inventario/asignado" className="button-submenu-left" activeClassName="active-link"><FiShoppingBag /> <p>Stock asignado</p></NavLink>
                            </div>
                        )}
                    </div>
                </>
            )}
            {(rol === "AA-002" ) && (
                <>
                    <NavLink onClick={closenav} to="/open/invitations" className="button-menu-left" activeClassName="active-link"><MdOutlineQuiz /> <p>Encuestas</p></NavLink>
                </>
            )}
            {(rol === 'AA-001' || rol === 'SA-001'|| rol === 'SA-002'|| rol === 'SA-003'|| rol === 'SA-004'|| rol === 'SA-005') 
            && (
                <>
                    <div>
                        <button className="button-submenu  button-equipo" activeClassName="active-link" onClick={() => toggleMenu("team")}>
                            <div className="sub-menu">
                                <BiGroup />
                                <p>Team</p>
                            </div>
                            <div className="sub-icon-menu">
                                { activeMenu === "team" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                            </div>
                        </button>
                        { activeMenu === "team" && (
                            <div className="submenu-left">
                                <NavLink to="/usuarios" className="button-submenu-left" activeClassName="active-link"><BiUserPlus/> <p>Usuarios</p></NavLink>
                                <NavLink to="/usuarios/grupos" className="button-submenu-left" activeClassName="active-link"><BiLayer /> <p>Grupos</p></NavLink>
                            </div>
                        )}
                    </div>
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={()=> toggleMenu("quiz")}>
                            <div className="sub-menu">
                                <MdOutlineQuiz />
                                <p>Encuestas</p>
                            </div>
                            <div className="sub-icon-menu">
                                { activeMenu === "quiz" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                            </div>
                        </button>
                        { activeMenu === "quiz" && (
                            <div className="submenu-left">
                                <NavLink to="/encuestas/send" className="button-submenu-left" activeClassName="active-link"><BiUserVoice /><p>Asignar encuesta</p></NavLink>
                                <NavLink to="/encuesta/lista" className="button-submenu-left" activeClassName="active-link"><LuFileStack /><p>Lista encuestas</p></NavLink>
                            </div>
                        )}
                    </div>
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={() => toggleMenu("kits")}>
                            <div className="sub-menu">
                                <BiArchive />
                                <p>Kits</p>
                            </div>
                            <div className="sub-icon-menu">
                                { activeMenu === "kits" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                            </div>
                        </button>
                        { activeMenu === "kits" && (
                            <div className="submenu-left">
                                <NavLink to="/kits/send" className="button-submenu-left" activeClassName="active-link"><BiUserPlus /> <p>Asignación Kits</p></NavLink>
                                <NavLink to="/kits/lista" className="button-submenu-left" activeClassName="active-link"><BiCabinet /><p>Lista asignados</p></NavLink>
                                {module_active
                                    ?<>
                                        <NavLink to="/stock/productos/base" className="button-submenu-left" activeClassName="active-link"><BiBarcode /> <p>Productos Base</p></NavLink>
                                        <NavLink to="/stock/productos/categorias" className="button-submenu-left" activeClassName="active-link"><BiBlanket /> <p>Categoria</p></NavLink>
                                    </>
                                    :<>
                                        <NavLink to="/equipos" className="button-submenu-left" activeClassName="active-link" ><TbTools /><p>Mis equipos</p></NavLink>
                                        <NavLink to="/inventario/stock" className="button-submenu-left" activeClassName="active-link"><LuWarehouse /> <p>Stock por asignar</p></NavLink>
                                    </>
                                }
                            </div>
                        )}
                    </div>
                    {module_active &&
                    <>
                        <div>
                            <button className="button-submenu" activeClassName="active-link" onClick={() => toggleMenu("rentail")}>
                                <div className="sub-menu">
                                    <BiArchiveOut />
                                    <p>Renta</p>
                                </div>
                                <div className="sub-icon-menu">
                                    { activeMenu === "rentail" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                                </div>
                            </button>
                            { activeMenu === "rentail" && (
                                <div className="submenu-left">
                                    <NavLink to="rental/inventario" className="button-submenu-left" activeClassName="active-link"><BiBarChartAlt2 /> <p>Inventario</p></NavLink>
                                    <NavLink to="rental/inventario/stock" className="button-submenu-left" activeClassName="active-link"><BiRepeat /> <p>Stock por asignar</p></NavLink>
                                </div>
                            )}
                        </div>
                        <div>
                            <button className="button-submenu" activeClassName="active-link" onClick={() => toggleMenu("company")}>
                                <div className="sub-menu">
                                    <BiGridAlt />
                                    <p>Compañia</p>
                                </div>
                                <div className="sub-icon-menu">
                                    { activeMenu === "company" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                                </div>
                            </button>
                            { activeMenu === "company" && (
                                <div className="submenu-left">
                                    <NavLink to="company/recolecciones" className="button-submenu-left" activeClassName="active-link"><BiCollection /> <p>Recolecciones</p></NavLink>
                                    <NavLink to="company/inventario" className="button-submenu-left" activeClassName="active-link"><BiBarChartAlt2 /> <p>Inventario</p></NavLink>
                                    <NavLink to="/company/warehouse" className="button-submenu-left" activeClassName="active-link"><BiHomeAlt /> <p>Bodegas</p></NavLink>
                                </div>
                            )}
                        </div>
                    </>
                    }
                </>
            )}
            {rol === 'US-001' && (
                <>
                    <NavLink onClick={closenav} to="/encuesta/lista" className="button-menu-left" activeClassName="active-link"><LuFileStack /><p>Encuestas</p></NavLink>
                    <NavLink onClick={closenav} to="/kits/lista" className="button-menu-left" activeClassName="active-link"><BiCabinet /><p>Asignación</p></NavLink>
                    <NavLink onClick={closenav} to="/equipos" className="button-menu-left" activeClassName="active-link" ><TbTools /><p>Mis equipos</p></NavLink>
                </>
            )}
            <NavLink onClick={closenav} to="/soporte" className="button-menu-left" activeClassName="active-link"><BiMessageSquareDetail /> <p>Soporte</p></NavLink>
            {(rol === 'AA-001' || rol === 'AA-003' )&& (<NavLink onClick={closenav} to="/marca/date" className="button-menu-left" activeClassName="active-link"><FiSettings /><p>Marca</p></NavLink>)}
            {( rol === 'SA-001' || rol === 'SA-002' || rol === 'SA-003' || rol === 'SA-004' || rol === 'SA-005') 
            && (
                <>
                    <div><p className="title-menu">Administrador Web</p></div>
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={()=> toggleMenu("logistics")}>
                            <div className="sub-menu">
                                <FiCodesandbox />
                                <p>Logistica</p>
                            </div>
                            { activeMenu === "logistics" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                        </button>
                        { activeMenu === "logistics" && (
                            <div className="submenu-left">
                                <NavLink to="/order" className="button-submenu-left" activeClassName="active-link"><FiCheckCircle /> <p>Lista aprobación</p></NavLink>
                                <NavLink to="/order/update" className="button-submenu-left" activeClassName="active-link"><FiFeather /> <p>Fecha entrega</p></NavLink>
                                <NavLink to="/recoleccion/search" className="button-submenu-left" activeClassName="active-link"><BiTrip /> <p>Recolecciones</p></NavLink>
                                <NavLink to="/messengers" className="button-submenu-left" activeClassName="active-link"><BiCycling /> <p>Mensajeros</p></NavLink>
                            </div>
                        )}
                    </div>
                    
                    <div>
                        <button className="button-submenu" activeClassName="active-link" onClick={() => toggleMenu("products")}>
                            <div className="sub-menu">
                                <BiBarcode />
                                <p>Productos</p>
                            </div>
                            { activeMenu === "products" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                        </button>
                        { activeMenu === "products" && (
                            <div className="submenu-left">
                                <NavLink to="/productos"  className="button-submenu-left" activeClassName="active-link" ><BiBasket /><p>Base</p></NavLink>
                                <NavLink to="/productos/categoria"  className="button-submenu-left" activeClassName="active-link" ><BiBook /><p>Categorias</p></NavLink>
                                <NavLink to="/productos/asignados"  className="button-submenu-left" activeClassName="active-link" ><BiFoodMenu /><p>Asignados</p></NavLink>
                            </div>
                        )}
                    </div>
                    
                    <NavLink onClick={closenav} to="/seller" className="button-menu-left" activeClassName="active-link"><FiSettings /><p>Seller</p></NavLink>
                    <NavLink onClick={closenav} to="/company/warehouse" className="button-menu-left" activeClassName="active-link"><BiStore /><p>Locaciones</p></NavLink>

                    <div>
                        <button className="button-submenu" activeClassName="active-link"  onClick={() => toggleMenu("rol")}>
                            <div className="sub-menu">
                                <MdEventNote /><p>Admin sistema</p>
                            </div>
                            { activeMenu === "rol" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                        </button>
                        { activeMenu === "rol" && (
                            <div className="submenu-left">
                                <NavLink to="/admin/rol"  className="button-submenu-left" activeClassName="active-link"><BiLibrary /><p>Admin roles</p></NavLink>
                                <NavLink to="/admin/license"  className="button-submenu-left" activeClassName="active-link"><BiLibrary /><p>Admin licencia</p></NavLink>
                            </div>
                        )}
                    </div>
                    <div>
                        <button className="button-submenu" activeClassName="active-link"  onClick={() => toggleMenu("register")}>
                            <div className="sub-menu">
                                <MdEventNote /><p>Registros</p>
                            </div>
                            { activeMenu === "register" ? (<BiChevronUp />):(<BiChevronDown /> )  }
                        </button>
                        { activeMenu === "register" && (
                            <div className="submenu-left">
                                <NavLink to="/registros/SHOFI"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> SHOFI web</p></NavLink>
                                <NavLink to="/registros/Siigo"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> Siigo</p></NavLink>
                                <NavLink to="/registros/Demo"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> Demo</p></NavLink>
                                <NavLink to="/startco/inversionista"className="button-submenu-left"  activeClassName="active-link"><BiBarChartSquare /><p> Sala SHOFI</p></NavLink>
                                <NavLink to="/startco/spaces"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> Cowork</p></NavLink>
                                <NavLink to="/startco/sales"className="button-submenu-left"  activeClassName="active-link"><BiCollection /><p> Juntas</p></NavLink>
                            </div>
                        )}
                    </div>
                </>
            )}
        </nav>
    );
}