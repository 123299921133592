import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from "react";
import { FiAlertCircle, FiSave } from "react-icons/fi";
import { toast } from "react-toastify";
import { useLoad } from "../../../../../context/LoadContext";
import colombia from "../../../../../firebase/colombia";
import { MdAspectRatio, MdOutlineCancel } from 'react-icons/md';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { GetCroppedImg } from "../../template/CropImageHelper";
import Cropper from "react-easy-crop";
import { v4 as uuidv4 } from 'uuid'; 
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase/config';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useLoginContext } from '../../../../../context/LoginContext';

const MAPS_API_KEY = "AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg";

export const EditMessenger = ({ setLoading, setIsOpenCreate, idItem , setIdItem }) => {
    const { sendPasswordResetEmail } = useLoginContext();
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const { triggerReload } = useLoad();
    const mapRef = useRef(null); // Referencia al mapa
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const [formData, setFormData] = useState({
        img: "",
        idc: "",
        name: "",
        country: "Colombia",
        state: "",
        city: "",
        andress: "",
        last_andress: "",
        email: "",
        phone:"",
        cc:"",
        lat: 4.711,
        lng: -74.0721,
    });

    useEffect(() => {
        const loadData = async () => {
            const docRef = doc(db, "directory_messenger", idItem);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const itemData = docSnap.data();
                setFormData({
                    ...itemData 
                });
                setSelectedDepartment(itemData.state);
                setImagePreviewUrl(itemData.img);
            } else {
                toast.error("No such document!");
            }
        };
        loadData();
    }, [idItem]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === 'phone' || name === 'cc'){
            // Validar que solo se ingresen números
            if (/^[0-9]*$/.test(value)) {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else {
            // Manejo de otros cambios en el formulario
            setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
            if (name === 'state') {
                setSelectedDepartment(value);
            }
        }
    }, []);
    
    useEffect(() => {
        if (selectedDepartment) {
            setCities(colombia[selectedDepartment]);
        }
    }, [selectedDepartment]);
    
    const validateForm = () => {
        let isValid = true;
        let errorMessage = ""; // Variable para acumular los mensajes de error
        // Validar campos vacíos
        if (!formData.andress) {
            errorMessage = "El nombre es obligatorio.";
            isValid = false;
        }
        // Si no es válido, mostrar un solo mensaje de error con toast
        if (!isValid) {
            toast.error(errorMessage);
        }
        // Retornar si el formulario es válido o no
        return isValid;
    };

    // Actualizar el centro del mapa cuando cambian las coordenadas
    useEffect(() => {
        if (mapRef.current) {
            const { lat, lng } = formData;
            mapRef.current.setView([lat, lng], 13, { animate: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.lat, formData.lng]);

    // useEffect para actualizar las coordenadas cuando se completan los campos necesarios
    useEffect(() => {
        const { country, state, city, andress } = formData;
        if (country && state && city && andress) {
            getCoordinates(); // Obtener coordenadas automáticamente
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.country, formData.state, formData.city, formData.andress]);

    // Obtener coordenadas de la dirección proporcionada
    const getCoordinates = async () => {
        const address = `${formData.andress}, ${formData.city}, ${formData.state}, ${formData.country}`;
        const encodedAddress = encodeURIComponent(address);
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${MAPS_API_KEY}`
            );
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                setFormData(prevState => ({ ...prevState, lat, lng }));
                return true;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                return false;
            }
        } catch (error) {
            console.error("Error obteniendo coordenadas:", error);
            toast.error("Error al obtener coordenadas.");
            return false;
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImageSrc(reader.result); // Mostrar en el Cropper
            };
        }
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    // Nueva función para redimensionar la imagen
    const resizeImage = (image, width, height) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const resizedImage = new File([blob], "croppedImage.jpg", { type: blob.type });
                resolve(resizedImage);
            }, 'image/jpeg');
        });
    };

    const cropImage = async () => {
        try {
            // Obtener la imagen recortada
            const croppedImage = await GetCroppedImg(imageSrc, croppedAreaPixels);
    
            // Crear un objeto Image para redimensionar
            const img = new Image();
            img.src = URL.createObjectURL(croppedImage);
    
            img.onload = async () => {
                // Calcular el tamaño para mantener la proporción
                const maxSize = 1000;
                let width = img.width;
                let height = img.height;
    
                if (width > height) {
                    height = (height * maxSize) / width;
                    width = maxSize;
                } else {
                    width = (width * maxSize) / height;
                    height = maxSize;
                }
    
                // Redimensionar la imagen usando la nueva función
                const resizedImage = await resizeImage(img, width, height);
    
                // Actualizar el estado con la imagen redimensionada
                setFormData((prev) => ({ ...prev, img: resizedImage }));
                setImagePreviewUrl(URL.createObjectURL(resizedImage));
                setImageSrc(null); // Cerrar el cropper
            };
        } catch (error) {
            console.error("Error recortando la imagen", error);
            toast.error("Error recortando la imagen.");
        }
    };

    const uploadImageToStorage = async (file, customName) => {
        try {
            const storage = getStorage(); // Inicializar el storage
            const storageRef = ref(storage, `messenger/${customName}`); // Usar nombre personalizado
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref); // Obtener la URL de descarga
            return downloadURL;
        } catch (error) {
            console.error("Error subiendo la imagen:", error);
            throw new Error("Error subiendo la imagen.");
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validar el formulario
        if (!validateForm()) {
            return; // Si hay errores, no continuar
        }
        setLoading(true);
        const coordsObtained = await getCoordinates(); // Buscar coordenadas
        if (!coordsObtained) return; // Si no se obtuvieron coordenadas, no continuar
        try {
            // Subir imagen a Firebase Storage si se ha seleccionado una
            let imgURL = formData.img;
            if (formData.img instanceof File) {
                // Generar un nombre personalizado para la imagen
                const customName = `${formData.indicative_sku}-${new Date().toISOString()}-${uuidv4()}.jpg`;
                // Subir la imagen con el nombre personalizado
                imgURL = await uploadImageToStorage(formData.img, customName);
            }
            setLoading(true);
            //Actualizacion
            const docRef = doc(db, "directory_messenger", idItem); // Referencia al documento
            // Actualizar o crear el documento en Firestore
            await setDoc(docRef, { ...formData, img: imgURL }, { merge: true });
            setIdItem("")
            toast.success("Documento actualizado con éxito.");
            setIsOpenCreate(isOpenCreate => !isOpenCreate);
            // Después de guardar, disparar la recarga
            triggerReload();
        } catch (error) {
            console.error("Error al actulizar el  documento:", error.message);
            toast.error("Error al actulizar el  documento: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    // Resetear la clave
    const name = formData.nombre+" "+formData.apellido
    const handlePasswordReset = async () => {
        setLoading(true);
        await sendPasswordResetEmail(formData.email , name);
        toast.info("Correo de restablecimiento enviado si el email existe.");
        setIdItem("")
        setIsOpenCreate(isOpenCreate => !isOpenCreate);
        // Después de guardar, disparar la recarga
        triggerReload();
        setLoading(false);
    };

    // Inactivar cuenta
    const handleCancel = async () => {
        setLoading(true);
        try {
            await axios.post('https://us-central1-goshofi-c578e.cloudfunctions.net/disableUser', { email: formData.email });
            //Actualizacion
            const docRef = doc(db, "directory_messenger", idItem); // Referencia al documento
            // Actualizar o crear el documento en Firestore
            await setDoc(docRef, { ...formData, status: false }, { merge: true });
            toast.info("Cuenta inactiva con exito");
            setIdItem("")
            setIsOpenCreate(isOpenCreate => !isOpenCreate);
            // Después de guardar, disparar la recarga
            triggerReload();
        } catch (error) {
            console.error("Error al actulizar el  documento:", error.message);
            toast.error("Error al actulizar el  documento: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    // Inactivar cuenta
    const handleActive = async () => {
        setLoading(true);
        try {
            await axios.post('https://us-central1-goshofi-c578e.cloudfunctions.net/enableUser', { email: formData.email });
            //Actualizacion
            const docRef = doc(db, "directory_messenger", idItem); // Referencia al documento
            // Actualizar o crear el documento en Firestore
            await setDoc(docRef, { ...formData, status: true }, { merge: true });
            toast.info("Cuenta activa con exito");
            setIdItem("")
            setIsOpenCreate(isOpenCreate => !isOpenCreate);
            // Después de guardar, disparar la recarga
            triggerReload();
        } catch (error) {
            console.error("Error al actulizar el  documento:", error.message);
            toast.error("Error al actulizar el  documento: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="section-open-create-form">
            {imageSrc ? (
                <div className="crop-container">
                    <div className="crop-area">
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1} // Proporción 1:1
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            style={{
                                containerStyle: {
                                    width: '100%',
                                    height: '400px',
                                    maxWidth: '500px',
                                    borderRadius: '30px',
                                    overflow: 'hidden',
                                },
                            }}
                        />
                    </div>
                    <div className="crop-buttons">
                        <button onClick={cropImage} className="button-new">
                            <MdAspectRatio /> Recortar
                        </button>
                        <button onClick={() => setImageSrc(null)} className="button-delete">
                            <MdOutlineCancel />Cancelar
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <section className="open-create-form-img">
                        <div className="input-img">
                            <img
                                className="user"
                                alt="user"
                                src={imagePreviewUrl || 'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'}
                            />
                            <div className="input-upload">
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <HiOutlinePhotograph /> {imagePreviewUrl ? 'Cambiar' : 'Subir imagen'}
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                    </section>
                    <form className="open-create-form-dates">
                        <div className='input-form-create'>
                            <label>Nombre Completo</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange} required/>
                        </div>
                        <div className='input-form-create'>
                            <label>Email</label>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} required readOnly />
                        </div>
                        <div className='input-form-create'>
                            <label>CC | PP | CE</label>
                            <input type="text" name="cc" value={formData.cc} onChange={handleChange} required/>
                        </div>
                        <div className='input-form-create'>
                            <label>Teléfono</label>
                            <input type="text" name="phone" value={formData.phone} onChange={handleChange} required/>
                        </div>
                        <div className='input-form-create'>
                            <label>Departamento:</label>
                            <select name="state" value={selectedDepartment} onChange={handleChange} required>
                                <option value="" disabled>Seleccione </option>
                                {Object.keys(colombia).map((department) => (
                                    <option key={department} value={department}>{department}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-form-create'>
                            <label>Ciudad:</label>
                            <select name="city" value={formData.city} onChange={handleChange} required>
                                <option value="" disabled>Seleccione</option>
                                {cities.map((city) => (
                                    <option key={city} value={city}>{city}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-form-create'>
                            <label>Direccion(Obligatorio)</label>
                            <input type="text" name="andress" value={formData.andress} onChange={handleChange} required/>
                        </div>
                        <div className='input-form-create'>
                            <label>Detalles de dirección</label>
                            <input type="text" name="last_andress" value={formData.last_andress} onChange={handleChange}/>
                        </div>
                        <div className='form-create-botton space-top'>
                            {formData.status
                                ?<button className="button-delete" onClick={handleCancel}>
                                    Inactivar <FiAlertCircle />
                                </button>
                                :<button className="button-save" onClick={handleActive}>
                                    Activar <FiAlertCircle />
                                </button>
                            }
                            <button className="button-new2"  onClick={handlePasswordReset}>
                                Cambio Clave  <RiLockPasswordLine />
                            </button>
                            <button className="button-new" onClick={handleSubmit}>
                                Actualizar <FiSave />
                            </button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}
