import "./module.scss";
import { Pagination, Stack } from "@mui/material";
import { ItenListTemplate } from "./ItenListTemplate";
import { useLocation } from "react-router-dom";
import { TitleCateogoryModule } from "../module/category/TitleCategoyModule";
import { TitleProductModule } from "../module/product/TitleProductModule";
import { TitleRecollections } from "../module/recollections/TitleRecollections";
import { TitleWarehouse } from "../module/warehouse/TitleWarehouse";
import { TitleMessenger } from "../module/messenger/TitleMessenger";
import { TitleSeller } from "../seller/TitleSeller";
import { TitleRoles } from "../roles/TitleRoles";
import { TitleLicense } from "../roles/TitleLicense";


export  const PageListTemplate = ({column, itemsPage, searchTerm, setSearchTerm, currentPage, usersItems, handleChange, setIsOpenCreate, buttonTitle }) => {
    const numberOfPages = Math.ceil(usersItems.length / itemsPage);
    const displayInfo = usersItems.slice((currentPage - 1) * itemsPage, currentPage * itemsPage);
    // Obtener la ubicación actual
    const location = useLocation();

    const toggleOpen = () => {
        setIsOpenCreate  (isOpenCreate=> !isOpenCreate);
    };

    return (
        <div className="list-template-element">
            <div className="list-template-element-contect">
                <div className="element-contect-header">
                    <input
                        className="element-contect-input-search"
                        type="text"
                        placeholder="Buscar usuarios..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="element-contect-header-button">
                        {buttonTitle&&<button className='button-new' onClick={toggleOpen}>
                            <img alt="Shofi New" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                            {buttonTitle}
                        </button>}
                    </div>
                </div>
                <div className="element-title-contect-list"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `50px repeat(${column}, 1fr) 90px`,
                    }}
                >
                    {/* Encabezados de las tablas */}
                    {location.pathname === "/stock/productos/categorias" && <TitleCateogoryModule/>}
                    {location.pathname === "/stock/productos/base" && <TitleProductModule/>}
                    {(location.pathname === "/company/recolecciones" || location.pathname === "/recoleccion/search")  && <TitleRecollections />}
                    {location.pathname === "/company/warehouse" && <TitleWarehouse />}
                    {location.pathname === "/messengers" && <TitleMessenger />}
                    {location.pathname === "/seller" && <TitleSeller />}
                    {location.pathname === "/admin/rol"  && <TitleRoles />}
                    {location.pathname === "/admin/license" && <TitleLicense />}
                </div>
                {displayInfo.length!==0
                    ?<>
                        <div className="element-contect-list">
                            {displayInfo.map(item => (
                                <ItenListTemplate 
                                    key={item.id} 
                                    item={item} 
                                    column={column} 
                                    location={location}
                                    setIsOpenCreate={setIsOpenCreate}
                                />
                            ))}
                        </div>
                        <div className="element-contect-numb">
                            <Stack spacing={2}>
                                <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                            </Stack>
                        </div>
                    </>
                    :<>
                        <div className="windons-quiz-init">
                            <div className="quiz-init-img">
                                <h2 className="title-shofi-loanding">No se han encontrado datos...</h2>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}