/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import { BiCheckCircle } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";


const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendRegister"; 

async function sendRegister(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            toast.success("Email enviado con exito");
        } else {
            toast.error("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            toast.error("Error body:", errorBody);
        }
    } catch (error) {
        toast.error("Error de envio email:", error);
    }
}

export const FormDownload = ({setLoading}) => {
    const [submitted, setSubmitted] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        load: '',
        numbemploye:'',
        date: new Date(),
    });

    useEffect(() => {
        const storedSubmittedData = localStorage.getItem('submitted_download');
        if (storedSubmittedData) {
            const { value, expiry } = JSON.parse(storedSubmittedData);
            const now = new Date();
            if (now.getTime() > expiry) {
                localStorage.removeItem('submitted_download');
                setSubmitted(false);
            } else {
                setSubmitted(value);
            }
        }
    }, []);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);
    
    const validateEmail = useCallback((email) => {
        // Expresión regular para extraer el dominio del email
        const regex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+)$/;
        const match = email.match(regex);
        if (!match) return false;
        const domain = match[1].toLowerCase();
        // Lista de dominios de correos públicos que no queremos aceptar
        const blockedDomains = new Set([
            "gmail.com", "googlemail.com", "outlook.com", "outlook.es",
            "hotmail.com", "hotmail.es", "hotmail.fr", "live.com",
            "msn.com", "yahoo.com", "yahoo.es", "yahoo.fr", "yandex.com",
            "protonmail.com", "icloud.com", "aol.com", "zoho.com",
            "gmx.com", "mail.com", "me.com", "fastmail.com", "tutanota.com"
        ]);
        // Verificar si el dominio está bloqueado
        return !blockedDomains.has(domain);
    }, []);

    const validatePhone = useCallback((phone) => {
        const regex = /^[0-9]*$/;
        return regex.test(phone);
    }, []);

    const handlePhoneChange = useCallback((e) => {
        const value = e.target.value;
        if (validatePhone(value) || value === '') {
            handleChange(e);
        }
    }, [handleChange, validatePhone]);

    // Datos de Hubspot
    const HUBSPOT_PORTAL_ID = '44612660';
    const HUBSPOT_FORM_ID = 'c58c360c-b561-49b7-864f-f6c03aec0e83';
    const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_ID}`;

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();

        if (!recaptchaValue) {
            toast.error('Por favor, verifica el reCAPTCHA');
            return;
        }

         if (!validateEmail(formData.email)) {
            toast.error('Por favor, usa un correo corporativo (no se permiten Gmail, Outlook, Yahoo, etc.)');
            return;
        }

        if (validatePhone(formData.phone)) {
            const hubspotFormData = {
                fields: [
                    { name: "email", value: formData.email },
                    { name: "firstname", value: formData.name },
                    { name: "phone", value: formData.phone },
                    { name: "company", value: formData.company },
                    { name: "jobtitle", value: formData.load }
                ],
                context: {
                    pageUri: window.location.href,
                    pageName: "Descarga de guía SHOFI"
                }
            };
            try {
                setLoading(true);
                await addDoc(collection(db, "downloads_guia"), formData);
                const response = await fetch(HUBSPOT_ENDPOINT, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(hubspotFormData),
                });
                if (!response.ok) throw new Error("HubSpot submission error");
    
                // Enviar evento a Google Tag Manager para el formulario de registro
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "Descarga de guía SHOFI",
                    form: {
                        name: formData.name,
                        email: formData.email,
                        company: formData.company,
                        phone: formData.phone,
                        load: formData.load,
                        numbemploye: formData.numbemploye,
                        formType: "Descarga de guía SHOFI",
                    }
                });
    
                setFormData({
                    name: '',
                    company: '',
                    email: '',
                    phone: '',
                    load: '',
                    numbemploye: '',
                    date: new Date(),
                });
    
                setSubmitted(true);
                await sendRegister(formData);
    
                const expiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
                localStorage.setItem('submitted_download', JSON.stringify({ value: true, expiry: expiryTime }));
    
                setLoading(false);
    
                // 🔽 Descarga automática del PDF en una nueva pestaña 🔽
                const pdfUrl = "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pdf%2FGui%CC%81a%20leyes%20trabajo%20hi%CC%81brido%20y%20no%20presencial%20Colombia_SHOFI%20(1).pdf?alt=media&token=6ff3150f-db3a-4b5b-ad6e-28292f23bcf8";
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.target = "_blank"; // Abre en una nueva pestaña
                link.rel = "noopener noreferrer"; // Seguridad para evitar acceso a `window.opener`
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // 🔼 Fin de la apertura en nueva pestaña 🔼
            } catch (error) {
                toast.error('Error: ' + error.message);
                setLoading(false);
            }
        } else {
            toast.error('Datos no válidos');
        }
    }, [formData, recaptchaValue]);
    
    return ( 
        <section className="section-contect-siigo">
            <div className="section-siigo-contect-container">
                <div className="section-siigo-title">
                    <div className="register-sigo-title">
                        <h2 className="title-page-1">
                            ¿Tu empresa cumple con<br></br>
                            la normativa del <span>trabajo<br></br>
                            remoto</span> en Colombia?
                        </h2>
                        <p className="subtitle-page-1">
                            Descarga esta guía gratuita y asegúrate de gestionar <br></br>
                            equipos remotos <span>cumpliendo con la ley</span>
                        </p>
                    </div>
                    {!submitted &&  
                        <form className="container-form-siigo" onSubmit={handleSubmit}>
                            <div className="form-siigo">
                                <div className='input-date'>
                                    <label>Nombre:</label>
                                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                                </div>
                                <div className='input-date'>
                                    <label>Cargo:</label>
                                    <select name="load" value={formData.load} onChange={handleChange} required>
                                        <option value="" disabled>Seleccione</option>
                                        <option value="Director">Director</option>
                                        <option value="CEO">CEO</option>
                                        <option value="RRHH">RRHH</option>
                                        <option value="SST">SST</option>
                                        <option value="Financiero">Financiero</option>
                                        <option value="Otro">Otro</option>
                                    </select>
                                </div>
                                <div className='input-date'>
                                    <label>Empresa:</label>
                                    <input type="text" name="company" value={formData.company} onChange={handleChange} required />
                                </div>
                                <div className='input-date'>
                                    <label>Número de empleados:</label>
                                    <select name="numbemploye" value={formData.numbemploye} onChange={handleChange} required>
                                        <option value="" disabled>Seleccione</option>
                                        <option value="1-10">1-10</option>
                                        <option value="11-50">11-50</option>
                                        <option value="51-100">51-100</option>
                                        <option value="101-10">101-500</option>
                                        <option value="501-2000">501-2000</option>
                                        <option value="+2000">+2000</option>
                                    </select>
                                </div>
                                <div className='input-date'>
                                    <label>Teléfono:</label>
                                    <input type="text" name="phone" value={formData.phone} onChange={handlePhoneChange} required />
                                </div>
                                <div className='input-date'>
                                    <label>Email:</label>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className='recaptcha-siigo'>
                                <ReCAPTCHA
                                    sitekey="6LdgvfwnAAAAAEIbajmXn1qZoeHC4gkRsPHKDond"
                                    onChange={value => setRecaptchaValue(value)}
                                />
                                <button className='button-tipe1' type="submit"><FiSend />Descárgar ahora</button>
                            </div>
                        </form>
                    }
                    {submitted &&  
                        <div className="container-form-siigo">
                            <div className="exict-form-sigo">
                                <BiCheckCircle />
                                <p className="title-page-1">Enviado con éxito</p>
                            </div>
                        </div>
                    }
                </div>
                <div className="section-siigo-img">
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2Fsigo%2Fyoung-handsome-smiling-man-casual-outfit-sitting-table-working-laptop%201.png?alt=media&token=831f76e8-820c-48c8-bd08-ef3935abafbc" className="img-siigo"/>
                </div>
            </div>
        </section>
    );
}


