export const inventory =[
    {
        name:"Inventario abierto",
        id:"inventoryopen",
        modal:"C001",
    },
    {
        name:"Inventario cerrado",
        id:"inventoryclose",
        modal:"C002",
    },
    {
        name:"Inventario no disponible",
        id:"inventorynot",
        modal:"C003",
    },
];


export const modules =[
    {
        name:"Encuestas",
        id:"question",
        modal:["C001"],
    },
    {
        name:"Diagnostico",
        id:"questionopen",
        modal:["C003"],
    },
    {
        name:"Inventario propio",
        id:"inventoryown",
        modal:["C001","C002"],
    },
    {
        name:"Soporte",
        id:"support",
        modal:["C001","C003","C002"],
    },
    {
        name:"Marca",
        id:"brand",
        modal:["C001","C003","C002"],
    },
    {
        name:"Reportes",
        id:"report",
        modal:["C001","C002"],
    },
    {
        name:"Cursos",
        id:"course",
        modal:["C001","C003"],
    },
    {
        name:"Tips",
        id:"tips",
        modal:["C001","C003"],
    },
];
