import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useLoginContext } from '../context/LoginContext';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';
import { Loading } from '../Loanding';


const AppRouter = () => {
    const{user, loading} = useLoginContext();
    if (loading) return <Loading />;
    return (
        <BrowserRouter>
            {user.logged
                ?<PrivateRouter />
                :<PublicRouter />
            }
        </BrowserRouter>
    );
}
export default AppRouter;