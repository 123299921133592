import { formateDate } from "../../../../../tools/formateDate";
import { StatusRecollectios } from "./StatusRecollectios";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}

export const ItemRecollections = ({id, date_create, status_delivery, selectProduc, img, city, state }) => {
    const av = reduceLength((id || "").toUpperCase(), 1);
    // Fecha de creacion de users
    const fechaCreate= formateDate(date_create);

    return (  
        <>
            <div className="avatar">
                {img ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{av}</h2>
                )}
            </div>
            <p>{id}</p>
            <p>{state}</p>
            <p>{city}</p>
            <p>{fechaCreate}</p>
            <StatusRecollectios status_delivery={status_delivery}/>
            <p>{selectProduc.length}</p>
        </>
    );
}